import React, { useEffect, useState } from 'react';
import { Popup, PopupBody, PopupFooter } from '../../controls';
import { Checkbox } from '../../controls/Checkbox';
import { user } from '../../../user';
import { AddBloombergEmail } from './AddBloombergEmail';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { bdInventoryActions } from '../../../actions/bd-inventory.actions';
import IconSVG from '../../../styles/svg-icons';

interface SendEmailPopupProps {
    onClose: () => void;
    onConfirm: (params: {selectedDealerEmail: boolean, selectedBloombergEmail: boolean}) => void;
}

export const SendEmailPopup: React.FC<SendEmailPopupProps> = ({ onClose, onConfirm }) => {
    const dispatch = useDispatch();
    const currentUser = user.current()!;
    const currentUserEmail = currentUser.email;
    const currentUserBloombergEmail = currentUser.bloombergEmail;
    const isVisibleForm = useSelector((state: AppState) => state.bdInventory.isVisibleAddBloombergEmailForm);
    const [selectedDealerEmail, setSelectedDealerEmail] = useState(true);
    const [selectedBloombergEmail, setSelectedBloombergEmail] = useState(!!currentUserBloombergEmail);
    const disabledConfirmButton = isVisibleForm || (!selectedDealerEmail && !selectedBloombergEmail);

    useEffect(() => {
        setSelectedBloombergEmail(!!currentUserBloombergEmail);
    }, [currentUserBloombergEmail]);

    const handleConfirm = () => {
        onConfirm({selectedDealerEmail, selectedBloombergEmail});
        onClose()
    };

    const renderBloombergEmail = () => {
        if (currentUserBloombergEmail) {
            return (
                <div className="form-item">
                    <Checkbox
                        checked={selectedBloombergEmail}
                        onChange={() => setSelectedBloombergEmail(!selectedBloombergEmail)}
                        label={currentUserBloombergEmail}
                    />
                </div>
            )
        }
        if (isVisibleForm) {
            return (
                <AddBloombergEmail
                    toggleVisibleForm={() => dispatch(bdInventoryActions.toggleVisibleAddBloombergEmailForm())}
                    isRequestSaveEmail={false}
                />
            )
        }
        return (
            <button className="btn btn-link add-contact" onClick={() => dispatch(bdInventoryActions.toggleVisibleAddBloombergEmailForm())}>
                <IconSVG name="plus-round" width={16} height={16} /> Add Bloomberg Email
            </button>
        )
    };

    return (
        <Popup
            modalClass="modal-choose-email"
            title="Choose email address" onClose={onClose}
        >
            <PopupBody>
                <p>You are going to send the current list of inventory to your mailbox. Please choose the desirable adress.</p>
                <div className="form-item">
                    <Checkbox
                        checked={selectedDealerEmail}
                        onChange={() => setSelectedDealerEmail(!selectedDealerEmail)}
                        label={currentUserEmail}
                    />
                </div>
                {renderBloombergEmail()}
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>Cancel</button>
                <button className="btn btn-main" onClick={handleConfirm} disabled={disabledConfirmButton}>Confirm</button>
            </PopupFooter>
        </Popup>
    )
};
