import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { FormError } from '../../../controls';
import { editParsedBwicActions } from '../../../../actions';
import { DayPickerInput } from '../../../controls/DayPickerInput';

export function BwicEmailReceivedDate() {
    const dispatch = useDispatch();
    const { emailReceivedDate, errors } = useSelector(s => s.editParsedBwic);
    const error = errors && errors.emailReceivedDate;
    const css = classNames({
        'form-control': true,
        'is-invalid': !!error
    });

    return (
        <div className="form-item">
            <label className="form-label">Email Received Date (EST)</label>
            <DayPickerInput
                onDayChange={date => dispatch(editParsedBwicActions.changeEmailReceivedDate(date))}
                selectedDate={emailReceivedDate}
                allowWeekends={true}
                minimumDate={moment().subtract(1, 'year').toDate()}
                maximumDate={moment().add(1, 'year').toDate()}
                className={css}
            />
           <FormError message={error} />
        </div>
    )
}
