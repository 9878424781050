import * as React from 'react';
import { useSelector } from 'react-redux';
import { InventoryPosition } from '../../../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../../../types/inventory/InventoryPositionStatus';
import { AppState } from '../../../types/state/AppState';
import { formatUtils, isRequesting, isRequestSuccess, moneyUtils } from '../../../utils';
import { securitiesActions, inventoryEditActions, gridActions } from '../../../actions';
import { gridColumns, uploadStatus, routes, roles, constants } from '../../../constants';
import { Grid } from '../../grid';
import { Preloader } from '../../common';
import { Popup, PopupBody, PopupFooter } from '../../controls';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { InventoryEditLeavingGuard } from './InventoryEditLeavingGuard';
import { InventoryEditActionButtons } from './InventoryEditActionButtons';
import { InventoryEditGridActionButtons } from './InventoryEditGridActionButtons';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { user } from '../../../user';
import { InventoryFloatingIndex, inventoryFloatingIndexTitles } from '../../../types/inventory/InventoryFloatingIndex';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    positions: InventoryPosition[];
    company?: { name: string, code: string; },
    isLoading: boolean;
    onRefresh: () => void;
    onSave: () => void;
}

export function InventoryEdit({ positions, isLoading, company, onRefresh, onSave }: Props) {
    const dispatch = useAppDispatch();
    const isConflictsPopupVisible = useSelector((s: AppState) => s.inventoryEdit.isConflictsPopupVisible);
    const savingRequestState = useSelector((s: AppState) => s.inventoryEdit.savingRequestState);
    const isSaving = isRequesting(savingRequestState);
    const isSaved = isRequestSuccess(savingRequestState);
    const isDocumentUploading = useSelector((s: AppState) => s.grid.upload.status === uploadStatus.uploading);
    const isInProgress = isLoading || isSaving;
    const unsoldPositions = React.useMemo(() =>
        positions.filter(i =>
            i.status === InventoryPositionStatus.added ||
            i.status === InventoryPositionStatus.updated ||
            i.status === InventoryPositionStatus.undefined
        ), [positions]
    );

    React.useEffect(() => {
        if (isLoading) return;

        const rowsLimit = 200;

        dispatch(securitiesActions.init(
            unsoldPositions.map(i => ({
                ...i,
                size: moneyUtils.amountToMM(i.size),
                bidSize: i.bidSize ? moneyUtils.amountToMM(i.bidSize) : undefined,
                readonly: i.status === InventoryPositionStatus.traded,
                coupon: formatUtils.formatInventoryCoupon(i),
                collateralManagerReferenceName: i.collateralManager?.legalName || constants.emptyPlaceholder,
            })),
            gridColumns.inventory()
                .map(c => {
                    if (c.name === gridColumns.spread.name) {
                        return { ...c, updateDependencyColumnsCallback: inventoryEditActions.updateCoupon };
                    }

                    if (c.name === gridColumns.floaterIndex.name) {
                        return {
                            ...c,
                            selectSourceItemsCallback: () => [
                                { key: InventoryFloatingIndex.Sofr, title: inventoryFloatingIndexTitles[InventoryFloatingIndex.Sofr] },
                                { key: InventoryFloatingIndex.Libor1m, title: inventoryFloatingIndexTitles[InventoryFloatingIndex.Libor1m] },
                                { key: InventoryFloatingIndex.Libor3m, title: inventoryFloatingIndexTitles[InventoryFloatingIndex.Libor3m] },
                                { key: InventoryFloatingIndex.Euribor3m, title: inventoryFloatingIndexTitles[InventoryFloatingIndex.Euribor3m] },
                                { key: InventoryFloatingIndex.Euribor6m, title: inventoryFloatingIndexTitles[InventoryFloatingIndex.Euribor6m] },
                            ],
                            updateDependencyColumnsCallback: inventoryEditActions.updateCoupon
                        };
                    };

                    return c;
                }),
            rowsLimit
        ));
    }, [dispatch, isLoading, unsoldPositions]);

    React.useEffect(() => {
        return () => {
            dispatch(inventoryEditActions.reset());
            dispatch(gridActions.reset());
        };
    }, [dispatch]);

    const handleClose = () => dispatch(inventoryEditActions.setConflictsPopupVisible(false));

    const handleRefresh = () => {
        dispatch(gridActions.reset());
        dispatch(inventoryEditActions.setConflictsPopupVisible(false));

        onRefresh();
    };

    return (
        <Preloader inProgress={isInProgress || isSaved}>
            <div className="my-inventory container">
                <div className="sub-header">
                    <div className="sub-header-row type01 flex-row">
                        <Breadcrumbs>
                            <BreadcrumbsItem
                                route={routes.inventory}
                                text={user.hasRoles(roles.Administrator) || user.hasRoles(roles.DataEntry) ? 'Dealer Inventory' : 'Inventory'}
                            />
                        </Breadcrumbs>
                        <h1>{unsoldPositions.length > 0 ? 'Edit List' : 'Add List'}</h1>
                        {!!company && <span className="name-tag">{company.name} ({company.code})</span>}
                        <div className="flex-item-right controls">
                            <InventoryEditGridActionButtons disabled={isLoading || isSaving || isDocumentUploading} />
                            <InventoryEditActionButtons
                                disabled={isLoading || isSaving || isDocumentUploading}
                                isCurrentInventoryEmpty={!unsoldPositions.length}
                                onSave={onSave}
                            />
                        </div>
                    </div>
                </div>

                <Grid
                    onDataItemsUpload={(file: File) => dispatch(inventoryEditActions.uploadSecurities(file))}
                    onDataItemsPaste={(clipboardText: string) => dispatch(inventoryEditActions.handleClipboardData(clipboardText))}
                    readonlyTooltip="Security is Sold and cannot be updated. You can delete Sold security."
                    onFileSelected={(file: File) => dispatch(inventoryEditActions.uploadSecurities(file))}
                    helpPopoverTitle="You can add your securities in the following ways"
                />
                {!isSaved && <InventoryEditLeavingGuard inventoryPositions={unsoldPositions} />}

                {
                    isConflictsPopupVisible &&
                    <Popup title="Changes are not allowed" onClose={handleClose}>
                        <PopupBody>
                            <StatusMessageSection type={StatusMessageSectionType.Error}>
                                The inventory list was changed. Refresh the page to get the latest information.
                            </StatusMessageSection>
                        </PopupBody>
                        <PopupFooter>
                            <button className="btn btn-ghost" onClick={handleClose}>Cancel</button>
                            <button className="btn btn-main" onClick={handleRefresh}>Refresh Page</button>
                        </PopupFooter>
                    </Popup>
                }
            </div>
        </Preloader>
    );
}
