import { useParams } from 'react-router-dom';
import { TabItem, TabList } from '../../bidding/common/tab-list';

interface Props {
    years: number[];
    onSelectedYearChange: (year: number) => void;
}

export function MarketTrendsYearFilter({ years, onSelectedYearChange }: Props) {
    const params = useParams<{ year: string }>();

    return (
        <div className="webinar-navigation webinar-navigation-years">
            <TabList className="webinar-navigation-cnt webinar-cnt-center">
                {
                    years.map(year => (
                        <TabItem
                            key={year}
                            title={year}
                            active={year === Number(params.year)}
                            onClick={() => onSelectedYearChange(year)}
                        />
                    ))
                }
            </TabList>
        </div>
    );
}
