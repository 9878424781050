import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bdInventoryActions } from '../../../../actions';
import { createFilterActions } from '../../../../actions/filter.actions';
import { securityDetailsActions } from '../../../../actions/security.details.actions';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BwicFilterType } from '../../../../types/filters/FilterState';
import { PageConfigType } from '../../../../types/page-config/PageConfigType';
import { AppState } from '../../../../types/state/AppState';
import { isRequesting, isRequestSuccess } from '../../../../utils';
import { BwicFilters } from '../../../filters/BwicFilters';
import { bdInventoryFilters } from './bdInventoryFilters';
import { bwicLogActions } from "../../../../actions/bwic-log.actions";
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

const filterActions = createFilterActions(BwicFilterType.BDInventory);

export const BDInventoryListFilter = () => {
    const dispatch = useDispatch();
    const isFilterChanged = useSelector((s: AppState) => s.filters.bdInventory.filterChanged);
    const isFilterModified = useSelector((s: AppState) => s.filters.bdInventory.filterModified);
    const visibleFilters = useSelector((state: AppState) => state.filters.bdInventory.visibleFilters);
    const filter = useSelector((state: AppState) => state.filters.bdInventory.filter);
    const lastAppliedFilter = useSelector((state: AppState) => state.filters.bdInventory.lastAppliedFilter);
    const isInventoryExist = useSelector((state: AppState) => state.bdInventory.isInventoryExist);
    const requestStateInventoryExist = useSelector((state: AppState) => state.bdInventory.requestStateInventoryExist);
    const requestStateSearchInventory = useSelector((state: AppState) => state.entities.inventory.requestState);
    const disabledFilters = isRequesting(requestStateSearchInventory) || !isInventoryExist;
    const selectedFilterReferenceName = useAppSelector(s => s.filters.bdInventory.selectedFilterReferenceName);

    useEffect(() => {
        if (isRequestSuccess(requestStateInventoryExist) && !isInventoryExist) {
            dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        }
    }, [isInventoryExist, requestStateInventoryExist, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(filterActions.resetFilter());
            dispatch(filterActions.resetFilterState());
            dispatch(filterActions.resetFiltersVisibility());
        }
    }, [dispatch])

    const handleVisibilityChange = (filterName: string) => {
        dispatch(filterActions.filterVisibilityChange(filterName))
    }

    const handleApplyFilter = () => {
        dispatch(bdInventoryActions.advancedFilterBlocked(false));
        dispatch(bdInventoryActions.applyFilter());
        dispatch(securityDetailsActions.securityDetailsReset());
        dispatch(bwicLogActions.bwicLogReset());
    };

    const handleClear = () => {
        dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        dispatch(securityDetailsActions.securityDetailsReset());
        dispatch(bdInventoryActions.clearFilter());
        dispatch(bwicLogActions.bwicLogReset());
    };

    return (
        <BwicFilters
            isSearching={disabledFilters}
            onApply={handleApplyFilter}
            onReset={handleClear}
            visibleFilters={visibleFilters}
            hiddenFilters={bdInventoryFilters.additionalFilters}
            filterType={BwicFilterType.BDInventory}
            lastAppliedFilter={lastAppliedFilter}
            filter={filter}
            filterChanged={isFilterChanged}
            filterModified={isFilterModified}
            onVisibilityChange={handleVisibilityChange}
            withBlockedSearchAndFilter={disabledFilters}
            filterConfigShowEmailNotification={false}
            pageConfigType={PageConfigType.Inventory}
            saveFilterVisible={true}
            selectedFilterReferenceName={selectedFilterReferenceName}
            saveFilterBlockerFeature={SubscriptionFeature.InventorySavedFilters}
            advancedFiltersBlockerFeature={SubscriptionFeature.InventoryAvancedFilters}
        />
    )
}
