import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { editParsedBwicActions as actions } from '../../../actions';
import { constants, bwicStatuses, uploadStatus, routes } from '../../../constants';
import { DownloadTemplateButton } from "../../common/download-template/DownloadTemplateButton";
import { DeleteRowsWithErrorButton } from "../../grid/DeleteRowsWithErrorButton";

class CommandBar extends Component {
    render = () => {
        const {
            bwic,
            bwicDate,
            bidsDue,
            totalPositionRowCount,
            timeZone,
            timeZones,
            dispatch,
            emailReceivedDate,
            isSaving,
            isGridUploading,
            hasGridErrors,
            isValid,
            hasPositions
        } = this.props;

        if (totalPositionRowCount === 0) {
            return null;
        }

        const canSave = !isSaving && hasPositions && !!bwicDate && !hasGridErrors && isValid && !!emailReceivedDate;
        // new bwic or edit unpublished
        const saveAndPublishVisible = !bwic.referenceName || (bwic.referenceName && bwic.onReview);
        const canCancel = !!bwic.referenceName && bwic.status !== bwicStatuses.cancelled.key;

        let dateTime = '';
        if (bwicDate && bidsDue) {
            const date = moment(bwicDate).format(constants.dateShortFormat);
            const time = moment.utc(bidsDue).format(constants.timeFormat);
            const timeZoneOffset = timeZones.find(t => t.id === timeZone).utcOffsetMinutes;

            //combine date time
            dateTime = moment
                .utc(`${date} ${time}`, `${constants.dateShortFormat} ${constants.timeFormat}`)
                .utcOffset(timeZoneOffset, true)
                .local()
                .format(`${constants.dateShortFormat} ${constants.timeFormat}`);
        }

        return (
            <div className="sub-header-row type01 flex-row">
                <Breadcrumbs>
                    <BreadcrumbsItem route={routes.manageParsedBwics} text='Manage Parsed BWICs' />
                </Breadcrumbs>
                <h1>{dateTime}</h1>
                <div className="flex-item-right controls">
                    <DeleteRowsWithErrorButton />
                    <DownloadTemplateButton
                        templateType="SecuritiesXlsxTemplate"
                        disabled={isGridUploading}
                    />
                    {canCancel &&
                        <button className="btn btn-ghost btn-danger" disabled={isGridUploading} type="button" onClick={() => dispatch(actions.cancelDialog(true))}>
                            Cancel BWIC
                        </button>
                    }
                    <button className="btn btn-ghost" type="button" disabled={isGridUploading} onClick={() => dispatch(actions.showManageParsedBwics())}>
                        Cancel
                    </button>
                    <button className="btn btn-ghost" disabled={!canSave || isGridUploading} type="button" onClick={() => dispatch(actions.save())}>
                        Save
                    </button>
                    {saveAndPublishVisible &&
                        <button className="btn btn-main" disabled={!canSave || isGridUploading} type="button" onClick={() => dispatch(actions.saveAndPublish())}>
                            Save &amp; Publish
                        </button>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ editParsedBwic, grid }) => {
    const hasPositions = grid.dataItems.some(i => !i.draft)
    return {
        bwic: editParsedBwic.bwic,
        bwicDate: editParsedBwic.date,
        bidsDue: editParsedBwic.bidsDue,
        timeZone: editParsedBwic.timeZone,
        timeZones: editParsedBwic.timeZones,
        hasPositions,
        hasGridErrors: hasPositions && grid.dataItems.some(i => !i.draft && i.errors?.length),
        totalPositionRowCount: grid.dataItems.length,
        emailReceivedDate: editParsedBwic.emailReceivedDate,
        isSaving: editParsedBwic.isSaving,
        isValid: editParsedBwic.isValid ?? true,
        isGridUploading: grid.upload.status === uploadStatus.uploading
    }
};

const connectedCommandBar = connect(mapStateToProps)(CommandBar);
export { connectedCommandBar as CommandBar };
