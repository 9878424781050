import { useEffect, useState } from 'react';
import moment from 'moment';
import { constants } from '../../../constants';
import { RequestState } from '../../../constants/request-state';
import { manageEmailsService } from '../../../services/manage-emails.service';
import { ManageEmailFilterState } from '../../../types/state/ManageEmailState';
import { getSearchDateRange } from '../../../utils';
import {
    ManageEmailDetails
} from '../../../types/management/ManageEmailDetails';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../actions';

/**
 * Due common problem with uncontrolled refreshing of react-infinity-scroller lib - https://github.com/danbovey/react-infinite-scroller/issues/143
 * have been decided to use "flag method" as it recomended the lib creators - https://github.com/danbovey/react-infinite-scroller (Troubleshooting)
 */

export const useEmails = (filter: ManageEmailFilterState, shouldRequestNextPage: boolean, setShouldRequestNextPage: (isLoading: boolean) => void, pageSize = 50) => {
    const dispatch = useDispatch();
    const [emails, setEmails] = useState<ManageEmailDetails[]>([]);
    const [totalRecordNumber, setTotalRecordNumber] = useState<number>(0);
    const [requestStatus, setRequestStatus] = useState(RequestState.none);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
        setEmails([]);
        setShouldRequestNextPage(true);
    }, [
        filter.statuses,
        filter.types,
        filter.selectedDateOption,
        filter.customDateRange,
        setShouldRequestNextPage
    ])

    useEffect(() => {
        const getEmailList = async () => {
            const dateRange = getSearchDateRange({
                selectedDateOption: filter.selectedDateOption,
                customDateRange: filter.customDateRange
            });

            const requestParams = {
                emailEventTypes: filter.statuses,
                emailTypes: filter.types,
                from: moment(dateRange.dateFrom).tz(constants.estTimezone, true).toISOString(),
                to: moment(dateRange.dateTo).tz(constants.estTimezone, true).toISOString(),
                page: page,
                pageSize,
            }

            try {
                setRequestStatus(RequestState.request);
                const response = await manageEmailsService.getEmail(requestParams);
                setEmails((emails) => [...emails, ...response.result]);
                setTotalRecordNumber(response.totalRecordNumber);
                setRequestStatus(RequestState.success);
                setPage((page) => page + 1);
                setShouldRequestNextPage(false);
            } catch (e) {
                setRequestStatus(RequestState.failure);
                dispatch(errorActions.unexpectedError(e));
            }
        }
        if (shouldRequestNextPage) {
            getEmailList();
        }
    }, [
        page,
        setShouldRequestNextPage,
        filter.statuses,
        filter.types,
        filter.selectedDateOption,
        filter.customDateRange,
        dispatch,
        pageSize,
        shouldRequestNextPage
    ])

    return {
        emails,
        requestStatus,
        page,
        totalRecordNumber
    }
}
