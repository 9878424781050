import * as React from 'react';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { constants, errorMessages } from '../../../../constants';
import { SettlementAgentCommissionByRating } from '../../../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { Rating, Ratings } from '../../../../types/enums/Rating';
import { formatUtils, numericUtils } from '../../../../utils';
import { Table } from '../../../bidding/common/table';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { FormError } from '../../../controls';
import { SettlementAgentEditingCommissionByRating } from './SettlementAgentEditingCommissionByRating';
import { RevertBidButton } from "../../../common/RevertBidButton";

export type EditErrors = { [rating in Rating]: string; };

interface Props {
    originalCommission?: SettlementAgentCommissionByRating;
    commission: SettlementAgentCommissionByRating | SettlementAgentEditingCommissionByRating;
    readonly?: boolean;
    disabled?: boolean;
    editErrors?: EditErrors;
    onChange?: (rating: Rating, commission: string, error: string) => void;
}

export function CommissionByRatingTable({
    originalCommission,
    commission,
    readonly = false,
    disabled = false,
    editErrors = {} as EditErrors,
    onChange }: Props) {

    const validate = (value: string) => {
        if (!value || !value.trim()) {
            return errorMessages.empty;
        } else if (!numericUtils.isNumber(value)) {
            return errorMessages.invalidValue;
        }

        const commission = Number(value);

        if (commission < constants.commissionRange.min || commission > constants.commissionRange.max) {
            return errorMessages.shouldBeBetween(
                constants.commissionRange.min.toFixed(constants.commissionDecimalPlaces),
                constants.commissionRange.max.toFixed(constants.commissionDecimalPlaces)
            );
        }

        return '';
    }

    const renderEditInput = (rating: Rating) => {
        const isChanged =
            !numericUtils.isNumber(commission[rating]) ||
            (originalCommission?.[rating] ?? 0) !== Number(commission[rating]);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const error = editErrors[rating] ? validate(e.target.value) : '';
            onChange?.(rating, e.target.value, error);
        };

        const handleBlur = () => {
            const current = String(
                commission[rating] === ''
                    ? originalCommission?.[rating] // Rollback to previous value if empty
                    : numericUtils.numberOrDefault(commission[rating], originalCommission?.[rating]) // Try parse editing value
            );
            onChange?.(rating, current, validate(current));
        };

        const handleRevert = () => onChange?.(rating, String(originalCommission?.[rating] ?? 0), '');

        return (
            <>
                <div className={cn("form-control-wrapper", {
                    'form-control-changed': isChanged,
                    'form-control-error': !!editErrors[rating]
                })}
                >
                    <MaskedInput
                        className="form-control"
                        guide={false}
                        disabled={disabled}
                        mask={createNumberMask({
                            prefix: '',
                            allowDecimal: true,
                            includeThousandsSeparator: false,
                            integerLimit: 3,
                            decimalLimit: constants.commissionDecimalPlaces,
                        })}
                        value={commission[rating] ?? constants.settlementAgentDefaultCommission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={e => e.target.select()}
                    />
                    <FormError message={editErrors[rating]} />
                </div>
                { isChanged && <RevertBidButton onRevert={handleRevert} />}
            </>
        );
    }

    const definitions: IColumnDefinition<Rating>[] = [
        {
            columnKey: "rating-name",
            renderColumnHeaderContent: () => "Rating",
            renderColumnContent: (r: Rating) => r,
        },
        {
            columnKey: "commission",
            renderColumnHeaderContent: () => <>Commission, %{!readonly && <span className="text-red">&nbsp;*</span>}</>,
            renderColumnContent: (rating: Rating) => readonly
                ? commission[rating]
                    ? formatUtils.formatDecimal(Number(commission[rating]), constants.commissionDecimalPlaces)
                    : formatUtils.formatDecimal(constants.settlementAgentDefaultCommission, constants.commissionDecimalPlaces)
                : renderEditInput(rating),
            className: 'data-list-commission overflow-visible text-right',
        }
    ];
    const columns = definitions.map(d => new ColumnBuilder(d));

    return (
        <Table
            className="data-list-striped"
            dataItems={Ratings}
            columns={columns}
        />
    );
}
