import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { helpResourcesActions } from "../../../actions/help-resources.actions";
import { AppState } from "../../../types/state/AppState";
import { isRequestNone } from "../../../utils";

export function useHelpResourcesCategories() {
    const dispatch = useDispatch();
    const categories = useSelector((s: AppState) => s.helpResources.categories);
    const categoriesRequestState = useSelector((s: AppState) => s.helpResources.categoriesRequestState);

    useEffect(() => {
        if (isRequestNone(categoriesRequestState)) {
            dispatch(helpResourcesActions.fetchCategories.request());
        }
    }, [categoriesRequestState, dispatch])

    return { categories, categoriesRequestState }
}