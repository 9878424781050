import { useState } from 'react';
import { RequestState } from '../../../constants/request-state';
import { manageStorageService } from '../../../services/manage-storage.service';

export const useClearRedisCache = () => {
    const [requestState, setRequestState] = useState(RequestState.none);

    const clearCache = async () => {
        setRequestState(RequestState.request);
        try {
            await manageStorageService.clearRedisCache();
            setRequestState(RequestState.success);
        } catch (e) {
            setRequestState(RequestState.failure);
        }
    }

    return {requestState, clearCache}
}
