import classNames from 'classnames';
import * as yup from "yup";
import { Formik } from 'formik';
import moment from 'moment';
import { errorMessages } from '../../constants/error.messages';
import { DateField } from '../common/form-fields/DateField';
import { Popup } from '../controls';

export interface TradeSubmitForm {
    tradeDate: Date;
}

const TradedConfirmValidationSchema = yup.object().shape({
    tradeDate: yup
        .date()
        .test('isValidDateFormat', errorMessages.invalidValue, (value) => moment(value, 'MM/DD/YYYY', true).isValid())
        .typeError(errorMessages.invalidValue)
        .max(moment().endOf('day'), errorMessages.dateShouldBeInPast)
        .required()
});

interface TradedConfirmPopupProps {
    ticker: string;
    onTradedChange: (tradeDate: Date) => void;
    onClose: () => void;
}

export const TradedConfirmPopup = ({ ticker, onTradedChange, onClose }: TradedConfirmPopupProps) => {
    const handleConfirmTraded = ({ tradeDate }: TradeSubmitForm) => {
        onTradedChange(tradeDate);
        onClose();
    }

    return (
        <Popup renderInBody={true} modalClass="modal-confirmation" title="Confirmation" onClose={onClose}>
            <Formik
                initialValues={{ tradeDate: new Date() }}
                validationSchema={TradedConfirmValidationSchema}
                onSubmit={handleConfirmTraded}
            >
                {({ submitForm, isValid }) => (
                    <>
                        <div className="modal-body">
                            <p className="status-message alert">
                                <i className="icon icon-warning" />
                                <span className="status-message-cnt">
                                    Would you like to mark security with {ticker} as traded? This operation is irreversible.
                                </span>
                            </p>
                            <DateField
                                label="Trade date"
                                name="tradeDate"
                                maximumDate={new Date()}
                                dateFormat="MM/DD/YYYY"
                                required
                            />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-ghost" onClick={onClose}>Cancel</button>
                            <button
                                disabled={!isValid}
                                className={classNames('btn', 'btn-main')}
                                onClick={submitForm}
                            >
                                Confirm
                            </button>
                        </div>
                    </>
                )}
            </Formik>
        </Popup >
    );
}
