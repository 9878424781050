import * as React from 'react';
import { keys, values as _values } from 'lodash';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { Company } from '../../../../types/management/Company';
import { OnHoverTooltip, Preloader } from "../../../common";
import { Checkbox } from '../../../controls/Checkbox';
import {
    useSettlementAgentDefaultCommission
} from '../../../../effects/data-accessors/useSettlementAgentDefaultCommission';
import { Rating, Ratings } from '../../../../types/enums/Rating';
import { CommissionByRatingTable, EditErrors } from './CommissionByRatingTable';
import { isRequesting, isRequestNone, isRequestSuccess, numericUtils } from '../../../../utils';
import { useDispatch } from 'react-redux';
import { clearingBankActions } from '../../../../actions';
import { SettlementAgentCommissionByRating } from '../../../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { SettlementAgentEditingCommissionByRating } from './SettlementAgentEditingCommissionByRating';
import { constants } from '../../../../constants/constants';
import IconSVG from "../../../../styles/svg-icons";

interface Props {
    company: Company
    onCancel: () => void
    onSave?: () => void;
}

export function SetClearingBankPopup({ company, onSave, onCancel }: Props) {
    const dispatch = useDispatch();

    const [isClearingBank, setIsClearingBank] = React.useState(Boolean(company.isClearingBank));
    const [editErrors, setEditErrors] = React.useState<EditErrors>({} as EditErrors);
    const { requestState, values } = useSettlementAgentDefaultCommission(company.id);
    const [editingCommission, setEditingCommission] =
        React.useState<SettlementAgentEditingCommissionByRating>({} as SettlementAgentEditingCommissionByRating);
    const hasEditingErrors = keys(editErrors).some(key => editErrors[Rating[key as keyof typeof Rating]]);
    const isLoading = isRequesting(requestState) || isRequestNone(requestState);

    React.useEffect(() => {
        if (isRequestSuccess(requestState) && keys(values).length && !keys(editingCommission).length) {
            setEditingCommission({ ...values });
        }
    }, [requestState, values, editingCommission]);

    React.useEffect(() => {
        if (!isClearingBank) {
            setEditingCommission(values);
        } // eslint-disable-next-line
    }, [isClearingBank]);

    const isCommissionByRatingChanged = isClearingBank && keys(editingCommission).some(key => {
        const rating = Rating[key as keyof typeof Rating];
        return values[rating] !== numericUtils.numberOrDefault(editingCommission[rating]);
    });

    const hasChanges = company.isClearingBank !== isClearingBank || isCommissionByRatingChanged;

    const hasEmptyValues = isClearingBank && keys(editingCommission).some(key =>
        numericUtils.numberOrDefault(editingCommission[Rating[key as keyof typeof Rating]]) === 0
    );


    const handleCommissionChange = (rating: Rating, commission: string, error: string) => {
        setEditErrors({ ...editErrors, [rating]: error });
        setEditingCommission({ ...editingCommission, [rating]: commission });
    };

    const handleSave = () => {
        const convertToNumbers = () => {
            const converted = {} as SettlementAgentCommissionByRating;
            Ratings.forEach(r => {
                converted[r] = numericUtils.numberOrDefault(
                    editingCommission[r],
                    constants.settlementAgentDefaultCommission
                );
            });

            return converted;
        }

        dispatch(clearingBankActions.saveClearingBankSettings(
            company.id,
            isClearingBank,
            convertToNumbers())
        );
        onSave?.();
    }

    const handleReset = () => {
        setEditingCommission((values) => {
            keys(values).forEach((key) => {
                values[key as Rating] = constants.commissionRange.min;
            })
            return values;
        });
        setEditErrors({} as EditErrors);
    }

    const isResetToDefaultDisabled = () => {
        if (!isRequestSuccess(requestState) || !isClearingBank) {
            return true;
        }
        return !_values(editingCommission).some(v => Number(v) !== constants.commissionRange.min)
    }

    return (
        <Popup
            modalClass="modal-clearing-bank"
            title={
                <>
                    <span className="title">Set Settlement Agent to</span>
                    <OnHoverTooltip overlay={company.name}>
                        <span className="name-tag text-ellipsis">{company.name}</span>
                    </OnHoverTooltip>
                </>
            }
            onClose={onCancel}
        >
            <PopupBody>
                <div className="flex-row controls-row">
                    <Checkbox
                        checked={isClearingBank}
                        onChange={() => setIsClearingBank(!isClearingBank)}
                        label="Settlement Agent"
                    />
                    <button
                        className="btn-link flex-item-right"
                        onClick={handleReset}
                        disabled={isResetToDefaultDisabled()}
                    >
                        <IconSVG name="close" width={16} height={16} />
                        Reset to default
                    </button>
                </div>
                <Preloader inProgress={isLoading}>
                    <CommissionByRatingTable
                        originalCommission={values}
                        commission={editingCommission}
                        readonly={false}
                        disabled={!isClearingBank}
                        editErrors={editErrors}
                        onChange={handleCommissionChange}
                    />
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onCancel}>Cancel</button>
                <button
                    className="btn btn-main"
                    disabled={!hasChanges || hasEditingErrors || isLoading || hasEmptyValues}
                    onClick={handleSave}
                >
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
}
