import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { editParsedBwicActions as actions, securitiesActions } from '../../../actions';
import { BwicDate, DaysToSettle, Time, TimeZone, BwicEmailReceivedDate } from './process';
import { Grid } from '../../grid';
import { CommandBar, EditBWICLeaveGuard } from '.';
import { Confirm } from '../../alerts';
import { routes } from '../../../constants';
import { Preloader } from '../../common/Preloader';
import { CollapsablePanel } from '../../common/CollapsablePanel';
import { EditParsedBwicRules } from './process/EditParsedBwicRules';

export const EditBWIC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { referenceName } = useParams();

    const bwic = useSelector(s => s.editParsedBwic.bwic);
    const cancelDialog = useSelector(s => s.editParsedBwic.cancelDialog);
    const isInitCompleted = useSelector(s => s.editParsedBwic.isInitCompleted);
    const saved = useSelector(s => s.editParsedBwic.saved);

    useEffect(() => {
        dispatch(actions.init(referenceName));

        return () => {
            dispatch(actions.reset());
        } // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (saved) {
            history.replace(routes.manageParsedBwics);
        } // eslint-disable-next-line
    }, [saved])

    return (
        <Preloader inProgress={!isInitCompleted || !bwic}>
            {isInitCompleted && bwic != null && (
                <div className="container container-manage-bwic">
                    <div className="sub-header">
                        <CommandBar />
                    </div>
                    <div className="container overflow-auto">
                        <div className="container-flex content-manage-bwic">
                            <CollapsablePanel title="BWIC Rules">
                                <div className="container-rules-area">
                                    <div className="form-row form-row-inline">
                                        <BwicDate />
                                        <Time />
                                        <TimeZone />
                                        <DaysToSettle />
                                        <BwicEmailReceivedDate />
                                    </div>
                                    <EditParsedBwicRules />
                                </div>
                            </CollapsablePanel>
                            <h2 className="securities-title">Securities</h2>
                            <Grid
                                onDataItemsUpload={file => dispatch(securitiesActions.uploadSecurities(file))}
                                onDataItemsPaste={clipboardText => dispatch(securitiesActions.handleClipboardData(clipboardText))}
                                onFileSelected={(file) => dispatch(securitiesActions.uploadSecurities(file))}
                                helpPopoverTitle="You can add your securities in the following ways"
                            />
                        </div>
                    </div>
                </div>
            )}
            {!saved && <EditBWICLeaveGuard />}
            {
                cancelDialog.visible &&
                <Confirm
                    text="Are you sure you want to cancel BWIC?"
                    onCancel={() => dispatch(actions.cancelDialog(false))}
                    onConfirm={() => dispatch(actions.cancelBwic(bwic.referenceName))}
                />
            }
        </Preloader>
    );
}
