import moment from 'moment';
import { Formik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { FormError, Popup, PopupBody, PopupFooter } from '../../../controls';
import { FormFieldLabel } from '../../../forms';
import { constants, errorMessages } from '../../../../constants';
import { yup } from '../../../../validation/yup';
import { changeSubscriptionActions } from '../../../../actions/change-subscription.actions';
import { OnHoverTooltip, Preloader } from "../../../common";
import { UserSlim } from '../../../../types/account/UserSlim';
import { useSubscriptions } from '../../../profile/tabs/subscriptions/hooks/useSubscriptions';
import { isRequestSuccess } from '../../../../utils';
import { SubscriptionType } from '../../../../types/billing/SubscriptionType';
import { isTrialExpired } from '../../../../utils/subscription.utils';
import { DayPickerInput } from '../../../controls/DayPickerInput';

const trialDefaultDays = 14;
const proDefaultYears = 1;

type subscriptionForm = {
    subscriptionType: string;
    subscriptionPeriodEnd?: Date;
    subscriptionPeriodEndRaw?: string;
};

interface Props {
    activeUsers: UserSlim[]
}

const minDate = moment()
    .startOf('day')
    //.add(1, 'd') Temporary changed due testing purposes - KTX-10417
    .toDate();

const validationSchema = yup.object().shape({
    subscriptionType: yup.string().required(),
    subscriptionPeriodEndRaw: yup.date().when('subscriptionType', {
        is: (type: string) => type === 'Basic',
        then: () => yup.date().nullable(),
        otherwise: () => yup
            .date()
            .test('isValidDateFormat', errorMessages.invalidValue, (value) => moment(value, 'MM/DD/YYYY', true).isValid())
            .typeError(errorMessages.invalidValue)
            .min(minDate, errorMessages.dateShouldNotBeInPast)
            .required()
    })
});

export function ChangeSubscriptionPopup({ activeUsers }: Props) {
    const dispatch = useDispatch();
    const { requestState, subscriptions } = useSubscriptions();

    const { popupVisible, company, isSubscriptionSavingFlag } = useSelector(
        (state: AppState) => state.changeSubscription,
    );

    if (!popupVisible || !company) {
        return null;
    }

    const initialValues: subscriptionForm = {
        subscriptionType: company.trialPeriodEnd && !isTrialExpired(company.trialPeriodEnd) ? SubscriptionType.trial : company.subscription,
        subscriptionPeriodEnd: company?.subscriptionExpiration,
        subscriptionPeriodEndRaw: company?.subscriptionExpiration?.toString() ?? ''
    };

    const companyActiveMemberCount = activeUsers.filter((user: UserSlim) => user.companyId === company.id).length;

    const handleClose = () => {
        if (!isSubscriptionSavingFlag) {
            dispatch(changeSubscriptionActions.hide());
        }
    };

    const handleSubmit = (values: subscriptionForm) => {
        const { subscriptionType, subscriptionPeriodEnd } = values;
        
        dispatch(
            changeSubscriptionActions.saveSubscription(company.id, subscriptionType, subscriptionPeriodEnd)
        );
    };

    const getDefaultPeriodEnd = (subscription: string) => {
        switch (subscription) {
            case SubscriptionType.basic:
                return undefined;
            case SubscriptionType.trial:
                return moment().startOf('day').add(trialDefaultDays, 'd').toDate();
            default:
                return moment().startOf('day').add(proDefaultYears, 'y').toDate();

        }
    }

    return (
        <Popup
            title={
                <>
                    <span className="title">Change Subscription</span>
                    <OnHoverTooltip overlay={company.name}>
                        <span className="name-tag text-ellipsis">{company.name}</span>
                    </OnHoverTooltip>
                </>
            }
            modalClass="modal-change-subscription"
            onClose={handleClose}
        >
            <Preloader inProgress={!isRequestSuccess(requestState)}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ values, setValues, dirty, errors, isValid }) => {
                        return (
                            <>
                                <PopupBody>
                                    <div className="flex-row">
                                        <div className="flex-item-equal">
                                            <dl className="definition-list style01">
                                                <dt>Company Role</dt>
                                                <dd>{company.rolesText}</dd>
                                            </dl>
                                        </div>
                                        <div className="flex-item-equal">
                                            <dl className="definition-list style01">
                                                <dt>Users</dt>
                                                <dd>{companyActiveMemberCount}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-item-equal">
                                            <FormFieldLabel required={true} text="Subscription Plan" />
                                            <select
                                                className="form-control form-select"
                                                name="subscriptionType"
                                                value={values.subscriptionType}
                                                onChange={e => {
                                                    const subscriptionPeriodEnd = getDefaultPeriodEnd(e.target.value)
                                                    setValues(() => ({
                                                        subscriptionType: e.target.value,
                                                        subscriptionPeriodEnd,
                                                        subscriptionPeriodEndRaw: subscriptionPeriodEnd ? subscriptionPeriodEnd.toString() : ''
                                                    }));
                                                }}
                                            >
                                                {[...subscriptions, { title: SubscriptionType.trial }].map(s =>
                                                    <option key={s.title} value={s.title}>{s.title}</option>
                                                )}
                                            </select>
                                            <FormError message={errors.subscriptionType} />
                                        </div>
                                        <div className="flex-item-equal">
                                            <FormFieldLabel required={true} text="Subscription Period End Date" />
                                            <div
                                                className={classNames('form-control-wrapper', {
                                                    disabled: values.subscriptionType === 'Basic'
                                                })}
                                            >
                                                <DayPickerInput
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors.subscriptionPeriodEndRaw,
                                                    })}
                                                    name="subscriptionPeriodEnd"
                                                    selectedDate={values.subscriptionPeriodEnd}
                                                    minimumDate={minDate}
                                                    placeholder="MM/DD/YYYY"
                                                    disabled={values.subscriptionType === 'Basic'}
                                                    dateFormat={constants.dateShortFormat}
                                                    onDayChange={(subscriptionPeriodEnd, subscriptionPeriodEndRaw) => {                                                        
                                                        setValues(prev => ({
                                                            ...prev,
                                                            subscriptionPeriodEnd: subscriptionPeriodEnd instanceof Date ? subscriptionPeriodEnd : undefined,
                                                            subscriptionPeriodEndRaw
                                                        }))
                                                    }}
                                                />
                                                <FormError message={errors.subscriptionPeriodEndRaw} />
                                            </div>
                                        </div>
                                    </div>
                                </PopupBody>
                                <PopupFooter>
                                    <button
                                        className="btn btn-ghost"
                                        disabled={isSubscriptionSavingFlag}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-main"
                                        type="submit"
                                        disabled={!dirty || isSubscriptionSavingFlag || !isValid}
                                        onClick={() => handleSubmit(values)}
                                    >
                                        Save
                                    </button>
                                </PopupFooter>
                            </>
                        );
                    }}
                </Formik>
            </Preloader>
        </Popup>
    );
}
