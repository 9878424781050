import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { DirectBiddingDisclaimerContext } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContext';

export interface NavBarSubItemProps {
    onClick: (e: SyntheticEvent) => void;
    path: string;
    title: React.ReactNode;
    disabled?: boolean;
    className?: string;
}

export const NavBarSubItem: React.FC<NavBarSubItemProps> = ({ path, title, onClick, disabled, className }) => {
    const history = useHistory();
    const isActive = history.location.pathname === path;
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const isDirectBiddingDisclaimerBlocked = React.useContext(DirectBiddingDisclaimerContext);
    const isBlocked = isFeatureBlocked || isDirectBiddingDisclaimerBlocked;
    const itemClassNames = classNames({
        "navbar-dropdown-menu-item": true,
        isActive,
        disabled
    }, className);

    if (isBlocked || disabled) {
        return <span className={itemClassNames}>{title}</span>
    }

    else if (isActive) {
        return <span className={itemClassNames}>{title}</span>
    }

    return (
        <Link onClick={onClick} className={itemClassNames} to={path}>
            {title}
        </Link>
    )
};
