import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { RouteLeavingGuard } from '../../routing';
import { history } from '../../../history';
import { dateTimeUtils, numericUtils, securitiesComparer } from '../../../utils';
import { getProcessSpecificSettings, setBwicDate } from '../../../actions';

class EditBWICLeaveGuard extends Component {

    render = () => {
        return (
            <RouteLeavingGuard
                navigate={pathname => history.replace(pathname)}
                shouldBlockNavigation={this.handleBlockNavigation}
            />
        );
    }

    handleBlockNavigation = () => {
        if (this.props.saved) {
            return false;
        }

        const {
            bwic,
            saved,
            positions,
            bwicDate,
            bidsDue,
            goodUntil,
            timeZone,
            daysToSettle,
            rulesText,
            reserveLevelsApply,
            canTradeEarly,
            canBidEarly,
            partialBidsAccepted,
            bwicProcessType,
            hardCutOff,
            improverCount,
            bidsGoodForHours,
            bidsGoodForMinutes,
            stage1EndDateUtc,
            stage2EndDateUtc
        } = this.props;

        const positionsChanged = !securitiesComparer.compareMultiple(bwic.securities, positions);

        if (!bwic.referenceName) {
            return positionsChanged;
        }

        const dateChanged = !moment.utc(bwic.date).startOf('day').isSame(moment.utc(bwicDate).startOf('day'));
        const bidsDueChanged = dateTimeUtils.timestampFromUtcString(bwic.bidsDue) !== dateTimeUtils.timestampFromUtcString(bidsDue);
        const goodUntilChanged = dateTimeUtils.timestampFromUtcString(bwic.goodUntil) !== dateTimeUtils.timestampFromUtcString(goodUntil);
        const timeZoneChanged = timeZone !== bwic.timeZone.id;
        const daysToSettleChanged = +bwic.daysToSettle !== +daysToSettle;
        const originalRulesText = (bwic.process && bwic.process.rulesText) || '';
        const rulesTextChanged = originalRulesText !== rulesText;
        const reserveLevelsApplyChanged = (reserveLevelsApply ?? null) !== (bwic.parsedProcess?.reserveLevelsApply ?? null);
        const canTradeEarlyChanged = (canTradeEarly ?? null) !== (bwic.parsedProcess?.canTradeEarly ?? null);
        const canBidEarlyChanged = (canBidEarly ?? null) !== (bwic.parsedProcess?.canBidEarly ?? null);
        const partialBidsAcceptedChanged = (partialBidsAccepted ?? null) !== (bwic.parsedProcess?.partialBidsAccepted ?? null);
        const bwicProcessTypeChanged = bwicProcessType !== bwic?.parsedProcess?.processType;
        const bidsGoodForTimeSpan = dateTimeUtils.parseTimeSpan(bwic.parsedProcess?.bidsGoodFor);
        const bidsGoodForHoursChanged = numericUtils.numberOrDefault(bidsGoodForHours) !== bidsGoodForTimeSpan?.hours ?? 0;
        const bidsGoodForMinutesChanged = numericUtils.numberOrDefault(bidsGoodForMinutes) !== bidsGoodForTimeSpan?.minutes ?? 0;
        const processSettings = getProcessSpecificSettings(bwic.parsedProcess);
        const hardCutOffChanged = hardCutOff !== processSettings?.hardCutOff;
        const improverCountChanged = improverCount !== processSettings?.improverCount;

        const isSameDate = (original, edit) => {
            const originalExist = Boolean(original);
            const editExist = Boolean(edit)

            if (originalExist !== editExist) return false
            if (!originalExist && !editExist) return true

            return setBwicDate(bwic.date, original).isSame(setBwicDate(bwic.date, edit));
        }
        const stage1EndDateUtcChanged = !isSameDate(stage1EndDateUtc, processSettings?.stage1EndDateUtc);
        const stage2EndDateUtcChanged = !isSameDate(stage2EndDateUtc, processSettings?.stage2EndDateUtc);

        return !saved && (
            dateChanged ||
            bidsDueChanged ||
            goodUntilChanged ||
            timeZoneChanged ||
            daysToSettleChanged ||
            rulesTextChanged ||
            reserveLevelsApplyChanged ||
            canBidEarlyChanged ||
            canTradeEarlyChanged ||
            partialBidsAcceptedChanged ||
            bwicProcessTypeChanged ||
            hardCutOffChanged ||
            improverCountChanged ||
            stage1EndDateUtcChanged ||
            stage2EndDateUtcChanged ||
            bidsGoodForHoursChanged ||
            bidsGoodForMinutesChanged ||
            positionsChanged
        );
    }
}

const mapStateToProps = ({ editParsedBwic, grid }) => ({
    bwic: editParsedBwic.bwic,
    bwicDate: editParsedBwic.date,
    bidsDue: editParsedBwic.bidsDue,
    goodUntil: editParsedBwic.goodUntil,
    timeZone: editParsedBwic.timeZone,
    daysToSettle: editParsedBwic.daysToSettle,
    rulesText: editParsedBwic.rulesText,
    reserveLevelsApply: editParsedBwic.reserveLevelsApply,
    canTradeEarly: editParsedBwic.canTradeEarly,
    canBidEarly: editParsedBwic.canBidEarly,
    partialBidsAccepted: editParsedBwic.partialBidsAccepted,
    bwicProcessType: editParsedBwic.bwicProcessType,
    hardCutOff: editParsedBwic.hardCutOff,
    improverCount: editParsedBwic.improverCount,
    bidsGoodForHours: editParsedBwic.bidsGoodForHours,
    bidsGoodForMinutes: editParsedBwic.bidsGoodForMinutes,
    stage1EndDateUtc: editParsedBwic.stage1EndDateUtc,
    stage2EndDateUtc: editParsedBwic.stage2EndDateUtc,
    saved: editParsedBwic.saved,
    positions: grid.dataItems.filter(i => !i.draft),
});

const connectedEditBWICLeaveGuard = connect(mapStateToProps)(EditBWICLeaveGuard);
export { connectedEditBWICLeaveGuard as EditBWICLeaveGuard };

