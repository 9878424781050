import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { OnHoverTooltip, Pluralize } from '../common';
import { AwaitingTrade } from '../../types/trades/AwaitingTrade';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';
import { user } from '../../user';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';

export const TradeCounterBadge = () => {
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const currentUser = user.current();
    const trades: AwaitingTrade[] = useSelector((s: AppState) => s.entities.awaitingTrades.items);
    let pendingTrades = 0
    let pendingAllocationTrades = 0;
    let pendingProceedsTrades = 0;
    trades.forEach(trade => {
        if (trade.status === TradeStatus.pending) pendingTrades += 1;
        if (trade.sellerSettlementStatus === TradeSettlementStatus.PendingProceeds && currentUser?.isClearingBank) pendingProceedsTrades += 1;
        if (trade.buyerSettlementStatus === TradeSettlementStatus.PendingProceeds && currentUser?.isClearingBank) pendingProceedsTrades += 1;
        if (trade.sellerSettlementStatus === TradeSettlementStatus.PendingAllocation) pendingAllocationTrades += 1;
        if (trade.buyerSettlementStatus === TradeSettlementStatus.PendingAllocation) pendingAllocationTrades += 1;
    })

    const totalTrades = pendingTrades + pendingAllocationTrades + pendingProceedsTrades

    if(!totalTrades || isFeatureBlocked) {
        return null
    }
    return (
        <span className="badge-wrapper display-inline">
            <OnHoverTooltip overlayClassName="blotter-tooltip-overlay" overlay={(
                <>
                    {!!pendingTrades && <Pluralize count={pendingTrades} singular="pending trade" />}
                    {!!pendingTrades && (!!pendingAllocationTrades || !!pendingProceedsTrades) && <hr/>}
                    {!!pendingAllocationTrades && <><Pluralize count={pendingAllocationTrades} singular="pending allocation" plural="pending allocation" /><br/></>}
                    {!!pendingProceedsTrades && currentUser?.isClearingBank && <Pluralize count={pendingProceedsTrades} singular="pending proceed" />}
                </>
            )}>
                <span className="badge">{totalTrades}</span>
            </OnHoverTooltip>
        </span>
    )
}
