import React, { useEffect, useState } from 'react';
import { buildService } from '../../../services/build.service';
import { Preloader } from '../../common';

interface Version {
    assemblyVersion: string;
    buildNumber: string;
}

export function BuildVersion() {
    const [version, setVersion] = useState<Version>({ assemblyVersion: '', buildNumber: '' });
    const [isRequesting, setIsRequesting] = useState(false);

    useEffect(() => {
        setIsRequesting(true);
        buildService
            .version()
            .then((version: Version) => {
                setVersion(version);
                setIsRequesting(false)
            });
    }, []);

    return (
        <Preloader inProgress={isRequesting}>
            <div>Current BWIC version: {version.assemblyVersion ? version.assemblyVersion : '-'}</div>
            <div>Build number: {version.buildNumber ? version.buildNumber : '-'}</div>
        </Preloader>
    )
}
