import React from 'react';
import { routes } from '../../constants';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { FeatureLink } from '../access/FeatureLink';
import { ActionBlocker } from '../access/ActionBlocker';

interface Props {
    count?: number;
    ticker: string;
}

export const SecurityInventoryLink: React.FC<Props> = ({ ticker, count }) => {
    return (
        <ActionBlocker feature={SubscriptionFeature.InventorySecuritySearch}>
            <FeatureLink
                withLockIcon
                path={`${routes.inventory}?isinCusipsAndTickers=${ticker}&dateFrom=unspecified&dateTo=unspecified`}
                target="_blank"
            >
                View{!!count && ` (${count})`}
            </FeatureLink>
        </ActionBlocker>
    )
}
