import { useDispatch, useSelector } from 'react-redux';
import { FilterButton, FilterClear, FilterPanel, FilterSection } from '../../filters';
import { FilterSelect } from '../../common/filters/FilterSelect';
import { bwicDateFilterOptions, roles } from '../../../constants';
import { AppState } from '../../../types/state/AppState';
import { DateRangeSelector } from '../../common';
import { DateFilterOption } from '../../../types/filters/DateFilterOption';
import { DateRange } from '../../../types/filters/DateRange';
import { manageMembersFiltersActions } from '../../../actions/manage-members-filter.actions';
import { isEqual, keys } from 'lodash';
import { UserStatus } from '../../../types/account/UserStatus';
import { companiesActions } from '../../../actions/companies.actions';
import { memberFilterInitialState } from '../../../reducers/companies.reducer';
import { userStatuses } from '../../../constants/user.statuses';
import { useRegByUsers } from './useRegByUsers';

export const systemUserName = 'KTX System';

interface FiltersProps {
    enabled: boolean;
}

export const MembersFilter = ({ enabled }: FiltersProps) => {
    const dispatch = useDispatch();
    const companiesList = useSelector((s: AppState) => s.companies.companies);
    const filter = useSelector((s: AppState) => s.companies.memberFilter);
    const lastAppliedMemberFilter = useSelector((s: AppState) => s.companies.lastAppliedMemberFilter);
    const filtersChanged = !isEqual({ ...filter, searchTerm: '' }, { ...lastAppliedMemberFilter, searchTerm: '' });
    const isClearFilterShown = enabled
        && !isEqual({ ...memberFilterInitialState, searchTerm: '' }, { ...filter, searchTerm: '' });
    const resetFilters = () => dispatch(companiesActions.resetMemberFilters());
    const regByUsers = useRegByUsers();

    return (
        <div className="filters-area flex-row align-items-flex-end">
            <FilterPanel>
                <FilterSection>
                    <FilterSelect
                        title="Status"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.active === lastAppliedMemberFilter.active &&
                            filter.blocked === lastAppliedMemberFilter.blocked &&
                            filter.invited === lastAppliedMemberFilter.invited &&
                            filter.notInvited === lastAppliedMemberFilter.notInvited &&
                            filter.pendingApproval === lastAppliedMemberFilter.pendingApproval
                        }
                        options={[
                            UserStatus.Active,
                            UserStatus.Blocked,
                            UserStatus.Invited,
                            UserStatus.NotInvited,
                            'pending-approval'
                        ].map((x: UserStatus | string) => ({
                            text: (userStatuses.getByStatusCode(x) && userStatuses.getByStatusCode(x)?.title) ?? 'Pending Approval',
                            value: x,
                            selected: (
                                (filter.active && x === UserStatus.Active) ||
                                (filter.blocked && x === UserStatus.Blocked) ||
                                (filter.invited && x === UserStatus.Invited) ||
                                (filter.notInvited && x === UserStatus.NotInvited) ||
                                (filter.pendingApproval && x === 'pending-approval')
                            ),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={(item => {
                            (item.value === UserStatus.Active && dispatch(manageMembersFiltersActions.toogleFilterStatusActive()));
                            (item.value === UserStatus.Blocked && dispatch(manageMembersFiltersActions.toogleFilterStatusBlocked()));
                            (item.value === UserStatus.Invited && dispatch(manageMembersFiltersActions.toogleFilterStatusInvited()));
                            (item.value === UserStatus.NotInvited && dispatch(manageMembersFiltersActions.toogleFilterStatusNotInvited()));
                            (item.value === 'pending-approval' && dispatch(manageMembersFiltersActions.toogleFilterStatusPendingApproval()));
                        })}
                        onClearAll={() => {
                            filter.active && dispatch(manageMembersFiltersActions.toogleFilterStatusActive());
                            filter.blocked && dispatch(manageMembersFiltersActions.toogleFilterStatusBlocked());
                            filter.invited && dispatch(manageMembersFiltersActions.toogleFilterStatusInvited());
                            filter.notInvited && dispatch(manageMembersFiltersActions.toogleFilterStatusNotInvited());
                            filter.pendingApproval && dispatch(manageMembersFiltersActions.toogleFilterStatusPendingApproval());
                        }}
                        onSelectAll={() => {
                            !filter.active && dispatch(manageMembersFiltersActions.toogleFilterStatusActive());
                            !filter.blocked && dispatch(manageMembersFiltersActions.toogleFilterStatusBlocked());
                            !filter.invited && dispatch(manageMembersFiltersActions.toogleFilterStatusInvited());
                            !filter.notInvited && dispatch(manageMembersFiltersActions.toogleFilterStatusNotInvited());
                            !filter.pendingApproval && dispatch(manageMembersFiltersActions.toogleFilterStatusPendingApproval());
                        }}
                    />
                    <FilterSelect
                        title="Role"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.administrator === lastAppliedMemberFilter.administrator &&
                            filter.arrangersClient === lastAppliedMemberFilter.arrangersClient &&
                            filter.brokerDealerTrader === lastAppliedMemberFilter.brokerDealerTrader &&
                            filter.brokerDealerViewer === lastAppliedMemberFilter.brokerDealerViewer &&
                            filter.dataEntry === lastAppliedMemberFilter.dataEntry &&
                            filter.sellerAdmin === lastAppliedMemberFilter.sellerAdmin &&
                            filter.sellerTrader === lastAppliedMemberFilter.sellerTrader &&
                            filter.sellerViewer === lastAppliedMemberFilter.sellerViewer &&
                            filter.subscriptionManager === lastAppliedMemberFilter.subscriptionManager &&
                            filter.viewer === lastAppliedMemberFilter.viewer &&
                            filter.collateralManager === lastAppliedMemberFilter.collateralManager &&
                            filter.media === lastAppliedMemberFilter.media
                        }
                        options={[
                            roles.Administrator,
                            roles.ArrangersClient,
                            roles.BrokerDealerTrader,
                            roles.BrokerDealerViewer,
                            roles.CollateralManager,
                            roles.DataEntry,
                            roles.Media,
                            roles.SellerAdministrator,
                            roles.SellerTrader,
                            roles.SellerViewer,
                            roles.SubscriptionManager,
                            roles.Viewer
                        ].map(x => ({
                            text: roles.getTitle(x),
                            value: x,
                            selected: (
                                (filter.administrator && x === roles.Administrator) ||
                                (filter.arrangersClient && x === roles.ArrangersClient) ||
                                (filter.brokerDealerTrader && x === roles.BrokerDealerTrader) ||
                                (filter.brokerDealerViewer && x === roles.BrokerDealerViewer) ||
                                (filter.dataEntry && x === roles.DataEntry) ||
                                (filter.sellerAdmin && x === roles.SellerAdministrator) ||
                                (filter.sellerTrader && x === roles.SellerTrader) ||
                                (filter.sellerViewer && x === roles.SellerViewer) ||
                                (filter.subscriptionManager && x === roles.SubscriptionManager) ||
                                (filter.viewer && x === roles.Viewer) ||
                                (filter.collateralManager && x === roles.CollateralManager) ||
                                (filter.media && x === roles.Media)
                            ),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            (item.value === roles.Administrator && dispatch(manageMembersFiltersActions.toogleFilterRoleAdministrator()));
                            (item.value === roles.ArrangersClient && dispatch(manageMembersFiltersActions.toggleFilterRoleArrangersClient()));
                            (item.value === roles.BrokerDealerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerTrader()));
                            (item.value === roles.BrokerDealerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerViewer()));
                            (item.value === roles.DataEntry && dispatch(manageMembersFiltersActions.toogleFilterRolesDataEntry()));
                            (item.value === roles.SellerAdministrator && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerAdministrator()));
                            (item.value === roles.SellerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerTrader()));
                            (item.value === roles.SellerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerViewer()));
                            (item.value === roles.SubscriptionManager && dispatch(manageMembersFiltersActions.toogleFilterRolesSubscriptionManager()));
                            (item.value === roles.Viewer && dispatch(manageMembersFiltersActions.toogleFilterRoleViewer()));
                            (item.value === roles.CollateralManager && dispatch(manageMembersFiltersActions.toogleFilterCollateralManager()));
                            (item.value === roles.Media && dispatch(manageMembersFiltersActions.toogleFilterRoleMedia()));
                        }}
                        onClearAll={() => {
                            filter.administrator && dispatch(manageMembersFiltersActions.toogleFilterRoleAdministrator());
                            filter.arrangersClient && dispatch(manageMembersFiltersActions.toggleFilterRoleArrangersClient());
                            filter.brokerDealerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerTrader());
                            filter.brokerDealerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerViewer());
                            filter.dataEntry && dispatch(manageMembersFiltersActions.toogleFilterRolesDataEntry());
                            filter.sellerAdmin && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerAdministrator());
                            filter.sellerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerTrader());
                            filter.sellerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerViewer());
                            filter.subscriptionManager && dispatch(manageMembersFiltersActions.toogleFilterRolesSubscriptionManager());
                            filter.viewer && dispatch(manageMembersFiltersActions.toogleFilterRoleViewer());
                            filter.collateralManager && dispatch(manageMembersFiltersActions.toogleFilterCollateralManager());
                            filter.media && dispatch(manageMembersFiltersActions.toogleFilterRoleMedia());
                        }}
                        onSelectAll={() => {
                            !filter.administrator && dispatch(manageMembersFiltersActions.toogleFilterRoleAdministrator());
                            !filter.arrangersClient && dispatch(manageMembersFiltersActions.toggleFilterRoleArrangersClient());
                            !filter.brokerDealerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerTrader());
                            !filter.brokerDealerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleBrokerDealerViewer());
                            !filter.dataEntry && dispatch(manageMembersFiltersActions.toogleFilterRolesDataEntry());
                            !filter.sellerAdmin && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerAdministrator());
                            !filter.sellerTrader && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerTrader());
                            !filter.sellerViewer && dispatch(manageMembersFiltersActions.toogleFilterRoleSellerViewer());
                            !filter.subscriptionManager && dispatch(manageMembersFiltersActions.toogleFilterRolesSubscriptionManager());
                            !filter.viewer && dispatch(manageMembersFiltersActions.toogleFilterRoleViewer());
                            !filter.collateralManager && dispatch(manageMembersFiltersActions.toogleFilterCollateralManager());
                            !filter.media && dispatch(manageMembersFiltersActions.toogleFilterRoleMedia());
                        }}
                    />
                    <FilterSelect
                        title="Company"
                        multiply={true}
                        withSearch={true}
                        disabled={!enabled}
                        isApplied={
                            lastAppliedMemberFilter.companies.length !== 0
                        }
                        options={companiesList.map(x => ({
                            text: x.name,
                            value: x.id,
                            selected: filter.companies.some(el => el === x.id),
                            visible: true,
                            disabled: false
                        }))}
                        onChangeItemSelection={item => {
                            dispatch(manageMembersFiltersActions.toogleFilterCompanies([item.value]));
                        }}
                        onClearAll={() => {
                            dispatch(manageMembersFiltersActions.setFilterCompanies([]));
                        }}
                        onSelectAll={() => {
                            dispatch(manageMembersFiltersActions.setFilterCompanies(companiesList.map(el => el.id)));
                        }}
                    />
                    <DateRangeSelector
                        restyled={true}
                        title="Reg. Day"
                        isApplied={
                            lastAppliedMemberFilter.selectedDateOption.key !== bwicDateFilterOptions.unspecified.key
                        }
                        onReset={() => dispatch(manageMembersFiltersActions.setDateFilterChange(bwicDateFilterOptions.unspecified))}
                        disabled={!enabled}
                        selectedDateOption={filter.selectedDateOption}
                        customDateRange={filter.customDateRange}
                        onSelectedDateChange={(dateOption: DateFilterOption) => dispatch(manageMembersFiltersActions.setDateFilterChange(dateOption))}
                        onCustomDateChange={(dateRange: DateRange) => dispatch(manageMembersFiltersActions.setDateFilterRangeChange(dateRange))}
                        acceptedOptions={[
                            bwicDateFilterOptions.unspecified,
                            bwicDateFilterOptions.thisWeek,
                            bwicDateFilterOptions.lastWeek,
                            bwicDateFilterOptions.lastMonth,
                            bwicDateFilterOptions.lastYear,
                            bwicDateFilterOptions.custom
                        ]}
                        disabledDays={{ after: new Date() }}
                    />
                    <FilterSelect
                        title="Reg. by"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            lastAppliedMemberFilter.regBy.length !== 0
                        }
                        options={keys(regByUsers)
                            // Replace Reg. by value by default text for system admins
                            .sort((a, b) => (regByUsers[Number(a)] ?? systemUserName).localeCompare(regByUsers[Number(b)] ?? systemUserName))
                            .map(regBy => ({
                                text: regByUsers[Number(regBy)] ?? systemUserName,
                                value: Number(regBy),
                                selected: filter.regBy.some(el => el === Number(regBy)),
                                visible: true,
                                disabled: false
                            }))}
                        onChangeItemSelection={item => {
                            dispatch(manageMembersFiltersActions.toogleFilterRegBy([item.value]));
                        }}
                        onClearAll={() => {
                            dispatch(manageMembersFiltersActions.setFilterRegBy([]));
                        }}
                        onSelectAll={() => {
                            dispatch(manageMembersFiltersActions.setFilterRegBy(keys(regByUsers).map(k => Number(k))));
                        }}
                    />
                    <FilterButton
                        caption="Apply"
                        disabled={!filtersChanged}
                        onClick={() => dispatch(companiesActions.applyMemberFilters())}
                    />
                    <FilterClear isShown={isClearFilterShown} onClick={resetFilters} />
                </FilterSection>
            </FilterPanel>
        </div>
    );
}
