import { useSelector } from 'react-redux';
import { manageParsedBwicsActions as actions } from '../../../actions';
import { FilterButton, FilterClear } from '../../filters';
import {
    bwicDateFilterOptions,
    bwicStatuses
} from "../../../constants";
import { FilterSelect } from '../../common/filters/FilterSelect';
import { AppState } from '../../../types/state/AppState';
import { DateFilterOption } from '../../../types/filters/DateFilterOption';
import { DateRange } from '../../../types/filters/DateRange';
import { isEqual } from 'lodash';
import { DateRangeFilter } from '../../common/filters/FilterDateRange/DateRangeFilter';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const Filter = () => {
    const dispatch = useAppDispatch();
    const lastAppliedFilter = useSelector((s: AppState) => s.manageParsedBwics.lastAppliedFilter);
    const filter = useSelector((s: AppState) => s.manageParsedBwics.filter);
    const initialFilter = useSelector((s: AppState) => s.manageParsedBwics.initialFilter);
    const searchInProgress = useSelector((s: AppState) => s.manageParsedBwics.searchInProgress);
    const isNextPageRequesting = useSelector((s: AppState) => s.manageParsedBwics.isNextPageRequesting);
    const isFiltersDisabled = searchInProgress || isNextPageRequesting;

    const handleStatusSelection = (statuses: number) =>
        dispatch(actions.filterStatusChange(statuses));

    const handleStateSelection = (state: boolean) =>
        dispatch(actions.filterPublishedChange(state));

    const handleCustomDateChange = (dateRange: DateRange) =>
        dispatch(actions.filterCustomDateChange(dateRange));

    const handleSelectedDateChange = (selectedOption: DateFilterOption) =>
        dispatch(actions.filterDateChange(selectedOption));

    const handleSearch = () => dispatch(actions.search());

    const filterApplied = isEqual(lastAppliedFilter, filter);
    const filterChanged = !isEqual(initialFilter, filter);

    const isApplyButtonDisabled = filterApplied || searchInProgress || isNextPageRequesting;

    const {
        selectedDateOption,
        customDateRange,
        statuses,
        stateOptions
    } = filter;

    return (
        <div className="filters-area flex-row align-items-flex-end">
            <DateRangeFilter
                title="Date"
                customClassName="wide-range-date"
                onReset={() => handleSelectedDateChange(bwicDateFilterOptions.unspecified)}
                selectedDateOption={selectedDateOption}
                customDateRange={customDateRange}
                onCustomDateChange={handleCustomDateChange}
                disabled={isFiltersDisabled}
                onSelectedDateChange={handleSelectedDateChange}
                lastAppliedFilter={lastAppliedFilter}
                initialFilter={initialFilter}
                filter={filter}
            />
            <FilterSelect
                title="Status"
                multiply={true}
                disabled={isFiltersDisabled}
                isApplied={
                    isEqual(lastAppliedFilter.statuses, filter.statuses)
                }
                options={bwicStatuses.toArray().map(x => ({
                    text: x.title,
                    value: x.key,
                    selected: (
                        statuses.some(el => el.selected && el.value === x.key)
                    ),
                    visible: true,
                    disabled: false
                }))
                }
                onChangeItemSelection={item => {
                    handleStatusSelection(item.value);
                }}
                onClearAll={() => {
                    statuses.filter(el => el.selected).forEach((item) => {
                        handleStatusSelection(Number(item.value));
                    })
                }}
                onSelectAll={() => {
                    statuses.filter(el => !el.selected).forEach((item) => {
                        handleStatusSelection(Number(item.value));
                    })
                }}
            />
            <FilterSelect
                title="BWIC State"
                multiply={true}
                disabled={isFiltersDisabled}
                isApplied={
                    isEqual(lastAppliedFilter.stateOptions, filter.stateOptions)
                }
                options={[
                    { value: true, text: 'Published', selected: stateOptions?.[0]?.selected, visible: true, disabled: false },
                    { value: false, text: 'Unpublished', selected: stateOptions?.[1]?.selected, visible: true, disabled: false }
                ]}
                onChangeItemSelection={item => {
                    handleStateSelection(item.value);
                }}
                onClearAll={() => {
                    stateOptions.filter(el => el.selected).forEach((item) => {
                        handleStateSelection(Boolean(item.value));
                    })
                }}
                onSelectAll={() => {
                    stateOptions.filter(el => !el.selected).forEach((item) => {
                        handleStateSelection(Boolean(item.value));
                    })
                }}
            />
            <FilterButton
                caption="Apply"
                disabled={isApplyButtonDisabled}
                onClick={handleSearch}
            />
            <FilterClear isShown={filterChanged && !searchInProgress} onClick={() => dispatch(actions.resetFilters())} />
        </div>
    );
}
