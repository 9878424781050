import { InventoryPositionStatus } from '../../types/inventory/InventoryPositionStatus';

export const inventoryPositionStatusTitle = {
    [InventoryPositionStatus.added]: 'New',
    [InventoryPositionStatus.updated]: 'Updated',
    [InventoryPositionStatus.traded]: 'Sold',
};

export const  inventoryPositionStatusTitleList: string[] = [
    inventoryPositionStatusTitle[InventoryPositionStatus.added],
    inventoryPositionStatusTitle[InventoryPositionStatus.updated],
    inventoryPositionStatusTitle[InventoryPositionStatus.traded],
];

export const getInventoryPositionStatusByTitle = (title: string): InventoryPositionStatus | undefined => {
    if (title) {
        const keyValue = Object
            .entries(inventoryPositionStatusTitle)
            .find(([key, value]) => title.localeCompare(value, undefined, { sensitivity: 'accent' }) === 0);
        if (keyValue) {
            const [key] = keyValue;
            return Number(key);
        }
    }
    return undefined;
};
