import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editParsedBwicActions as actions } from '../../../../actions';
import { Counter } from '../../../controls';


class DaysToSettle extends Component {
    render = () => {
        const { daysToSettle } = this.props;

        return (
            <div className="form-item days-to-settle">
                <label className="form-label">Days to Settle</label>
                <Counter
                    min={0}
                    max={5}
                    emptyEnabled={false}
                    value={daysToSettle}
                    onValueChanged={this.handleDaysChanged}
                />
            </div>
        );
    }

    handleDaysChanged = days => this.props.dispatch(actions.changeDaysToSettle(days));
}

const mapStateToProps = ({ editParsedBwic }) => ({
    daysToSettle: editParsedBwic.daysToSettle,
});


const connectedDaysToSettle = connect(mapStateToProps)(DaysToSettle);
export { connectedDaysToSettle as DaysToSettle };
