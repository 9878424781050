import React, { Fragment } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Support } from '../common';
import { routes } from '../../constants';
import { Footer } from "./Footer";

export const AnonymousLayout = props => {
    return (
        <Fragment>
            <header>
                <nav className="navbar navbar-no-border">
                    <Link to="/" className="nav-brand">
                        <i className="icon icon-logo-bwic"></i>
                    </Link>

                    <div className="navbar-item-right navbar-item-anonymous-state">
                        <Support />
                        <Switch>
                            <Route path={[routes.login, routes.verifyIdentityRequest]}>
                                <span className="text-sm info-text">Not a member?</span>
                                <Link to={routes.signUp} className="btn btn-ghost btn-full-width">sign up</Link>
                            </Route>

                            <Route>
                                <span className="text-sm info-text">Have an account?</span>
                                <Link to={routes.login} className="btn btn-ghost btn-full-width">sign in</Link>
                            </Route>
                        </Switch>
                    </div>
                </nav>
            </header>

            <main>
                {props.children}
            </main>

            <Footer />

        </Fragment>
    );
}
