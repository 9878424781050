import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import { FooterCopyright } from "./Copyright";
import { classnames } from '../../styles/classnames';
import { user } from '../../user';

export const Footer = props => {
    const footerClasses = classnames({
        'footer': true,
        'default-footer': props.defaultFooter
    });
    return (
        <footer className={footerClasses}>
            <FooterCopyright />
            <nav className="footer-nav">
                <Link to={routes.ATSRulebook}>ATS Rulebook</Link>
                <Link to={routes.termsAndConditions}>Terms & Conditions</Link>
                <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                <Link to={routes.disclaimers}>
                    {user.isAuthenticated() ? "Disclaimer & Disclosure" : "Disclaimers"}
                </Link>
            </nav>
        </footer>
    )
};
