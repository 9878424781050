import moment from "moment";
import { News } from "../../../types/news/News";
import { newsCategoryTitles } from "../../../types/news/enums/NewsCategory";
import { newsLabelTitles } from "../../../types/news/enums/NewsLabel";
import { FormField } from "../../amrPipeline/common/FormField";
import { constants, routes } from "../../../constants";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IconSVG from "../../../styles/svg-icons";
import { Company } from "../../../types/amr-pipeline/models/Company";
import { editNewsActions } from "../../../actions/edit-news.actions";
import { EditNewsTabType } from "../../../types/news/enums/EditNewsTabType";
import { useState } from "react";
import { Confirm } from "../../alerts";
import { newsProviderTitles } from "../../../types/news/enums/NewsProvider";
import { OnHoverTooltip } from "../../common";
import DownloadDocument from '../../amrPipeline/common/documents/DownloadDocument';
import { FileIcon } from '../../controls';
import { newsActions } from '../../../actions/news.actions';

interface Props {
    data: News;
    managers: Company[];
    arrangers: Company[];
}

export const ViewForm = ({ data, managers, arrangers }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visibleDeleteConfirmationPopup, setVisibleDeleteConfirmationPopup] =
        useState(false);
    const {
        referenceName,
        title,
        category,
        label,
        provider,
        description,
        newsDateTime,
        externalSourceLink,
        transactionLink,
        cloManagerReferenceName,
        arrangerReferenceName,
        newsDocuments,
    } = data;

    const managerLegalName = managers.find(
        (m) => m.referenceName === cloManagerReferenceName
    )?.legalName;
    const arrangerLegalName = arrangers.find(
        (m) => m.referenceName === arrangerReferenceName
    )?.legalName;

    const switchToEditMode = () =>
        history.replace(
            routes.manageNewsUrl(referenceName, EditNewsTabType.edit)
        );

    const handleOnDelete = () =>
        dispatch(editNewsActions.deleteNews(referenceName as string));

    const renderHeader = () => (
        <div className="sub-header">
            <div className="sub-header-row type04 flex-row">
                <h1>{title}</h1>
                <Link
                    to={routes.newsUrl(referenceName)}
                    className="btn-link"
                >
                    <IconSVG name="go-to" width={16} height={16} />
                    View Article
                </Link>
                <div className="flex-item-right flex-row">
                    <button className="btn-link btn-danger" onClick={() => setVisibleDeleteConfirmationPopup(true)}>
                        <i className="icon icon-delete" />
                        Delete
                    </button>
                    <button
                        className="btn btn-ghost btn-sm"
                        onClick={switchToEditMode}
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );

    const renderFiles = () => {
        if (!newsDocuments?.length) {
            return constants.emptyPlaceholder;
        }

        return newsDocuments.map((file) => (
            <div className="document-item">
                <DownloadDocument
                    onClick={() => dispatch(newsActions.downloadDocument(referenceName, file.referenceName, file.name))}
                    document={file}
                    secondary
                >
                    <FileIcon filename={file.name} />
                    <span>{file.name}</span>
                </DownloadDocument>
            </div>
        ));
    };

    return (
        <div className="container-flex">
            {renderHeader()}
            <div className="tabs-data tabs-data-padding">
                <div className="news-info-box news-info-box-view">
                    <FormField title="Title" className="data-item-row-title-md data-item-row-full-height">{title}</FormField>
                    <FormField title="Category" className="data-item-row-title-md data-item-row-full-height">
                        {newsCategoryTitles[category]}
                    </FormField>
                    <FormField title="Event/Label" className="data-item-row-title-md data-item-row-full-height">{newsLabelTitles[label]}</FormField>
                    <FormField title="News Date" className="data-item-row-title-md data-item-row-full-height">
                        {moment(newsDateTime).utc().format(constants.dateTimeFormat)}
                    </FormField>
                    <FormField title="News Provider" className="data-item-row-title-md data-item-row-full-height">{newsProviderTitles[provider]}</FormField>
                    <FormField title="External Source" className="data-item-row-title-md data-item-row-full-height">
                        {externalSourceLink ? (
                            <OnHoverTooltip overlay={externalSourceLink}>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={externalSourceLink}
                                    className="btn-link btn-link-lg secondary regular text-ellipsis"
                                >
                                    {externalSourceLink}
                                </a>
                            </OnHoverTooltip>

                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Deal Details" className="data-item-row-title-md data-item-row-full-height">
                        {transactionLink ? (
                            <OnHoverTooltip overlay={transactionLink}>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={transactionLink}
                                    className="btn-link btn-link-lg secondary regular text-ellipsis"
                                >
                                    {transactionLink}
                                </a>
                            </OnHoverTooltip>
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Manager Profile" className="data-item-row-title-md data-item-row-full-height">
                        {cloManagerReferenceName ? (
                            <OnHoverTooltip overlay={managerLegalName}>
                                <a
                                    href={`${routes.manageCloManagerUrl(
                                        cloManagerReferenceName
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link btn-link-lg secondary regular text-ellipsis"
                                >
                                    {managerLegalName}
                                </a>
                            </OnHoverTooltip>
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Arranger" className="data-item-row-title-md data-item-row-full-height">
                        {arrangerReferenceName && arrangerLegalName ? (
                            <OnHoverTooltip overlay={arrangerLegalName}>
                                <a
                                    href={routes.manageBanksUrl(arrangerReferenceName)}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link btn-link-lg secondary regular text-ellipsis"
                                >
                                    {arrangerLegalName}
                                </a>
                            </OnHoverTooltip>
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Description" className="data-item-row-title-md data-item-row-full-height">
                        <div dangerouslySetInnerHTML={{ __html: description }} className="news-description" />
                    </FormField>
                    <FormField title="Files" className="data-item-row-title-md data-item-row-full-height">
                        {renderFiles()}
                    </FormField>
                </div>
            </div>
            {visibleDeleteConfirmationPopup && (
                <Confirm
                    title="Remove News"
                    confirmButtonText="Confirm"
                    onCancel={() => setVisibleDeleteConfirmationPopup(false)}
                    onConfirm={handleOnDelete}
                    text={
                        <span>
                            Are you sure you want to remove <b>{title}</b> news?
                        </span>
                    }
                />
            )}
        </div>
    );
};
