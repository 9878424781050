import { ChangeEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import IconSVG from '../../../styles/svg-icons';

interface CompaniesUsersSearchProps {
    enabled?: boolean,
    searchTerm?: string,
    onSearchTermChange: (searchTerm: string) => void
}

export const TokenSearch = ({ enabled = true, searchTerm, onSearchTermChange }: CompaniesUsersSearchProps) => {
    const refInput = useRef<HTMLInputElement>(null);
    const [inputFocus, setInputFocus] = useState(false);

    const handleClearSearchTerm = () => {
        onSearchTermChange('');
        if (refInput.current) {
            refInput.current.focus();
        }
    };

    return (
        <div className={classNames(
            'control-search-wrapper',
            { 'control-search-focus': inputFocus, 'disabled': !enabled }
        )}>
            <div className="form-control-search-btn">
                <i className="icon icon-search" />
            </div>
            <input
                ref={refInput}
                disabled={!enabled}
                className="form-control form-control-search"
                type="text"
                value={searchTerm || ''}
                placeholder="Search by company name or shortcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchTermChange(e.target.value)}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
            />
            {
                !!searchTerm &&
                <button className="btn-close" disabled={!enabled} onClick={handleClearSearchTerm}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            }
        </div>
    )
}
