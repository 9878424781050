import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { FilterOption } from "../../../types/filters/FilterOption";
import { createFilterActions } from "../../../actions/filter.actions";
import { BwicFilterType } from "../../../types/filters/FilterState";
import { useBrokerDealers } from "../../../effects/useBrokerDealers";
import { isRequestSuccess } from "../../../utils";
import { BrokerDealerCompanySlim } from "../../../types/company/BrokerDealerCompanySlim";

export const useBrokerDealersFilterData = (
    filterType: BwicFilterType,
    filterKey: string = 'dealerIds',
    filterItems: (item: BrokerDealerCompanySlim) => boolean = () => true
) => {
    const filterActions = createFilterActions(filterType);
    const dispatch = useDispatch();
    const brokerDealerListState = useBrokerDealers();
    const brokerDealers = brokerDealerListState.items;
    const brokerDealersRequestState = brokerDealerListState.requestState;

    useEffect(() => {
        if (isRequestSuccess(brokerDealersRequestState)) {
            const dealerOptions = [...brokerDealers]
                .filter(filterItems)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(d =>
                ({
                    value: d.id,
                    text: d.name,
                    selected: false,
                    visible: true,
                    disabled: false
                })) as FilterOption[];
            dispatch(filterActions.storeFilterData.success({
                filterType: filterType,
                filterName: filterKey,
                data: dealerOptions
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, filterKey, brokerDealersRequestState, dispatch])

    return brokerDealerListState.requestState;
}