import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { routes } from '../../../constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequestNone, isRequestSuccess } from '../../../utils';
import { bdInventoryDocuments } from '../../../actions/bd-inventory-documents.actions';
import { bdInventoryActions } from '../../../actions';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { ManageDealDocuments } from '../../documents/ManageDealDocuments';

export function InventoryDocuments() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { requestState } = useSelector((s: AppState) => s.entities.inventory);
    const isUploading = useSelector((s: AppState) => s.uploadDocuments.isUploading);
    const isInitialized = useSelector((s: AppState) => s.uploadDocuments.isInitialized);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            dispatch(bdInventoryActions.init())
        }
        if (isRequestSuccess(requestState)) {
            dispatch(bdInventoryDocuments.init())
        }
        return () => {
            dispatch(bdInventoryDocuments.reset())
        }
    }, [dispatch, requestState]);

    return (
        <div className="container my-inventory">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <Breadcrumbs>
                        <BreadcrumbsItem route={routes.inventory} text='Inventory' />
                    </Breadcrumbs>
                    <h1>Upload Documents</h1>
                    <div className="flex-item-right controls">
                        <button
                            className="btn btn-ghost"
                            onClick={() => history.goBack()}
                            disabled={isUploading || !isInitialized}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <ManageDealDocuments />
        </div>
    )
}
