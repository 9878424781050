import React, { useEffect } from 'react';
import { routes } from '../../constants';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { user } from '../../user';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { shouldRequest } from '../../utils';
import { clientsCompaniesActions } from '../../actions/clients-companies.actions';
import { NavBarSubItem } from './NavBarSubItem';
import { FlexRow } from '../common/FlexRow';

interface ClientsMenuItemProps {
    onClick: () => void
}

export const ClientsMenuItem: React.FC<ClientsMenuItemProps> = ({ onClick }) => {
    const dispatch = useDispatch();

    const currentUser = user.current();
    const isClearingBank = currentUser?.isClearingBank;

    const pendingAgreementCount = useSelector((state: AppState) => state.clientsCompanies.pendingAgreementCount);
    const requestStateFetchPendingAgreementCount = useSelector((state: AppState) => state.clientsCompanies.requestStateFetchPendingAgreementCount);

    useEffect(() => {
        if (shouldRequest(requestStateFetchPendingAgreementCount) && isClearingBank) {
            dispatch(clientsCompaniesActions.fetchPendingAgreementCountRequest());
        }
    }, [dispatch, requestStateFetchPendingAgreementCount, isClearingBank]);

    if (!isClearingBank) {
        return null;
    }

    return (
        <>
            {
                user.current()?.isClearingBank &&
                <FlexRow>
                    <NavBarSubItem
                        onClick={onClick}
                        path={routes.clients}
                        title={(
                            <>
                                Clients
                                {
                                    isClearingBank && !!pendingAgreementCount &&
                                    <OnHoverTooltip overlay="Pending requests">
                                        <span className="badge">{pendingAgreementCount}</span>
                                    </OnHoverTooltip>
                                }
                            </>
                        )}
                    />
                </FlexRow>
            }
        </>
    )
};
