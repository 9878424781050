import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { CompanyList, MemberList } from './';
import { CompaniesFilter } from './CompaniesFilter';
import { MembersFilter } from './MembersFilter';
import { companiesActions as actions } from '../../../actions';
import { ButtonSwitch } from '../../controls';
import { routes } from '../../../constants';
import IconSVG from '../../../styles/svg-icons';
import { getMembers, getCompanies } from "../../../selectors";
import { Preloader } from '../../common';
import { isRequesting } from '../../../utils';
import { CompaniesUsersSearch } from './CompaniesUsersSearch';
import queryString from 'query-string';

export const ManageCompanies = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { search } = queryString.parse(location.search);
    const isCompaniesView = location.pathname === routes.manageCompanies;
    const isInitCompleted = useSelector((s) => s.companies.isInitCompleted);
    const companyFilter = useSelector((s) => s.companies.companyFilter);
    const memberFilter = useSelector((s) => s.companies.memberFilter);
    const exportUsersRequestState = useSelector((s) => s.companies.exportUsersRequestState);
    const iRequestingExportCompanies = useSelector((s) => s.companies.iRequestingExportCompanies);
    const hasFilteredMembers = useSelector((s) => getMembers(s.companies).length);
    const hasFilteredCompanies = useSelector((s) => getCompanies(s.companies).length);
    const pendingUserRequestCount = useSelector((s) => s.companies.pendingUserRequestCount);

    useEffect(() => {
        dispatch(actions.init());
    }, [dispatch]);

    const handleCompanyFilterChanged = filter => dispatch(actions.filterCompanies(filter));

    const handleMemberFilterChanged = filter => dispatch(actions.filterMembers(filter));

    const handleChangeView = (isCompanyView) => {
        const path = isCompanyView ? routes.manageCompanies : routes.manageCompanyMembers;
        history.replace(path);
    };

    const filter = isCompaniesView ? companyFilter : memberFilter;
    const onFilterChanged = isCompaniesView ? handleCompanyFilterChanged : handleMemberFilterChanged;
    const handleSearchTermChange = searchTerm => onFilterChanged({ ...filter, searchTerm });

    useEffect(() => {
        if(search) {
            handleSearchTermChange(search);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
        <div className="container manage-cnt">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Manage Companies & Users</h1>
                    <CompaniesUsersSearch
                        enabled={isInitCompleted}
                        searchTerm={filter.searchTerm}
                        handleSearchTermChange={handleSearchTermChange}
                    />
                    <div className="flex-row flex-item-right controls">
                        {!!pendingUserRequestCount &&
                            <span>Unapproved Users: <span className="text-medium">{pendingUserRequestCount}</span></span>
                        }
                        <ButtonSwitch
                            className="control-group-slide"
                            sourceItems={[{ title: 'Companies', key: true }, { title: 'Users', key: false }]}
                            selectedValue={isCompaniesView}
                            onSelectionChange={handleChangeView}
                        />
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row">
                    {isCompaniesView
                        ? <CompaniesFilter enabled={isInitCompleted} />
                        : <MembersFilter enabled={isInitCompleted} />
                    }
                    <div className="flex-item-right controls">
                        {isCompaniesView ? (
                            <Preloader small={true} fullScreen={false} inProgress={iRequestingExportCompanies} text="Exporting…">
                                <button
                                    onClick={() => dispatch(actions.exportCompanies())}
                                    className="btn-link"
                                    disabled={!isInitCompleted || !hasFilteredCompanies}
                                >
                                    <IconSVG name="export" width={16} height={16} />Export
                                </button>
                            </Preloader>
                        ) : (
                            <Preloader small={true} fullScreen={false} inProgress={isRequesting(exportUsersRequestState)} text="Exporting…">
                                <button
                                    onClick={() => dispatch(actions.exportUsers())}
                                    className="btn-link"
                                    disabled={!hasFilteredMembers || memberFilter.pendingApproval}
                                >
                                    <IconSVG name="export" width={16} height={16} />Export
                                </button>
                            </Preloader>
                        )}
                        <Link
                            disabled={!isInitCompleted}
                            className="btn btn-sm btn-ghost"
                            to={routes.manageCompaniesCreateUser}
                        >
                            <IconSVG name="plus" width={16} height={16} />
                            <span>New User</span>
                        </Link>
                        {
                            isCompaniesView &&
                            <Link disabled={!isInitCompleted} to={routes.addCompany} className="btn btn-sm btn-ghost">
                                <IconSVG name="plus" width={16} height={16} />
                                <span>New Company</span>
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <Switch>
                <Route exact={true} component={MemberList} path={routes.manageCompanyMembers} />
                <Route exact={true} component={CompanyList} path={routes.manageCompanies} />
            </Switch>
        </div>
    );
};
