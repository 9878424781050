import { useEffect, useState } from "react"
import { RequestState } from "../../../constants/request-state";
import { logger } from "../../../logging/logger";
import { manageEmailsService } from "../../../services/manage-emails.service";
import { ManageEmailTypeOption } from "../../../types/management/ManageEmailDetails";
import { isRequestNone } from "../../../utils";

export const useEmailTypeOptions = (): [ManageEmailTypeOption[], RequestState] => {
    const [emailTypesRequestState, setEmailTypesRequestState] = useState(RequestState.none);
    const [typeOptions, setTypeOptions] = useState<ManageEmailTypeOption[]>([]);

    useEffect(() => {
        const fetchOptions = async () => {
            setEmailTypesRequestState(RequestState.request);
            try {
                const options = await manageEmailsService.getEmailTypes();
                setTypeOptions(options);
                setEmailTypesRequestState(RequestState.success);
            }
            catch (e) {
                logger.exception(e as Error, 'Failed to request email types');
                setEmailTypesRequestState(RequestState.failure);
            }
        }

        if (isRequestNone(emailTypesRequestState)) {
            fetchOptions();
        }
    }, [emailTypesRequestState])

    return [typeOptions, emailTypesRequestState];
}