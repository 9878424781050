import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { gridActions } from '../../../actions';
import { routes } from '../../../constants';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    disabled: boolean;
    isCurrentInventoryEmpty: boolean;
    onSave: () => void;
}

export function InventoryEditActionButtons({ disabled, isCurrentInventoryEmpty, onSave }: Props) {
    const dispatch = useAppDispatch();
    const editingInventoryList: any[] = useSelector((s: AppState) => s.grid.dataItems.filter((i: any) => !i.draft));

    const canSave =
        !disabled &&
        (editingInventoryList.length > 0 || !isCurrentInventoryEmpty) && // allow remove all existing positions
        !editingInventoryList.some(s => s.errors?.length); // no validation errors

    const handleSaveClick = () => {
        dispatch(gridActions.validate());
        onSave();
    };

    return (
        <>
            <Link className="btn btn-ghost" to={routes.inventory}>Cancel</Link>
            <button className="btn btn-main" disabled={!canSave} onClick={handleSaveClick}>Publish</button>
        </>
    );
}
