import { Table } from "../../bidding/common/table";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../bidding/common/table/types/ColumnDefinition";
import { SORT } from "../../../constants";
import { ProgressCircle } from "../../controls";
import FileIcon from "../../controls/FileIcon";
import IconSVG from "../../../styles/svg-icons";
import { OnHoverTooltip } from "../../common";
import { AmrFile } from "../../../types/amr-pipeline/models/AmrFile";
import { FilesUploaderChildProps } from "../../amrPipeline/common/documents/FileUploader";
import { newsActions } from "../../../actions/news.actions";
import { useAppDispatch } from "../../../effects/useAppDispatch";

export function FileList({
    referenceName,
    documents,
    progress,
    handleDeleteFile,
}: FilesUploaderChildProps) {
    const dispatch = useAppDispatch();
    const downloadDocument =
        (documentToDownload: AmrFile) =>
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (referenceName) {
                dispatch(
                    newsActions.downloadDocument(
                        referenceName,
                        documentToDownload.referenceName,
                        documentToDownload.name
                    )
                );
            }
        };

    const getTableColumns = () => {
        const columns: IColumnDefinition<AmrFile>[] = [
            {
                columnKey: "name",
                renderColumnContent: (document) => (
                    <OnHoverTooltip overlay={document.name}>
                        <button
                            className="btn-link text-ellipsis btn-download-file"
                            onClick={downloadDocument(document)}
                        >
                            <FileIcon filename={document.name} />
                            {document.name}
                        </button>
                    </OnHoverTooltip>
                ),
                renderColumnHeaderContent: () => "Name",
                headerClassName: "data-list-cell-xl-flexible",
                bodyClassName: "data-list-cell-xl-flexible",
            },
            {
                columnKey: "progress",
                renderColumnContent: (document) =>
                    document.referenceName ? null : (
                        <ProgressCircle progress={progress[document.name]} />
                    ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-sm",
                bodyClassName: "data-list-cell-sm",
            },
            {
                columnKey: "delete-action",
                renderColumnContent: (document) => (
                    <button
                        className="btn-link btn-danger"
                        onClick={handleDeleteFile(document.referenceName)}
                    >
                        <IconSVG name="basket" width={16} height={16} />
                    </button>
                ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs",
                bodyClassName: "data-list-cell-xxs",
            },
        ];

        return columns.map((c) => new ColumnBuilder(c));
    };

    return (
        <div className="component-file-upload-list">
            <Table
                columns={getTableColumns()}
                dataItems={documents}
                defaultSortBy="uploadTime"
                defaultSortByDirection={SORT.DESC}
                className="component-file-upload-list data-list-striped"
            />
        </div>
    );
}

