import { isEqualWith, round } from 'lodash';
import { useHistory } from 'react-router';
import { InventoryPosition } from '../../../types/inventory/InventoryPosition';
import { apiUtils } from '../../../utils/api.utils';
import { RouteLeavingGuard } from '../../routing';
import { numericUtils } from '../../../utils';
import { GridDataItem } from '../../../types/state/GridState';
import { useAppSelector } from '../../../effects/useAppSelector';
import { InventoryTablePosition } from '../bd/InventoryTablePosition';

interface Props {
    inventoryPositions: InventoryPosition[];
}

export function InventoryEditLeavingGuard({ inventoryPositions }: Props) {
    const history = useHistory();

    const editingItems: GridDataItem<InventoryTablePosition>[] =
        useAppSelector(s => s.grid.dataItems.filter((i: GridDataItem<InventoryTablePosition>) => !i.draft));

    const handleBlockNavigation = () => {
        if (!inventoryPositions.length && !editingItems.length) {
            return false;
        }

        if (
            inventoryPositions.length !== editingItems.length ||
            editingItems.some(i => i.errors?.length) ||  // has error
            editingItems.some(i => !i.id)) { // has new item
            return true;
        }

        const editingMap = apiUtils.normalize(editingItems, e => e.id, e => e);

        for (let item of inventoryPositions) {
            const edit = editingMap[item.id];

            if (!edit) return true;

            const editCopy = { ...edit };

            delete editCopy.readonly;
            delete editCopy.ncEnd;
            delete editCopy.riEnd;
            delete editCopy.coupon;
            delete editCopy.collateralManagerReferenceName;
            delete editCopy.errors;
            delete editCopy.draft;
            editCopy.size = numericUtils.isNumber(editCopy.size) ? round(Number(editCopy.size) * 1000000) : editCopy.size;
            editCopy.bidSize = numericUtils.isNumber(editCopy.bidSize) ? round(Number(editCopy.bidSize) * 1000000) : undefined;

            if (!isEqualWith(item, editCopy, equalizeEmpty)) {
                return true;
            }
        }

        return false;
    }

    return (
        <RouteLeavingGuard
            navigate={(pathname: string) => history.replace(pathname)}
            shouldBlockNavigation={handleBlockNavigation}
        />
    );
}

function equalizeEmpty(a: any, b: any){
    return (a == null || a === '') && (b == null || b === '') ? true : undefined;
}
