import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { inventoryEditActions } from '../../actions';
import { adminInventoryEditActions } from '../../actions/admin-inventory-edit.actions';
import { AppState } from '../../types/state/AppState';
import { isRequesting, isRequestNone, isRequestSuccess } from '../../utils';
import { InventoryEdit } from './edit/InventoryEdit';
import { routes } from '../../constants';

export function AdminInventoryEdit() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { requestState, items, lockModifiedDate, bdCompany } = useSelector((s: AppState) => s.entities.adminInventoryEdit);
    const savingRequestState = useSelector((s: AppState) => s.inventoryEdit.savingRequestState);
    const requestParams = useParams<{ companyId?: string }>();
    const companyId = Number(requestParams.companyId);

    React.useEffect(() => {
        dispatch(adminInventoryEditActions.loadInventory(companyId));
        return () => {
            dispatch(adminInventoryEditActions.reset());
        }
    }, [companyId, dispatch]);

    React.useEffect(() => {
        if(isRequestSuccess(savingRequestState)) {
            history.replace(routes.inventory)
        }
    // eslint-disable-next-line
    }, [savingRequestState])

    const handleRefresh = () => {
        dispatch(adminInventoryEditActions.reset());
        dispatch(adminInventoryEditActions.loadInventory(companyId));
    };

    return (
        <InventoryEdit
            positions={items}
            company={bdCompany}
            isLoading={isRequesting(requestState) || isRequestNone(requestState)}
            onRefresh={handleRefresh}
            onSave={() => dispatch(inventoryEditActions.save(lockModifiedDate, companyId))}
        />
    );
}
