import React, { Component } from 'react';
import { connect } from 'react-redux';
import { manageParsedBwicsActions as actions } from '../../../actions';
import { textRulesPanelActions } from '../../../actions/text-rules-panel.actions';
import { dateTimeUtils, formatUtils, moneyUtils } from '../../../utils';
import { routes } from '../../../constants';
import { classnames } from '../../../styles/classnames';
import { BwicStatusLabel, Preloader } from '../../common';
import { Checkbox } from '../../controls/Checkbox';
import classNames from 'classnames';
import ContextMenu from '../../controls/ContextMenu';
import { ListBuilder } from '../../../utils/ListBuilder';
import { withRouter } from "react-router";
import { controlPanelActions } from '../../../actions/control-panel.actions';
import { SameDayBwicIcon } from '../../common/SameDayBwicIcon';
import { BwicProcessType } from '../../../types/models/Process';
import { BwicRulesPseudoLink } from '../../common/BwicRulesPseudoLink';

class BWIC extends Component {
    handleBwicDelete = (referenceName) => {
        this.props.dispatch(actions.deleteBwicDialog(true, referenceName));
    };

    handleUnpublish = (bwic) => {
        this.props.dispatch(actions.unpublishDialog(true, bwic));
    };

    handlePublish = (bwic) => {
        this.props.dispatch(actions.publishDialog(true, bwic));
    };

    editPxTalk = (parsed, referenceName, position) => {
        this.props.dispatch(actions.editPxTalk(true, referenceName, position))
    };

    editColor = (parsed, referenceName, position) => {
        if (parsed) {
            this.props.dispatch(actions.editColor(true, referenceName, position))
        }
    };

    handleChangeAmrFlag = (e) => {
        e.stopPropagation();
        const { checked } = e.target;
        const { dispatch, source } = this.props;
        dispatch(actions.changeAmrFlag(source.referenceName, checked))
    };

    toogleRulesSidePanel = (source, e) => {
        e.stopPropagation();

        if(this.props.textRulesPanelVisible && this.props.bwicReferenceName === source.referenceName) {
            this.props.dispatch(textRulesPanelActions.hide());
            return;
        }
        
        this.props.dispatch(controlPanelActions.hide());
        this.props.dispatch(textRulesPanelActions.show(
            source.rulesText,
            source.goodUntilUtc || source.goodUntilDateUtc,
            source.bidsDueUtc || source.bidsDueDateUtc,
            source.process,
            source.parsedProcess,
            source.isParsed,
            source.isSameDayBwic,
            source.daysToSettle,
            source.referenceName
        ));
    }

    renderOptions = (source) => {
        const { positions = [] } = source;
        // TODO rewrite to ability multiple pxtalk/color edit when modal done
        const isPxTalkExistInSomeSecurity = positions
            .some(position => Array.isArray(position.pxTalks) && position.pxTalks.length > 0);
        const colorsOfBwicSecurities = positions
            .map(position => position.color)
            .filter(color => color);

        return new ListBuilder()
            .addWhen(() => source.isParsed, {
                text: 'Edit',
                action: () => this.props.history.push(routes.editParsedBWICUrl(source.referenceName))
            })
            .add({
                sectionBlock: true,
                text: `${isPxTalkExistInSomeSecurity ? 'Update' : 'Add'} Px Talk`,
                action: () => this.editPxTalk(source.isParsed, source.referenceName, source.positions[0])
            })
            .addWhen(() => source.isParsed, {
                text: `${colorsOfBwicSecurities.length > 0 ? 'Update' : 'Add'} Color`,
                action: () => this.editColor(source.isParsed, source.referenceName, source.positions[0])
            })
            .addWhen(() => !source.onReview && source.isParsed, {
                sectionBlock: true,
                text: 'Unpublish',
                action: () => this.handleUnpublish(source)
            })
            .addWhen(() => source.onReview && source.isParsed, {
                sectionBlock: true,
                text: 'Publish',
                action: () => this.handlePublish(source)
            })
            .addWhen(() => source.onReview && source.isParsed, {
                classNames: 'warning',
                text: 'Delete',
                action: () => this.handleBwicDelete(source.referenceName)
            })
            .result();
    }

    render = () => {
        const { source = {}, dispatch, editPxTalk, editColor, requestStatusSetIsAmrFlag } = this.props;
        const positions = source.positions && source.positions.map(p =>
            <div
                key={p.id}
                className={classNames('data-table-item-row flex-row', {
                    active: (editPxTalk && p.id === editPxTalk.positionId) || (editColor && p.id === editColor.positionId)
                })}
            >
                <div className="flex-none data-table-item-check">
                    <Checkbox
                        checked={!!p.selected}
                        onChange={() => dispatch(actions.securitySelectionChange(source.referenceName, p.id, !p.selected))}
                    />
                </div>
                <div className="flex-none data-table-item-ticker">{p.ticker}</div>
                <div className="flex-none data-table-item-same-day"></div>
                <div className="flex-none data-table-item-isin">{p.isinCusip}</div>
                <div className="flex-none data-table-item-asset">{p.asset}</div>
                <div className="flex-none data-table-item-ccy">{p.currency}</div>
                <div className="flex-none data-table-item-rating">{p.rating}</div>
                <div className="flex-none data-table-item-rating">
                    {`${p.agencyRatings.snP || '-'}/${p.agencyRatings.fitch || '-'}`}
                </div>
                {/*<div className="flex-none data-table-item-factor">{p.factor}</div>*/}
                <div className="flex-none data-table-item-size">{moneyUtils.amountToMM(p.size)}</div>
                <div className="flex-none data-table-item-px text-right">
                    <span
                        onClick={() => this.editPxTalk(source.isParsed, source.referenceName, p)}
                        className={classnames({
                            "pseudo-link": true,
                            "is-active": editPxTalk && editPxTalk.positionId === p.id && editPxTalk.bwicReferenceName === source.referenceName
                        })}
                    >
                        {formatUtils.formatPxTalk(p.pxTalks) || 'add'}
                    </span>
                </div>
                <div className="flex-none data-table-item-color">
                    <span
                        onClick={() => this.editColor(source.isParsed, source.referenceName, p)}
                        className={classnames({
                            "pseudo-link": true,
                            "disabled": !source.isParsed,
                            "is-active": editColor && editColor.positionId === p.id && editColor.bwicReferenceName === source.referenceName
                        })}
                    >
                        {formatUtils.formatColor(p.color) || 'add'}
                    </span>
                </div>
            </div>
        );

        const collapseIconCssClasses = classnames({
            'icon-expand': true,
            'is-open': !source.collapsed
        });

        const collapseHeaderCssClasses = classnames({
            'data-item-collapsable-header': true,
            'expand': !source.collapsed
        });

        return (
            <div className="data-table-item">
                <div
                    className={collapseHeaderCssClasses}
                    onClick={() => dispatch(actions.setBwicCollapsedState(source.referenceName, !source.collapsed))}
                >
                    <i className={collapseIconCssClasses} />
                    <BwicStatusLabel
                        status={source.status}
                        liveBidding={source.process?.type === BwicProcessType.Live}
                        isParsed={source.isParsed}
                        directBidding={source.isClearingBankParticipant}
                        isAllToAll={source.isAllToAll}
                    />
                    <span className="same-day">
                        {source.isSameDayBwic && <SameDayBwicIcon />}
                    </span>
                    <span className="bwic-date">
                        {source.bidsDue}
                    </span>
                    <span className="bwic-title text-ellipsis">
                        {source.name}
                    </span>
                    <div className="bwic-rules-link">
                        <BwicRulesPseudoLink 
                            isParsed={source.isParsed}
                            process={source.process}
                            parsedProcess={source.parsedProcess}
                            onClick={(e) => this.toogleRulesSidePanel(source, e)}
                        /> 
                    </div>
                    <div className="bwic-unpublished">{source.onReview && <>Unpublished</>}</div>
                    <div className="bwic-created">
                        {source.createdAtUtc && <>Created {dateTimeUtils.utcToLocalString(source.createdAtUtc)}</>}
                    </div>
                    <div className="bwic-updated">
                        {source.lastUpdateAt && <>Updated {source.lastUpdateAt}</>}
                    </div>
                    <div className="flex-item-right flex-row">
                        <div className="amr-label" onClick={ (e)=> e.stopPropagation() }>
                            <Preloader fullScreen={false} small={true} inProgress={requestStatusSetIsAmrFlag[source.referenceName]}>
                                <Checkbox
                                    onChange={this.handleChangeAmrFlag}
                                    checked={source.isAMR}
                                    label="AMR"
                                />
                            </Preloader>
                        </div>
                        <ContextMenu
                            menuItems={this.renderOptions(source)}
                        />
                    </div>
                </div>

                {
                    !source.collapsed &&
                    source.positions &&
                    !!source.positions.length &&
                    <div className="data-item-collapsable-content">
                        <div className="data-item-collapsable-content-rows">
                            {positions}
                        </div>
                    </div>
                }
                {
                    !source.collapsed &&
                    (!source.positions || !source.positions.length) &&
                    <div className="data-item-collapsable-content data-item-empty-bwic">
                        <i className="icon icon-warning yellow" />
                        This BWIC does not contain any securities
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ manageParsedBwics, textRulesPanel }) => ({
    editPxTalk: manageParsedBwics.editPxTalk,
    editColor: manageParsedBwics.editColor,
    requestStatusSetIsAmrFlag: manageParsedBwics.requestStatusSetIsAmrFlag,
    textRulesPanelVisible: textRulesPanel.visible,
    bwicReferenceName: textRulesPanel.params?.bwicReferenceName
});

const connectedBWIC = withRouter(connect(mapStateToProps)(BWIC));
export { connectedBWIC as BWIC };
