import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { keys } from 'lodash';
import moment from 'moment/moment';
import { useHistory } from 'react-router';
import { routes } from '../../../constants';
import { HelpResourceArticle } from '../../../types/help-resources/HelpResourceArticle';
import { HelpResourceSection } from '../../../types/help-resources/HelpResourceCategory';
import { MarketTrendsMonthFilter } from './MarketTrendsMonthFilter';
import { MarketTrendsSection } from './MarketTrendsSection';
import { MarketTrendsYearFilter } from './MarketTrendsYearFilter';
import { useMarkedTrendsRoutingFilter } from './useMarkedTrendsRoutingFilter';

const monthsList = moment.months();

const groupMarkedTrendsSections = (sections: HelpResourceSection[]): MarketTrendsStructure => {
    const structure: MarketTrendsStructure = {};
    sections.forEach(section => {
        const [month, yearString] = section.name.split(' ');
        const year = Number(yearString);

        if (!structure[year]) {
            structure[year] = [];
        }

        if (monthsList.indexOf(month) !== -1 && structure[year].findIndex(i => i.month === month) === -1) {
            structure[year].push({ month });
            structure[year].sort((a, b) => monthsList.indexOf(b.month) - monthsList.indexOf(a.month));
        }
    })
    return structure;
};

type MarketTrendsStructure = { [year: number]: { month: string }[] };

interface MarketTrendsWebinarsContentProps {
    sections: HelpResourceSection[];
    articles: HelpResourceArticle[];
    mainDiv: React.MutableRefObject<HTMLDivElement | null>
}

export function MarketTrendsWebinarsContent({ sections, articles, mainDiv }: MarketTrendsWebinarsContentProps) {
    const initialized = useRef(false);
    const history = useHistory();
    const { selectedYear } = useMarkedTrendsRoutingFilter();

    const structure = useMemo(() => groupMarkedTrendsSections(sections), [sections]);
    const yearList = useMemo(() => keys(structure).map(year => Number(year)).sort().reverse(), [structure]);

    const yearStructure = structure[selectedYear];

    useEffect(() => {
        if (yearList[0] && structure && !initialized.current) {
            const firstMonth = structure[selectedYear || yearList[0]]?.[0].month;
            history.replace(`${routes.resourcesWebinarUrl(selectedYear || yearList[0])}${firstMonth ? '#' + firstMonth : ''}`);
            initialized.current = true;
        }
    }, [selectedYear, yearList, history, structure])

    const onContentScroll = useCallback(() => {
        const month = history.location.hash.replace('#', '')
        if (month && selectedYear) {
            history.replace(routes.resourcesWebinarUrl(selectedYear))
        }
    }, [history, selectedYear])

    useEffect(() => {
        const mainDivCurrent = mainDiv.current;
        mainDivCurrent?.addEventListener('wheel', onContentScroll)
        return () => {
            mainDivCurrent?.removeEventListener('wheel', onContentScroll)
        }
    }, [mainDiv, onContentScroll])

    const handleYearChanged = (year: number) => {
        const yearStructure = structure[year];
        const firstMonth = yearStructure[0].month;
        history.push(`${routes.resourcesWebinarUrl(year)}${firstMonth ? '#' + firstMonth : ''}`);
        mainDiv.current?.scrollTo(0, 0)
    }

    return (
        <>
            <MarketTrendsYearFilter years={yearList} onSelectedYearChange={handleYearChanged} />
            <MarketTrendsMonthFilter months={yearStructure} />
            <div className="webinars-list webinar-cnt-center">
                {
                    sections.filter(s => s.name.indexOf(String(selectedYear)) > -1).map(s =>
                        <MarketTrendsSection
                            key={s.id}
                            section={s}
                            articles={articles.filter(a => a.section_id === s.id)}
                        />
                    )
                }
            </div>
        </>
    )
}
