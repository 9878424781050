import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryEditActions } from '../../../actions';
import { bdInventoryActions } from '../../../actions/bd-inventory.actions';
import { AppState } from '../../../types/state/AppState';
import { isRequesting, isRequestNone, isRequestSuccess } from '../../../utils';
import { InventoryEdit } from '../edit/InventoryEdit';
import { useCurrentUserCompany } from '../../../effects/useCurrentUserCompany';
import { useHistory } from 'react-router';
import { routes } from '../../../constants';

export function BdInventoryEdit() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [, userCompany] = useCurrentUserCompany();
    const { requestState, items, lockModifiedDate } = useSelector((s: AppState) => s.entities.inventory);
    const savingRequestState = useSelector((s: AppState) => s.inventoryEdit.savingRequestState);

    const [currentLockModifiedDate, setCurrentLockModifiedDate] = React.useState<Date>();
    const [mounted, setMounted] = useState(false);

    const handleRefresh = React.useCallback(() => {
        dispatch(bdInventoryActions.resetEntities());
        dispatch(bdInventoryActions.loadBdInventory());
    }, [dispatch]);

    React.useEffect(() => {
        handleRefresh();
        setMounted(true);
    }, [dispatch, handleRefresh]);

    React.useEffect(() => {
        if (!currentLockModifiedDate && isRequestSuccess(requestState) && lockModifiedDate) {
            setCurrentLockModifiedDate(lockModifiedDate);
        }
    }, [requestState, currentLockModifiedDate, lockModifiedDate]);

    React.useEffect(() => {
        if(isRequestSuccess(savingRequestState)) {
            history.replace(routes.inventory)
        }
    // eslint-disable-next-line
    }, [savingRequestState])

    if (!mounted || isRequestNone(requestState)) {
        return null;
    }

    return (
        <InventoryEdit
            positions={items}
            company={userCompany}
            isLoading={isRequesting(requestState)}
            onRefresh={handleRefresh}
            onSave={() => dispatch(inventoryEditActions.save(currentLockModifiedDate))}
        />
    );
}
