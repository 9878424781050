import React from 'react';
import { Link } from 'react-router-dom';
import { Support, IntroCarousel } from '../common';
import { Footer } from "./Footer";

export const SignPagesLayout = props => {

    return (
        <>
            <main className="sign-page">
                <div className="intro-carousel">
                    <div className="intro-carousel-cnt">
                        <h1>Electronic Trading Platform</h1>
                        <IntroCarousel />
                    </div>
                </div>
                <div className="sign-page-form">
                    <header>
                        <nav className="navbar">
                            <Link to="/" className="nav-brand">
                                 <i className="icon icon-logo-bwic"></i>
                            </Link>

                            <div className="navbar-item-right navbar-item-anonymous-state">
                                <Support />
                            </div>
                        </nav>
                    </header>
                    <div className="container-flex">
                        <>
                            {props.children}
                        </>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
}
