import * as React from 'react';

interface Props {
    title: string,
    children: React.ReactNode
}

export function EditMemberSection({ title, children }: Props) {
    return (
        <div className="box">
            <h2>{title}</h2>
            {children}
        </div>
    );
}
