import * as React from 'react';
import { values } from 'lodash';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { InventoryPositionStatus } from '../../types/inventory/InventoryPositionStatus';
import { OnHoverTooltip, Relative } from '../common';
import { Preloader } from '../common/Preloader';
import { TradedConfirmPopup } from './TradedConfirmPopup';
import { InventoryPosition } from '../../types/inventory/InventoryPosition';

interface Props {
    position: InventoryPosition;
    isTradedChangeInProgress: { [key: number]: boolean };
    onTradedChange: (tradeDate: Date) => void
}

export function TradedButton({ position, onTradedChange, isTradedChangeInProgress }: Props) {
    const [tradedConfirmationPopupVisible, setTradedConfirmationPopupVisible] = React.useState(false);
    const isBlocked = React.useContext(BlockedFeatureContext);

    if (isBlocked) {
        return <button className="btn btn-ghost btn-sm" disabled>Traded</button>;
    }

    return (
        <>
            <Relative inline>
                <Preloader inProgress={isTradedChangeInProgress[position.id]} small={true}>
                    <OnHoverTooltip
                        overlay="Security is Sold. Open the Edit List to update the Inventory list"
                        disabled={position.status !== InventoryPositionStatus.traded}
                    >
                        <button
                            className="btn btn-ghost btn-sm"
                            onClick={() => setTradedConfirmationPopupVisible(true)}
                            disabled={position.status === InventoryPositionStatus.traded || values(isTradedChangeInProgress).some(p => p)}
                        >
                            Traded
                        </button>
                    </OnHoverTooltip>
                </Preloader>
            </Relative>
            {tradedConfirmationPopupVisible && <TradedConfirmPopup ticker={position.ticker} onTradedChange={onTradedChange} onClose={() => setTradedConfirmationPopupVisible(false)} />}
        </>
    );
}
