import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Filter, BWICList } from '.';
import { manageParsedBwicsActions as actions } from '../../../actions';
import { ChangePxTalk, ChangeColor, Preloader } from '../../common';
import { SecuritiesSearchInput } from '../../filters';
import { BwicTextRulesPanel } from '../../bwic-rules/BwicTextRulesPanel';
import { history } from '../../../history';


class ManageBWICs extends Component {
    componentDidMount = () => this.props.dispatch(actions.init(history.location.search));
    componentWillUnmount = () => this.props.dispatch(actions.reset());

    handleClickNew = () => this.props.dispatch(actions.showNewBwic());

    render = () => {
        const { searchInProgress, isLoading, dispatch, isNextPageRequesting } = this.props;

        return (
            <div className="container">
                <div className="sub-header">
                    <div className="sub-header-row type01 flex-row">
                        <h1>Manage Parsed BWICs</h1>
                        <SecuritiesSearchInput
                            onSearch={() => this.props.dispatch(actions.search())}
                            disabled={isLoading || searchInProgress || isNextPageRequesting}
                        />
                        <div className="flex-item-right controls">
                            <button
                                disabled={isLoading || searchInProgress || isNextPageRequesting}
                                className="btn btn-ghost"
                                onClick={this.handleClickNew}
                            >
                                New BWIC
                            </button>
                        </div>
                    </div>
                    <div className="sub-header-row type02 flex-row">
                        <Filter />
                    </div>
                </div>
                <div className="container-flex container-manage-bwics position-relative">
                    <div className="container-flex flex-row flex-row-full-size">
                        <Preloader inProgress={isLoading || searchInProgress}>
                            <BWICList />
                        </Preloader>
                        <BwicTextRulesPanel />
                    </div>
                </div>
                <ChangePxTalk onSave={() => dispatch(actions.savePxTalk())} />
                <ChangeColor
                    onSave={() => dispatch(actions.saveColor())}
                    onDelete={() => dispatch(actions.deleteColor())}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ manageParsedBwics }) => ({
    isNextPageRequesting: manageParsedBwics.isNextPageRequesting,
    searchInProgress: manageParsedBwics.searchInProgress,
    isLoading: manageParsedBwics.isLoading,
    bwics: manageParsedBwics.bwics
});

const connectedManageBWICs = connect(mapStateToProps)(ManageBWICs);
export { connectedManageBWICs as ManageBWICs };

