import {
    NewsCategory,
    newsCategoryTitles,
} from "../../../types/news/enums/NewsCategory";
import {
    NewsLabel,
    newsLabelTitles,
} from "../../../types/news/enums/NewsLabel";
import {
    NewsProvider,
    newsProviderTitles,
} from "../../../types/news/enums/NewsProvider";

export const constants = {
    maxSearchLengthToIgnore: 2,
    newsLoadCount: 20,
};

export const defaultNews = {
    title: "",
    category: NewsCategory.None,
    label: NewsLabel.None,
    description: null,
    newsDateTime: new Date(),
};

export const newsLabelValues = Object.keys(newsLabelTitles).map((key) => ({
    id: key,
    label: newsLabelTitles[key as NewsLabel],
}));

export const newsCategoryValues = Object.keys(newsCategoryTitles).map(
    (key) => ({
        id: key,
        label: newsCategoryTitles[key as NewsCategory],
    })
);

export const newsProviderValues = Object.keys(newsProviderTitles).map(
    (key) => ({
        id: key,
        label: newsProviderTitles[key as NewsProvider],
    })
);
