import classNames from 'classnames';
import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { DirectBiddingDisclaimerContext } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContext';
import { NavBarSubItem, NavBarSubItemProps } from './NavBarSubItem';

interface NavBarSubDropDownItemProps {
    onClick: (e: SyntheticEvent) => void;
    items: NavBarSubItemProps[];
    title: React.ReactNode;
    disabled?: boolean;
    className?: string;
}

export const NavBarSubDropDownItem: React.FC<NavBarSubDropDownItemProps> = ({ items, title, onClick, disabled, className }) => {
    const history = useHistory();
    const [showSubItems, setShowSubItems] = useState(false);
    const isActive = items.some(i => history.location.pathname === i.path);
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const isDirectBiddingDisclaimerBlocked = React.useContext(DirectBiddingDisclaimerContext);
    const isBlocked = isFeatureBlocked || isDirectBiddingDisclaimerBlocked;
    const itemClassNames = classNames({
        "navbar-dropdown-menu-item position-relative": true,
        isActive,
        disabled
    }, className);

    if (isBlocked || disabled) {
        return <span className={itemClassNames}>{title}</span>
    }

    return (
        <div
            className={itemClassNames}
            onMouseEnter={() => setShowSubItems(true)}
            onMouseLeave={() => setShowSubItems(false)}
        >
            {title}
            <i className="icon icon-expand flex-item-right margin-r-0" />
            {
                showSubItems &&
                <div className="navbar-dropdown-menu navbar-dropdown-my">
                    {
                        items.map(i => (
                            <NavBarSubItem
                                key={i.path}
                                onClick={onClick}
                                path={i.path}
                                title={i.title}
                            />
                        ))
                    }
                </div>
            }
        </div>
    )
};
