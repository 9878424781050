import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editParsedBwicActions as actions } from '../../../../actions';


class TimeZone extends Component {
    render = () => {
        const { selectedTimeZone, timeZones } = this.props;
        const options = timeZones && timeZones.map(tz => <option key={tz.id} value={tz.id}>{tz.title}</option>)

        return (
            <div className="form-item time-zone">
                <label className="form-label">Time Zone</label>
                <select className="form-control form-select" value={selectedTimeZone} onChange={this.handleTimeZoneChanged}>
                    {options}
                </select>
            </div>
        );
    }

    handleTimeZoneChanged = e => this.props.dispatch(actions.changeTimeZone(e.target.value));
}

const mapStateToProps = ({ editParsedBwic }) => ({
    selectedTimeZone: editParsedBwic.timeZone,
    timeZones: editParsedBwic.timeZones
});


const connectedTimeZone = connect(mapStateToProps)(TimeZone);
export { connectedTimeZone as TimeZone };
