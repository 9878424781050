import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Popup } from '../../controls';
import { companiesActions as actions } from '../../../actions';
import { companyStatuses, errorMessages } from '../../../constants';

export function ConfirmationPopup({ statusChangeConfirmation }) {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const messageMaxLength = 255;
    const { company } = statusChangeConfirmation;
    const isBlockStatus = (
        company.status === companyStatuses.active.statusCode ||
        company.status === companyStatuses.offPlatform.statusCode
    );

    const handleChange = (e) => {
        const { value } = e.target;
        if (value.length > messageMaxLength) {
            setMessageError(errorMessages.maxLength(messageMaxLength))
        } else {
            setMessageError('')
        }
        setMessage(value);
    };

    const renderFooter = () => {
        const disabledBlockButton = !message.length || messageError;
        return (
            <div className="modal-footer">
                <button
                    className="btn btn-ghost"
                    onClick={() => dispatch(actions.companyStatusChangeConfirmation(false))}
                >
                    Cancel
                </button>
                {
                    isBlockStatus ?
                        <button
                            className="btn btn-main btn-danger"
                            disabled={disabledBlockButton}
                            onClick={() => dispatch(actions.changeCompanyStatus(company, message))}
                        >
                            Block
                        </button> :
                        <button
                            className="btn btn-main btn-danger"
                            onClick={() => dispatch(actions.changeCompanyStatus(company))}
                        >
                            Yes
                        </button>
                }
            </div>
        )
    };

    const renderContent = () => {
        return (
            <div className="modal-body">
                {isBlockStatus ?
                    <div className="form-row">
                        <label className="form-label">Description <span className="text-red">*</span></label>
                        <div>
                            <textarea
                                className={classNames("form-control", { "grey": !message.length })}
                                maxLength={messageMaxLength + 1}
                                value={message}
                                placeholder="Enter the reason for blocking here"
                                onChange={handleChange}
                            />
                            {messageError && <div className="form-error">{messageError}</div>}
                        </div>
                    </div> :
                    <p className="status-message alert">
                        <i className="icon icon-warning" />
                        <span className="status-message-cnt">{`Do you really want to unblock  ${company.name}?`}</span>
                    </p>
                }
            </div>
        )
    };

    return (
        <Popup
            title={isBlockStatus ? 'Blocked Reason' : 'Confirmation'}
            modalClass="modal-delete"
            onClose={() => dispatch(actions.companyStatusChangeConfirmation(false))}
        >
            {renderContent()}
            {renderFooter()}
        </Popup>
    )
}
