
import { Webinar } from '../../../types/marketing/Webinar';
import { ImageLoader } from '../../common/ImageLoader';

interface Props {
    webinar: Webinar;
}

export function WebinarContent({ webinar }: Props) {
    return (
        <>
            <div className="vertical-divider vertical-divider-light" />
            <div className="webinar-header-reg">
                <h1>{webinar.title}</h1>
                <p>{webinar.description}</p>
                {webinar.speakerName && (
                    <div className="speaker flex-row">
                        {webinar.speakerPhotoReferenceName && (
                            <div className="speaker-userpic position-relative">
                                <ImageLoader
                                    path={`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/webinar-image-speaker/v2/${webinar.speakerPhotoReferenceName}`}
                                />
                            </div>
                        )}
                        <div className="speaker-ditails">
                            <div className="speaker-label">Speaker:</div>
                            <h2 className="speaker-name">
                                {webinar.speakerName}{webinar.speakerTitle && (<>,<br/> {webinar.speakerTitle}</>)}
                            </h2>
                        </div>
                    </div>
                )}
                <a className="btn btn-sm btn-main" href={webinar.link} target="_blank" rel="noreferrer">
                    {webinar.linkName}
                </a>
            </div>
        </>
    );
}
