import React, { useState } from 'react';
import { roles, routes } from '../../constants';
import { ClickOutside } from '../common/ClickOutside';
import { NavBarSubItem } from './NavBarSubItem';
import { matchPath, useHistory, useParams } from 'react-router-dom';
import { ShowFor } from '../access';
import { user } from '../../user';
import { PendingUserRequestCountBadge } from './PendingUserRequestCountBadge';

export const ManageMenuItem: React.FC = () => {
    const history = useHistory();
    const params = useParams<{ referenceName?: string }>();
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const isActive = () => {
        const pathname = history.location.pathname;
        const referenceName = params.referenceName;
        const isActive =
            pathname === routes.manageCompanies ||
            pathname === routes.manageCompanyMembers ||
            pathname === routes.manageParsedBwics ||
            pathname === routes.newParsedBWIC ||
            pathname === routes.manageImportSecurities ||
            pathname === routes.addCompany ||
            pathname === routes.manageCompaniesCreateUser ||
            pathname === routes.manageEmails ||
            pathname === routes.manageTokens ||
            pathname === routes.manageClearRedisState ||
            matchPath(pathname, {
                path: routes.manageBank,
                exact: true,
                strict: false
            }) ||
            matchPath(pathname, {
                path: routes.manageNews,
                exact: true,
                strict: false
            }) ||
            matchPath(pathname, {
                path: routes.manageCloManager,
                exact: true,
                strict: false
            }) ||
            matchPath(pathname, {
                path: routes.manageCompaniesEditUser,
                exact: true,
                strict: false
            }) ||
            (referenceName && pathname === routes.editParsedBWICUrl(referenceName));
        return (
            isActive &&
                pathname !== routes.editPortfolioUrl(referenceName)
                ? 'active'
                : ''
        );
    }

    const pendingUserRequestCount = <PendingUserRequestCountBadge />;

    return (
        <li className={`navbar-item dropdown dropdown-with-badge ${expanded ? 'show' : ''}`}>
            <button
                type="button"
                className={`btn-link navbar-link dropdown-toggle ${isActive()}`}
                onClick={toggleExpanded}
            >
                Manage
                {user.hasRoles(roles.Administrator) && pendingUserRequestCount}
            </button>
            <div className={`dropdown-menu navbar-dropdown-menu navbar-dropdown-manage ${expanded ? 'show' : ''}`}>
                {expanded && (
                    <ClickOutside onClick={() => setExpanded(false)}>
                        <>
                            <ShowFor role={roles.Administrator}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.manageCompanies}
                                    title={(
                                        <>
                                            Companies &amp; Users
                                            {user.hasRoles(roles.Administrator) && pendingUserRequestCount}
                                        </>
                                    )}
                                />
                            </ShowFor>
                            <NavBarSubItem
                                onClick={toggleExpanded}
                                path={routes.manageParsedBwics}
                                title="Parsed BWICs"
                            />
                            <NavBarSubItem
                                onClick={toggleExpanded}
                                path={routes.manageImportSecurities}
                                title="Securities"
                            />
                            <NavBarSubItem onClick={toggleExpanded} path={routes.manageEmails} title="Emails" />
                            <ShowFor role={roles.Administrator}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.manageClearRedisState}
                                    title="Storage"
                                />
                            </ShowFor>
                            <ShowFor role={roles.Administrator}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.manageTokens}
                                    title="API Access Tokens"
                                />
                            </ShowFor>
                            <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.manageBanks}
                                    title="Banks"
                                />
                            </ShowFor>
                            <NavBarSubItem
                                onClick={toggleExpanded}
                                path={routes.manageCloManagers}
                                title="CLO Managers"
                            />
                            <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.manageNewsPage}
                                    title="KopenTech News"
                                />
                            </ShowFor>
                            <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.dealsCommon}
                                    title="Deals"
                                />
                            </ShowFor>
                        </>
                    </ClickOutside>
                )}
            </div>
        </li>
    )
};
