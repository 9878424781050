import { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { Nullable } from "../../../../types/primitives/Nullable";
import { FormError } from '../../../controls/FormError';
import TimePickerControl from '../../../controls/TimePickerControl';
import { RadioButtonGroup } from '../../../controls/RadioButtonGroup';
import { editParsedBwicActions } from '../../../../actions/edit.parsed.bwic.actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BwicProcessType } from '../../../../types/models/Process';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { HardCutOff, Stage2Participants } from '../../../../types/models/ParsedBwicProcess';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { numericUtils } from '../../../../utils/numeric.utils';

export function EditParsedBwicRules() {
    const dispatch = useAppDispatch();
    const { reserveLevelsApply, canTradeEarly, canBidEarly, partialBidsAccepted, bwicProcessType, hardCutOff,
        stage1EndDateUtc, stage2EndDateUtc, errors } = useAppSelector(s => s.editParsedBwic);

    const advancedRulesVisible =
        bwicProcessType === BwicProcessType.JumpBall ||
        bwicProcessType === BwicProcessType.TopX ||
        bwicProcessType === BwicProcessType.BestFootForward ||
        bwicProcessType === BwicProcessType.Unknown;

    const isTwoStage =
        bwicProcessType === BwicProcessType.JumpBall ||
        bwicProcessType === BwicProcessType.TopX ||
        bwicProcessType === BwicProcessType.Unknown;

    return (
        <>
            <div className="form-row form-row-inline">
                <BooleanFlag
                    title="Reserve levels Apply"
                    value={reserveLevelsApply}
                    onChange={value => dispatch(editParsedBwicActions.changeReserveLevelsApply(value))}
                />
                <BooleanFlag
                    title="Can Trade Early"
                    value={canTradeEarly}
                    onChange={value => dispatch(editParsedBwicActions.changeCanTradeEarly(value))}
                />
                <BooleanFlag
                    title="Can Bid Early"
                    value={canBidEarly}
                    onChange={value => dispatch(editParsedBwicActions.changeCanBidEarly(value))}
                />
                <BooleanFlag
                    title="Partial Bids Accepted"
                    value={partialBidsAccepted}
                    onChange={value => dispatch(editParsedBwicActions.changePartialBidsAccepted(value))}
                />
                <BidsGoodFor />
            </div>
            <div className="form-row form-row-inline">
                <RulesText />
                <BwicProcessTypeSelect processType={bwicProcessType} />

                {advancedRulesVisible && isTwoStage && <HardCutOffNumber hardCutOff={hardCutOff} />}
                {
                    advancedRulesVisible &&
                    (bwicProcessType === BwicProcessType.BestFootForward || hardCutOff === 1) &&
                    <>
                        <HardCutOffTime
                            className="rc-time-picker-normal rc-time-picker-normal-140"
                            title="Hard Cut Off (EST)"
                            date={stage1EndDateUtc}
                            error={errors?.stage1EndDateUtc}
                            onChange={time => dispatch(editParsedBwicActions.changeStage1EndTime(time))}
                        />
                        {bwicProcessType !== BwicProcessType.BestFootForward && <ImproverNumberSelect />}
                    </>
                }
            </div>
            {
                advancedRulesVisible && hardCutOff === 2 &&
                <div className="form-row form-row-inline two-stage-hard-cut-off">
                    <HardCutOffTime
                        className="rc-time-picker-normal rc-time-picker-normal-186"
                        title="1st Stage Hard Cut Off (EST)"
                        date={stage1EndDateUtc}
                        error={errors?.stage1EndDateUtc}
                        onChange={time => dispatch(editParsedBwicActions.changeStage1EndTime(time))}
                    />
                    <HardCutOffTime
                        className="rc-time-picker-normal rc-time-picker-normal-186"
                        title="2nd Stage Hard Cut Off (EST)"
                        date={stage2EndDateUtc}
                        error={errors?.stage2EndDateUtc}
                        onChange={time => dispatch(editParsedBwicActions.changeStage2EndTime(time))}
                    />
                    <ImproverNumberSelect />
                </div>
            }
        </>
    );
}

interface BooleanFlagProps {
    title: string;
    value?: Nullable<boolean>;
    onChange: (value: Nullable<boolean>) => void;
}

function BooleanFlag({ title, value, onChange }: BooleanFlagProps) {
    const name = useRef(uuid());

    return (
        <div className="form-item">
            <label className="form-label">{title}</label>
            <RadioButtonGroup
                className="radio-group-row"
                name={name.current}
                value={value ?? 0}
                options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                    { value: 0, label: "N/A" },
                ]}
                onChange={(value: boolean | 0) => onChange(value === 0 ? null : value)}
            />
        </div>
    );
}

function RulesText() {
    const dispatch = useDispatch();
    const rulesText = useAppSelector(s => s.editParsedBwic.rulesText);

    return (
        <div className="form-item notes">
            <label className="form-label" htmlFor="customRulesTextarea">Notes(optional)</label>
            <textarea
                id="customRulesTextarea"
                placeholder='Enter Notes'
                className="form-control"
                value={rulesText || ''}
                maxLength={512}
                cols={60}
                rows={1}
                onChange={e => dispatch(editParsedBwicActions.changeCustomRules(e.target.value))}
            />
        </div>
    );
}

interface BwicProcessTypeSelectProps {
    processType?: BwicProcessType;
}
function BwicProcessTypeSelect({ processType }: BwicProcessTypeSelectProps) {
    const dispatch = useAppDispatch();

    return (
        <div className="form-item bidding-process">
            <label className="form-label">Bidding Process</label>
            <select
                className="form-control form-select"
                value={processType ?? undefined}
                onChange={e => dispatch(editParsedBwicActions.changeBwicProcessType(+e.target.value))}
            >
                <option>Select</option>
                {[
                    { value: BwicProcessType.Standard, text: "Standard" },
                    { value: BwicProcessType.JumpBall, text: "Jump Ball" },
                    { value: BwicProcessType.TopX, text: "Top X" },
                    { value: BwicProcessType.BestFootForward, text: "Best Foot Forward" },
                    { value: BwicProcessType.Unknown, text: "Other" }
                ].map(x => <option key={x.value} value={x.value}>{x.text ?? x.value}</option>)}
            </select>
        </div>
    )
}

interface HardCutOffNumberProps {
    hardCutOff?: HardCutOff;
}
function HardCutOffNumber({ hardCutOff }: HardCutOffNumberProps) {
    const dispatch = useAppDispatch();

    return (
        <div className="form-item hard-cut-off">
            <label className="form-label"># of Hard Cut Off</label>
            <RadioButtonGroup
                className="radio-group-row"
                name="hardCutOff"
                value={hardCutOff}
                options={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" }
                ]}
                onChange={(value: HardCutOff) => dispatch(editParsedBwicActions.changeHardCutOffNumber(value))}
            />
        </div>
    );
}

function ImproverNumberSelect() {
    const dispatch = useDispatch()
    const improverCount = useAppSelector(s => s.editParsedBwic.improverCount)

    return (
        <div className="form-item stage-2-participants">
            <label className="form-label"># of Stage 2 Participants</label>
            <select
                className="form-control form-select"
                value={improverCount || 0}
                onChange={e => dispatch(editParsedBwicActions.changeStage2Participants(+e.target.value || null))}
            >
                <option value={0}>Select</option>
                {[
                    { value: Stage2Participants.Top2, text: "Top 2" },
                    { value: Stage2Participants.Top3, text: "Top 3" },
                    { value: Stage2Participants.Top4, text: "Top 4" },
                    { value: Stage2Participants.Top5, text: "Top 5" }
                ].map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
            </select>
        </div>
    )
}

interface HardCutOffTimeProps {
    title: string;
    className?: string;
    date?: Date;
    error?: string;
    onChange: (date: Date) => void;
}

function HardCutOffTime({ title, date, className, error, onChange }: HardCutOffTimeProps) {
    return (
        <div className="form-item hard-cut-off-est">
            <label className="form-label">{title}</label>
            <TimePickerControl
                time={date}
                emptyEnabled={true}
                className={cn(className, { 'is-invalid': !!error })}
                placeholder="Select"
                onTimeChange={onChange}
            />
            <FormError message={error} />
        </div>
    );
}

function BidsGoodFor() {
    const dispatch = useAppDispatch();
    const bidsGoodForHours = useAppSelector(s => s.editParsedBwic.bidsGoodForHours) ?? "";
    const bidsGoodForMinutes = useAppSelector(s => s.editParsedBwic.bidsGoodForMinutes) ?? "";
    const error = useAppSelector(s => s.editParsedBwic.errors?.bidsGoodFor);

    const limitHours = 12;
    const limitMinutes = 59;


    const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(numericUtils.isNumber(e.target.value) && +e.target.value > limitMinutes){
            return dispatch(editParsedBwicActions.changeBidsGoodForMinutes(bidsGoodForMinutes));
        }

        dispatch(editParsedBwicActions.changeBidsGoodForMinutes(e.target.value))
    }

    return (
        <div className="form-item bids-good-for">
            <label className="form-label">Bids Must Be Good for</label>
            <div className="form-row form-row-inline margin-b-0 position-relative">
                <div className="form-item">
                    <MaskedInput
                        className={cn(
                            "form-control",
                            { 'is-invalid': !!error }
                        )}
                        placeholder="Hours"
                        mask={createNumberMask({ prefix: '', allowLeadingZeroes: true, includeThousandsSeparator: false, integerLimit: limitHours })}
                        maxLength={2}
                        value={bidsGoodForHours}
                        onChange={e => dispatch(editParsedBwicActions.changeBidsGoodForHours(e.target.value))}
                    />
                </div>
                <div className="form-item">
                    <MaskedInput
                        className={cn(
                            "form-control",
                            { 'is-invalid': !!error }
                        )}
                        placeholder="Minutes"
                        mask={createNumberMask({ prefix: '', includeThousandsSeparator: false, allowLeadingZeroes: true, integerLimit: limitMinutes })}
                        maxLength={2}
                        value={bidsGoodForMinutes}
                        onChange={handleMinutesChange}
                    />
                </div>
                <FormError message={error} />
            </div>
        </div>
    )
}
