import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { isEmpty, isEqual, isNaN } from 'lodash';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import moment, { Moment } from 'moment';
import { Preloader, Relative } from '../../common';
import { companyRoles, constants, roles, routes, userStatuses } from '../../../constants';
import { InputField } from '../../forms';
import { numericUtils } from '../../../utils';
import { EditMemberSection } from './EditMemberSection';
import { companiesActions as actions, companiesActions } from '../../../actions';
import { MultiSelect, MultiSelectDataItem } from '../../controls/MultiSelect';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { Company } from '../../../types/management/Company';
import { User } from '../../../types/management/User';
import { yup } from '../../../validation/yup';
import { FormError } from '../../controls';
import { Checkbox } from '../../controls/Checkbox';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { UserDesk, UserDeskLabels } from '../../../types/management/UserDesk';

const deskTypes = [
    { label: "-", value: undefined },
    { label: UserDeskLabels[UserDesk.Primary], value: UserDesk.Primary },
    { label: UserDeskLabels[UserDesk.Secondary], value: UserDesk.Secondary },
];

interface FormValues {
    id: number | undefined;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    phone: string;
    companyId: number;
    roles: string[];
    primaryContact: boolean;
    linkedIn?: string;
    location?: string;
    desk?: UserDesk;
}

export const AdminEditUser: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const requestParams = useParams<{ userId?: string }>();
    const location = useLocation<{ companyId: number }>();
    const preselectedCompanyId: number = location?.state?.companyId || 0;
    const userId = numericUtils.numberOrDefault(requestParams.userId);

    const companies: Company[] = useSelector((state: AppState) => state.companies.companies);
    const initialUser: User = useSelector((state: AppState) => state.companies.editMember.initialUser);
    const isSavingUserRequest = useSelector((state: AppState) => state.companies.editMember.savingUserFlag);
    const initEditUserPageComplete = useSelector((state: AppState) => state.companies.initEditUserPageComplete);

    useEffect(() => {
        dispatch(companiesActions.initCreateEditUser(userId, preselectedCompanyId));
    }, [dispatch, userId, preselectedCompanyId]);

    useEffect(() => {
        return () => {
            dispatch(actions.resetEditMember())
        }
    }, [dispatch])

    const companiesToSelect = useMemo(() => {
        const orderedCompanies = [...companies].sort((a, b) => a.name.localeCompare(b.name));

        if (initialUser?.roles?.some(r => r === roles.CollateralManager)) {
            return orderedCompanies.filter(c => c.role === companyRoles.Seller);
        }
        if (initialUser?.roles?.some(r => r === companyRoles.Media)) {
            return orderedCompanies.filter(c => c.role === companyRoles.Media);
        }
        if (initialUser?.roles?.some(r => r === roles.Viewer)) {
            return orderedCompanies;
        }

        if (initialUser?.roles?.length) {
            return orderedCompanies.filter(c =>
                roles.getByCompanyRole(c.role).some(r =>
                    initialUser.roles.some(userRole => r === userRole))
            );
        }

        return orderedCompanies;
    }, [companies, initialUser]);

    const renderSubscriptionTitle = (planName: string, expires: Moment) => (
        <p>{planName}</p>
    );

    const getMostExpensiveText = () => (
        <p>
            The most expensive subscription plan and trial period of 30 days are set by default to the user. You can
            change them from the user options dropdown.
        </p>
    );

    const renderSubscription = (selectedRoles: string[]) => {
        const isSubscriptionExist = userId && initialUser?.subscription && initialUser?.trialPeriodEnd;
        const initialRoles = initialUser?.roles ?? [];
        const subscriptionDependentSelectedRoles = selectedRoles.filter(r => r !== roles.ArrangersClient);
        const isUserRoleChanged = subscriptionDependentSelectedRoles.length !== initialRoles.length || subscriptionDependentSelectedRoles.some(r => !initialRoles.includes(r));
        const isBasePlanVisible = subscriptionDependentSelectedRoles.length === 1 && subscriptionDependentSelectedRoles[0] === roles.SellerAdministrator;
        const isStandardPlanVisible = selectedRoles.some(role =>
            role === roles.BrokerDealerViewer ||
            role === roles.SellerViewer ||
            role === roles.Viewer ||
            role === roles.CollateralManager
        ) && !isBasePlanVisible;
        const isProPlanVisible = !isStandardPlanVisible && !isBasePlanVisible;
        return (
            <div className="subscription-info">
                {
                    isSubscriptionExist && !isUserRoleChanged
                        ? renderSubscriptionTitle(initialUser.subscription, moment(initialUser.trialPeriodEnd))
                        : (
                            <>
                                {isBasePlanVisible && renderSubscriptionTitle('Basic', moment().add(constants.trialPeriodDays, 'd'))}
                                {isStandardPlanVisible && renderSubscriptionTitle('Standard', moment().add(constants.trialPeriodDays, 'd'))}
                                {isProPlanVisible && renderSubscriptionTitle('Pro', moment().add(constants.trialPeriodDays, 'd'))}
                            </>
                        )

                }
                {getMostExpensiveText()}
            </div>
        );
    };

    const getSellerOptionDisabledState = (role: string, selectedRoles: string[]) => {
        const checkSelectedRoles = (...roles: string[]) => roles.some(r => selectedRoles.includes(r));
        const checkSingleRoleSelected = (role: string) => selectedRoles.length === 1 && role === selectedRoles[0];

        switch (role) {
            case roles.ArrangersClient:
                return (
                    checkSelectedRoles(
                        roles.CollateralManager,
                        roles.SellerTrader,
                        roles.SellerAdministrator,
                        roles.SellerViewer
                    )
                )
            case roles.CollateralManager:
                return (
                    checkSelectedRoles(roles.ArrangersClient) ||
                    checkSingleRoleSelected(roles.SellerAdministrator)

                )
            case roles.SellerTrader:
                return checkSelectedRoles(roles.SellerViewer, roles.ArrangersClient)
            case roles.SellerViewer:
                return (
                    checkSelectedRoles(roles.SellerTrader, roles.ArrangersClient)
                )
            case roles.SellerAdministrator:
                return (
                    checkSelectedRoles(roles.ArrangersClient) ||
                    checkSingleRoleSelected(roles.CollateralManager)
                )
            default:
                return false;
        }
    }

    const renderRoleSelect = useCallback((setFieldValue: any, values: any, errorMessage: any) => {
        const selectedCompanyId: number = Number(values.companyId);
        const selectedRoles: string[] = [...values.roles];
        const selectedCompany = companies && companies.find(c => c.id === selectedCompanyId);
        if (
            selectedCompany?.role === companyRoles.Seller ||
            selectedCompany?.role === companyRoles.Administrator ||
            selectedCompany?.role === companyRoles.Media) {
            let roleItems: MultiSelectDataItem[] = [];
            if (selectedCompany.role === companyRoles.Seller) {
                roleItems = // Seller roles
                    roles.getByCompanyRole(companyRoles.Seller)
                        .filter(r => initialUser.id || r !== roles.ArrangersClient) // Hide ArrangersClient for new user
                        .map(role => {

                            const text = roles.getTitle(role);
                            const value = role;
                            const selected = selectedRoles.some(r => r === role);
                            const disabled = !selected && getSellerOptionDisabledState(role, selectedRoles);

                            return {
                                text,
                                value,
                                selected,
                                disabled
                            };
                        })
            } else if (selectedCompany?.role === companyRoles.Administrator) {
                roleItems = [// Admin roles
                    {
                        text: roles.getTitle(roles.Administrator),
                        value: roles.Administrator,
                        selected: selectedRoles.some(r => r === roles.Administrator),
                        disabled: selectedRoles.some(r => r === roles.DataEntry)
                    }, {
                        text: roles.getTitle(roles.DataEntry),
                        value: roles.DataEntry,
                        selected: selectedRoles.some(r => r === roles.DataEntry),
                        disabled: selectedRoles.some(r => r === roles.Administrator)
                    }, {
                        text: roles.getTitle(roles.SubscriptionManager),
                        value: roles.SubscriptionManager,
                        selected: selectedRoles.some(r => r === roles.SubscriptionManager),
                    }
                ];
            } else if (selectedCompany?.role === companyRoles.Media) {
                roleItems = [{
                    text: roles.getTitle(roles.Media),
                    value: roles.Media,
                    selected: true,
                    disabled: true
                }, {
                    text: roles.getTitle(roles.Viewer),
                    value: roles.Viewer,
                    selected: true,
                    disabled: true
                }]
            }

            const sortedRoleItems = roleItems.sort((a, b) => a.text.localeCompare(b.text));
            return (
                <MultiSelect
                    required={true}
                    sourceItems={sortedRoleItems}
                    placeholder={!selectedCompany ? 'Choose a Company' : 'Choose Role'}
                    onItemSelect={(item, selected) => {
                        let newRoles = [...selectedRoles];
                        const isOnlyCollatteralManager = newRoles.length === 0 && item.value === roles.CollateralManager;

                        if (selected) {
                            newRoles = isOnlyCollatteralManager ?
                                [roles.CollateralManager, roles.SellerViewer] :
                                [...newRoles, String(item.value)]
                        } else {
                            newRoles = selectedRoles.filter(r => r !== item.value);
                        }

                        setFieldValue('roles', newRoles)
                    }}
                    showAddButton={false}
                    className={cn({ 'is-invalid': !!errorMessage })}
                />
            )
        }

        return (
            <select
                className={cn('form-control form-select', { 'is-invalid': !!errorMessage })}
                value={(selectedRoles && selectedRoles[0]) || ''}
                onChange={e => setFieldValue('roles', [e.target.value])}
            >
                {!selectedCompany && <option value="" disabled>Choose a Company</option>}
                {selectedCompany && (!selectedRoles.length) && <option value="" disabled>Choose Role</option>}
                {
                    selectedCompany && roles.getByCompanyRole(selectedCompany.role)
                        .map(r => <option key={r} value={r}>{roles.getTitle(r)}</option>)
                }
            </select>
        )
    }, [companies, initialUser.id]);

    const renderTitle = (): React.ReactNode => {
        if (userId) {
            return (
                <>
                    Edit User
                    <span
                        className={'status ' + userStatuses.getByStatusCode(initialUser?.status)?.className}
                    >
                        {userStatuses.getByStatusCode(initialUser?.status)?.title}
                    </span>
                </>
            )
        }
        return <>Create New User</>
    };

    const renderDeskSelect = (
        values: FormValues,
        setFieldValue: (field: string, value?: UserDesk) => void
    ) => {
        const isBdSelected = values.roles.some(role => roles.bd().includes(role));

        if(!isBdSelected) {
            return null;
        }

        return (
            <div className="form-item form-item-desk">
                <label className="form-label">
                    Desk
                </label>
                <select
                    className="form-control form-select"
                    id="desk"
                    name="desk"
                    value={values.desk}
                    onChange={e => {
                        const { value, name } = e.target;
                        const formattedValue = +value;
                        setFieldValue(name, isNaN(formattedValue) ? undefined : formattedValue);
                    }}
                >
                    {deskTypes.map(d =>
                        <option key={d.label} value={d.value}>{d.label}</option>)
                    }
                </select>
            </div>
        );
    }

    const handleSubmit = (values: FormValues) => {
        dispatch(actions.saveMember(values))
    };

    const validationSchema = yup.object().shape({
        firstName: yup.string().trim().required().matches(constants.name).max(64),
        lastName: yup.string().trim().required().matches(constants.name).max(64),
        email: yup.string().trim().required().email().max(256),
        phone: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
        jobTitle: yup.string().required().max(256),
        companyId: yup.number().transform(value => (value ? value : undefined)).required(),
        linkedIn: yup.string().trim().matches(constants.linkedIn).max(2083),
        location: yup.string().max(64),
        roles: yup.array()
            .test(
                'is-not-only-subscription-manager',
                'User role cannot be only Subscription Manager.',
                value => !(value && value.length === 1 && value[0] === roles.SubscriptionManager)
            )
            .test(
                'is-not-only-collateral-manager',
                'User role cannot be only Collateral Manager.',
                value => !(value && value.length === 1 && value[0] === roles.CollateralManager)
            )
            .test(
                'is-not-only-collateral-manager',
                'User role cannot be only Collateral Manager and Seller Admin roles at once.',
                value => !(value && value.length === 2 && value.includes(roles.CollateralManager) && value.includes(roles.SellerAdministrator))
            )
            .of(yup.string()).transform(value => (value.length ? value : undefined)).required(),
    });

    const initialFormValues: FormValues = {
        id: initialUser.id,
        firstName: initialUser.firstName,
        lastName: initialUser.lastName,
        email: initialUser.email,
        jobTitle: initialUser.jobTitle || '',
        phone: initialUser.phone || '',
        companyId: initialUser.companyId,
        roles: initialUser.roles?.length ? initialUser.roles : [],
        primaryContact: Boolean(initialUser.primaryContact),
        linkedIn: initialUser.linkedIn || '',
        location: initialUser.location || '',
        desk: initialUser.desk,
    };

    if (isEmpty(initialUser)) return <Preloader inProgress={true} />

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched, setFieldValue, setFieldTouched, values, submitCount, isValid }) => (
                <Preloader inProgress={!initEditUserPageComplete || isSavingUserRequest}>
                    {initEditUserPageComplete && !isSavingUserRequest && (
                        <Form className="manage-user container" noValidate>
                            <div className="sub-header">
                                <div className="sub-header-row type01 flex-row">
                                    <Breadcrumbs>
                                        <BreadcrumbsItem
                                            route={routes.manageCompanies}
                                            text='Manage Companies & Users'
                                        />
                                    </Breadcrumbs>
                                    <h1>{renderTitle()}</h1>
                                    <div className="flex-item-right controls">
                                        <button
                                            className="btn btn-ghost"
                                            type="button"
                                            onClick={() => history.replace(routes.manageCompanyMembers)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-main"
                                            type="submit"
                                            disabled={isSavingUserRequest || isEqual(initialFormValues, values) || (!isValid && submitCount)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="manage-user-form">
                                <div className="manage-user-form-cnt">
                                    <EditMemberSection title="Personal Information">
                                        <div className="form-row form-row-inline">
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        markRequired={true}
                                                        name="firstName"
                                                        loading={false}
                                                        maxLength={64}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        markRequired={true}
                                                        name="lastName"
                                                        loading={false}
                                                        maxLength={64}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="Title"
                                                        placeholder="Title"
                                                        markRequired={true}
                                                        name="jobTitle"
                                                        loading={false}
                                                        maxLength={256}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row form-row-inline">
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="Email"
                                                        placeholder="Email Address"
                                                        markRequired={true}
                                                        name="email"
                                                        loading={false}
                                                        maxLength={64}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="Phone"
                                                        placeholder="Phone Number"
                                                        markRequired={false}
                                                        name="phone"
                                                        type="tel"
                                                        loading={false}
                                                        maxLength={256}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="LinkedIn"
                                                        placeholder="LinkedIn"
                                                        markRequired={false}
                                                        name="linkedIn"
                                                        loading={false}
                                                        maxLength={2083}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row form-row-inline">
                                            <div className="form-item">
                                                <div className="form-control-wrapper">
                                                    <InputField
                                                        label="Location"
                                                        placeholder="Location"
                                                        markRequired={false}
                                                        name="location"
                                                        loading={false}
                                                        maxLength={64}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </EditMemberSection>
                                    <div className="columns flex-row">
                                        <EditMemberSection title="Company Information">
                                            <div className="form-row">
                                                <div className="form-item">
                                                    <label className="form-label" htmlFor="company">
                                                        Company<span className="text-red">&nbsp;*</span>
                                                    </label>
                                                    <select
                                                        className={cn(
                                                            'form-control form-select',
                                                            { 'is-invalid': !values.companyId && touched.companyId && !!errors.companyId }
                                                        )}
                                                        id="companyId"
                                                        name="companyId"
                                                        value={values.companyId || ''}
                                                        required
                                                        onChange={e => {
                                                            const { value, name } = e.target;
                                                            setFieldValue(name, value);

                                                            const company = companies.find(c => c.id === +value);
                                                            const isMedia = company?.role === companyRoles.Media
                                                            setFieldValue('roles', isMedia ? [roles.Media, roles.Viewer] : []);
                                                            setFieldTouched('roles', isMedia, isMedia);
                                                        }}
                                                    >
                                                        {!userId && <option value="" disabled>Choose Existing</option>}
                                                        {companiesToSelect.map(c =>
                                                            <option key={c.id} value={c.id}>{c.name}</option>)
                                                        }
                                                    </select>
                                                    <FormError
                                                        message={!values.companyId && touched.companyId && errors.companyId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-item">
                                                    <Relative>
                                                        <label className="form-label" htmlFor="company">
                                                            Role<span className="text-red">&nbsp;*</span>
                                                        </label>
                                                        {renderRoleSelect(setFieldValue, values, !!submitCount && touched.roles && errors.roles)}
                                                        <FormError message={!!submitCount && touched.roles && errors.roles} />
                                                    </Relative>
                                                </div>
                                            </div>
                                            <div className="form-row form-row-desk">
                                                {renderDeskSelect(values, setFieldValue)}
                                                <div className="form-item form-item-primary-contact">
                                                    <Checkbox
                                                        onChange={e => setFieldValue('primaryContact', e.target.checked)}
                                                        checked={values.primaryContact}
                                                        label="Primary Company Contact"
                                                    />
                                                </div>
                                            </div>
                                        </EditMemberSection>
                                        <div className="display-none">
                                            {
                                                roles.subscriptionChange().some(r => r === values.roles.find(userRole => userRole === r)) &&
                                                <EditMemberSection title="Subscription">
                                                    {renderSubscription(values.roles)}
                                                </EditMemberSection>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Preloader>
            )}
        </Formik>
    )
};
