import React from 'react';
import { useClearRedisCache } from './useClearRedisCache';
import { isRequestSuccess, isRequestFailed } from '../../../utils';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';

export const ManageCleanRedisCache: React.FC = () => {
    const { requestState, clearCache } = useClearRedisCache();

    const renderResponseStatus = () => {
        if(isRequestSuccess(requestState)) {
            return (
                <StatusMessageSection type={StatusMessageSectionType.Success} iconOnly>
                    Cache is cleared successfully
                </StatusMessageSection>
            )
        }
        if(isRequestFailed(requestState)) {
            return (
                <StatusMessageSection type={StatusMessageSectionType.Error} iconOnly>
                    Failed to clear the cache
                </StatusMessageSection>
            )
        }
       return ''
    }

    return (
        <div className="container manage-cnt">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Storage</h1>
                    <button className="btn btn-ghost flex-none" onClick={clearCache} disabled={isRequestSuccess(requestState)}>
                        Clear cache
                    </button>
                    {renderResponseStatus()}
                </div>
            </div>
        </div>
    )
}
