import React, { useRef, useState } from 'react';
import { documentsService } from "../../../services";
import IconSVG from "../../../styles/svg-icons";
import { constants } from "../../../constants";
import { errorActions } from '../../../actions';
import { useDispatch } from 'react-redux';
import { Preloader } from '../../common/Preloader';
import { DownloadTemplateButton } from '../../common/download-template/DownloadTemplateButton';

export function ImportSecurities() {
    const [requestUploadSecurities, fileInput, uploadSecurities, securities] = useUploadSecurities();

    const handleImportSecurities = () => {
        if (fileInput.current) fileInput.current.click()
    };

    const renderContent = () => {
        if (securities && Array.isArray(securities) && securities.length) {
            return (
                <>
                    <h2>Import Results</h2>
                    <div className="import-securities-box">
                        <p>Securities Import started</p>
                        {securities.map((item, index) => {
                            return <p key={`res-item-${item}-${index}`}>{item}</p>
                        })}
                        <p>Securities Import completed</p>
                    </div>
                </>)
        }
        return null;
    };

    return (
        <div className="container">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Securities</h1>
                    <button
                        className="btn btn-ghost"
                        type="button"
                        onClick={handleImportSecurities}
                        disabled={requestUploadSecurities}
                    >
                        <IconSVG name="import" width={16} height={16} />
                        Import Securities
                    </button>
                    <div className="flex-item-right controls">
                        <DownloadTemplateButton templateType="ManageSecuritiesXlsxTemplate" />
                    </div>
                    <input
                        ref={fileInput}
                        type="file"
                        accept={constants.acceptedTemplateExtensions}
                        style={{ display: 'none' }}
                        onChange={uploadSecurities}
                    />
                </div>
            </div>
            <div className="import-securities">
                <Preloader inProgress={requestUploadSecurities}>
                    {renderContent()}
                </Preloader>
            </div>
        </div>
    )
}

const useUploadSecurities = () => {
    const dispatch = useDispatch();
    const fileInput = useRef(null);
    const [requestUploadSecurities, setRequestUploadSecurities] = useState(false);
    const [securities, setSecurities] = useState([]);

    const uploadSecurities = e => {
        if (e.target.files.length && fileInput.current) {
            setRequestUploadSecurities(true);
            setSecurities([]);
            documentsService.uploadSecurities(e.target.files[0])
                .then((res) => {
                    setSecurities(res || []);
                })
                .catch((err) => {
                    dispatch(errorActions.unexpectedError(err))
                })
                .finally(() => setRequestUploadSecurities(false));
            fileInput.current.value = "";
        }
    };
    return [requestUploadSecurities, fileInput, uploadSecurities, securities]
};
