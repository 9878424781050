import { InventoryPosition } from "../../../types/inventory/InventoryPosition";
import { useBdInventoryCounters } from "./useBdInventoryCounters";
import { BaseInventorySummaryRow } from "../BaseInventorySummaryRow";

interface BdInventorySummuryRowProps {
    inventoryList: InventoryPosition[];
}

export function BdInventorySummuryRow({ inventoryList }: BdInventorySummuryRowProps) {

    const { securitiesTraded, securitiesNew, usdVolume, eurVolume, securitiesOnBWICs, ratingCounter, securitiesCounter } = useBdInventoryCounters(inventoryList);

    return (
        <BaseInventorySummaryRow 
            securitiesTraded={securitiesTraded}
            securitiesNew={securitiesNew}
            usdVolume={usdVolume}
            eurVolume={eurVolume}
            securitiesOnBWICs={securitiesOnBWICs} 
            ratingCounter={ratingCounter} 
            securitiesCounter={securitiesCounter} />
    )
}