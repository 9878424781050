import cn from 'classnames';
import { useRef, useState } from 'react';
import * as React from 'react';
import { useGetWebinar } from '../../../effects/useGetWebinar';
import { isRequesting } from '../../../utils';
import { Preloader } from '../../common';
import { useGetCategoryArticles } from '../effects/useGetCategoryArticles';
import { useGetCategorySections } from '../effects/useGetCategorySections';
import { useHelpResourcesCategories } from '../effects/useHelpResourcesCategories';
import { LazyLoad } from '../../common/LazyLoad';
import { WebinarContent } from './WebinarContent';
import { MarketTrendsWebinarsContent } from './MarketTrendsWebinarsContent';

const marketTrendsCategoryId = 14188685758100;

export const MarketTrendsWebinars = () => {
    const mainDiv = useRef(null);
    const { requestStateGetWebinar, webinar } = useGetWebinar();
    const { categories, categoriesRequestState } = useHelpResourcesCategories();
    const { requestStateGetCategorySections, sections } = useGetCategorySections(marketTrendsCategoryId);
    const { requestStateGetCategoryArticles, articles } = useGetCategoryArticles(marketTrendsCategoryId);
    const [topHeaderVisible, setTopHeaderVisible] = useState(true);

    const category = categories.find(c => c.id === marketTrendsCategoryId);

    return (
        <Preloader
            inProgress={
                isRequesting(categoriesRequestState) ||
                isRequesting(requestStateGetCategorySections) ||
                isRequesting(requestStateGetWebinar) ||
                isRequesting(requestStateGetCategoryArticles) ||
                !category
            }
        >
            <div className={cn('resources-cnt', { 'navigation-sticky': !topHeaderVisible })} ref={mainDiv}>
                <LazyLoad
                    className="webinar-header-placeholder"
                    enabledUnloading={true}
                    onVisibleChange={visible => setTopHeaderVisible(visible)}
                >
                    <div className="webinar-header webinar-cnt-center flex-row">
                        <div className="webinar-header-title">
                            <div className="text-bold">{category?.name}</div>
                            <p>{category?.description}</p>
                        </div>
                        {!!webinar && <WebinarContent webinar={webinar} />}
                    </div>
                </LazyLoad>
                <MarketTrendsWebinarsContent sections={sections} articles={articles} mainDiv={mainDiv}/>
            </div>
        </Preloader>
    )
}

