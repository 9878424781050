import * as React from 'react';
import { useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import { HelpResourceArticle } from '../../../types/help-resources/HelpResourceArticle';
import { HelpResourceSection } from '../../../types/help-resources/HelpResourceCategory';
import { Player } from '../../common/Player';
import { stringUtils } from '../../../utils';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RequiredFeature } from '../../access/RequiredFeature';
import { SubscribeLink } from '../../access/BlockedFeatureText';
import { BlockedFeatureContext } from '../../access/BlockedFeatureContext';
import classNames from 'classnames';

interface MarketTrendsSectionProps {
    section: HelpResourceSection;
    articles: HelpResourceArticle[];
}

const allowedDescriptionTags = ['h2', 'br', 'p', 'a'];
// Article index
const reportArticleIndex = 0;
const newsletterArticleIndex = 1;
const videoArticleIndex = 2;

export function MarketTrendsSection({ section, articles }: MarketTrendsSectionProps) {
    const sectionRef = useRef<HTMLDivElement>(null);
    const sectionMonth = section.name.split(' ')[0];

    const getSectionDescription = (description = '') =>
        description
            .replace('*', '<h2>')
            .replace('*', '</h2>');

    return (
        <div ref={sectionRef} className="webinar-item flex-row" key={section.id} id={sectionMonth}>
            <div className="webinar-item-details">
                <div className="webinar-item-name text-sm">{section.name}</div>
                {section.description && (
                    <div className="webinar-item-desc" dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(getSectionDescription(section.description), { allowedTags: allowedDescriptionTags })
                    }}
                    />
                )}
                <div className="webinar-item-links flex-row">
                    {articles?.[reportArticleIndex] && (
                        <ActionBlocker feature={SubscriptionFeature.CloMarketTrands}>
                            <MarkedTrendsLink href={articles?.[0]?.title} text="CLO Market Trends Report" />
                        </ActionBlocker>
                    )}
                    {articles?.[reportArticleIndex] && articles?.[newsletterArticleIndex] && (
                        <span className="vertical-divider vertical-divider-light" />
                    )}
                    {articles?.[newsletterArticleIndex] && <MarkedTrendsLink href={articles?.[1]?.title} text="Newsletter" />}
                </div>
            </div>

            <div className="webinar-item-media">
                {stringUtils.isValidUrl(articles?.[videoArticleIndex]?.title) && (
                    <RequiredFeature
                        feature={SubscriptionFeature.HistoricalWebinars}
                        text={<><SubscribeLink /> to unlock access to <span className="text-bold">historical Webinars</span>.</>}
                    >
                        <Player
                            url={articles?.[videoArticleIndex].title}
                            width={384}
                            height={216}
                        />
                    </RequiredFeature>
                )}
            </div>
        </div>
    );
}

function MarkedTrendsLink({ href, text }: { href: string, text: string }) {
    const disabled = React.useContext(BlockedFeatureContext);
    return (
        <a className={classNames("btn btn-link secondary regular", { disabled })} href={disabled ? '/#' : href} target="_blank" rel="noreferrer">
            {text}
        </a>
    )
}
