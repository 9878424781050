import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RequestState } from "../../../constants/request-state";
import IconSVG from "../../../styles/svg-icons";
import { AppState } from "../../../types/state/AppState";
import { isRequesting } from "../../../utils"
import { OnHoverTooltip, Preloader } from "../../common"
import { RejectReasonPopup } from "../../common/RejectReasonPopup";
import { userRequestAdminActions } from '../../../actions/user-request-admin.actions';

interface Props {
    userRequestId: number;
    concurrencyStamp: Date;
}

export const MembersListPendingUserRequestOptionsButtons = ({ userRequestId, concurrencyStamp }: Props) => {
    const dispatch = useDispatch();
    const updateState = useSelector((s: AppState) => userRequestId
        ? s.companies.userRequestOperationState[userRequestId]
        : RequestState.none) ?? RequestState.none;
    const [blockReasonVisible, setBlockReasonVisible] = useState(false);

    const handleBlockConfirmed = (message: string) => {
        if (userRequestId) {
            setBlockReasonVisible(false);
            dispatch(userRequestAdminActions.block(userRequestId, concurrencyStamp, message));
        }
    }

    return (
        <Preloader inProgress={isRequesting(updateState)} text="Processing…">
            <div className="affirm-reject">
                <OnHoverTooltip overlay="Approve">
                <button
                    className="btn-link btn-affirm flex-item-right"
                    onClick={() => dispatch(userRequestAdminActions.approve(userRequestId, concurrencyStamp))}
                >
                    <IconSVG name="saved" width={16} height={16} />
                </button>
            </OnHoverTooltip>
                <OnHoverTooltip overlay="Block">
                <button className="btn-link btn-reject btn-danger" onClick={() => setBlockReasonVisible(true)}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
                </OnHoverTooltip>
            </div>
            {
                blockReasonVisible &&
                <RejectReasonPopup
                    title="Blocked Reason"
                    onClose={() => setBlockReasonVisible(false)}
                    onReject={handleBlockConfirmed}
                    comfirmButtonText="Block"
                />
            }
        </Preloader>
    )
}
