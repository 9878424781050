import cn from 'classnames';
import { useMarkedTrendsRoutingFilter } from './useMarkedTrendsRoutingFilter';

interface Props {
    months: {month: string}[]
}

export function MarketTrendsMonthFilter({ months = [] }: Props) {
    const { selectedMonth } = useMarkedTrendsRoutingFilter();

    return (
        <div className="webinar-navigation webinar-navigation-months">
            <div className="webinar-navigation-cnt webinar-cnt-center">
                {
                    months.map(month => (
                        <a
                            key={month.month}
                            className={cn('btn btn-sm', { 'btn-selected': month.month === selectedMonth })}
                            href={`#${month.month}`}
                        >
                            {month.month}
                        </a>
                    ))
                }
            </div>
        </div>
    );
}
