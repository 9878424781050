import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export const useMarkedTrendsRoutingFilter = () => {
    const history = useHistory();
    const { year } = useParams<{ year: string }>();
    const selectedMonth = history.location.hash.replace('#', '');

    return { selectedYear: Number(year), selectedMonth }
}
