import * as React from 'react';
import { roles } from '../../constants';
import { ShowFor } from '../access';
import { BdInventoryList } from './bd/BdInventoryList';
import { InventoryList } from './InventoryList';

export function InventoryView() {
    return (
        <>
            <ShowFor roles={roles.bd()}><BdInventoryList /></ShowFor>
            <ShowFor roles={roles.inventoryAccess()}><InventoryList /></ShowFor>
        </>
    );
}
