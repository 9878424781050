import moment from 'moment/moment';
import { Currencies, Currency } from "../../../../types/enums/Currency";
import { FilterBooleanGroup, FilterDateGroup, FilterSelectGroup, FilterType } from "../../../../types/filters/FilterGroup";
import { Rating, Ratings } from '../../../../types/enums/Rating';
import { InventoryPositionStatus } from "../../../../types/inventory/InventoryPositionStatus";
import { inventoryPositionStatusTitle } from "../../inventory-position-status-title";
import { collateralTypes } from "../../../../constants/collateral-types";
import { CollateralType } from "../../../../types/amr-pipeline/enums/CollateralType";
import { dateRangeFilterOptions } from "../../../../constants/date-range.filter";
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { user } from '../../../../user/user';

export const bdInventoryFilters = {
    visibleFilters: ['date', 'statuses', 'collateralType', 'currency', 'managers', 'ratings'],
    additionalFilters: ['nonCallEnd', 'reinvestmentEnd', 'esg', 'euCompliance', 'staticDeal', 'maturity', 'vintage', 'trustees', 'amr', 'closing', 'outOfNC', 'outOfRI'],
    defaultFilter: {
        date: {
            key: 'date',
            type: FilterType.Date,
            visible: true,
            text: 'Date',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: user.hasFeatures(SubscriptionFeature.InventoryHistoricalData) ? {
                selectedOption: { ...dateRangeFilterOptions.All, default: true },
                options: {
                    customDateRange: { from: null, to: null },
                },
            } : {
                selectedOption: { ...dateRangeFilterOptions.CurrentInventory, default: true },
                options: { customDateRange: { from: null, to: null } },
            },
            acceptableOptions: dateRangeFilterOptions.inventoryOptions(),
            requiredFeature: SubscriptionFeature.InventoryHistoricalData
        } as FilterDateGroup,
        statuses: {
            key: 'statuses',
            type: FilterType.Select,
            visible: true,
            text: 'Status',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [
                {
                    value: InventoryPositionStatus.added,
                    text: inventoryPositionStatusTitle[InventoryPositionStatus.added],
                    selected: false,
                    visible: true,
                    disabled: false
                },
                {
                    value: InventoryPositionStatus.updated,
                    text: inventoryPositionStatusTitle[InventoryPositionStatus.updated],
                    selected: false,
                    visible: true,
                    disabled: false
                },
                {
                    value: InventoryPositionStatus.traded,
                    text: inventoryPositionStatusTitle[InventoryPositionStatus.traded],
                    selected: false,
                    visible: true,
                    disabled: false
                },
            ],
        } as FilterSelectGroup<InventoryPositionStatus>,
        collateralType: {
            key: 'collateralType',
            type: FilterType.Select,
            visible: true,
            text: 'Collat',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [
                {
                    value: CollateralType.broadlySyndicated,
                    text: collateralTypes.BroadlySyndicated,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
                {
                    value: CollateralType.middleMarket,
                    text: collateralTypes.MiddleMarket,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
            ],
        } as FilterSelectGroup<CollateralType>,
        currency: {
            key: 'currency',
            type: FilterType.Select,
            visible: true,
            text: 'Ccy',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: Currencies.map(v => ({
                value: v,
                text: v,
                selected: false,
                disabled: false,
                visible: true,
            })),
        } as FilterSelectGroup<Currency>,
        ratings: {
            key: 'ratings',
            type: FilterType.Select,
            visible: true,
            isApplied: false,
            disabled: false,
            selected: false,
            text: 'Rtg',
            filter: Ratings.map(v => ({
                value: v,
                text: v,
                selected: false,
                disabled: false,
                visible: true,
            })),
        } as FilterSelectGroup<Rating>,
        managers: {
            key: 'managers',
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Manager',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
        } as FilterSelectGroup<string>,
        maturity: {
            key: 'maturity',
            type: FilterType.Date,
            visible: false,
            text: 'Maturity',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.maturity(),
            disabledDays: { before: moment().add(1, 'day').toDate() },
        },
        vintage: {
            key: 'vintage',
            type: FilterType.YearsDate,
            visible: false,
            text: 'Vintage',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.vintage(),
        },
        closing: {
            key: 'closing',
            type: FilterType.Date,
            visible: false,
            text: 'Closing',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.closing(),
            disabledDays: { after: moment().subtract(1, 'day').toDate() },
        },
        trustees: {
            withSearch: true,
            type: FilterType.Select,
            visible: false,
            text: 'Trustee',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'trustees',
        },
        amr: {
            key: 'amr',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Deal with AMR',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        nonCallEnd: {
            key: 'nonCallEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'NC End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.nonCallEnd(),
        } as FilterDateGroup,
        reinvestmentEnd: {
            key: 'reinvestmentEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'RI End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.reinvestmentEnd(),
        } as FilterDateGroup,
        esg: {
            key: 'esg',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'ESG',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        } as FilterBooleanGroup,
        euCompliance: {
            key: 'euCompliance',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'EU Compliance',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        } as FilterBooleanGroup,
        staticDeal: {
            key: 'staticDeal',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Static Deal',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        } as FilterBooleanGroup,
        outOfNC: {
            key: 'outOfNC',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of NC',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        } as FilterBooleanGroup,
        outOfRI: {
            key: 'outOfRI',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of Rl',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        } as FilterBooleanGroup,
    },
};
