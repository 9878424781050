import { useHistory } from 'react-router';
import { InventoryPosition } from '../../types/inventory/InventoryPosition';
import { routes } from '../../constants';
import { LocationStateBuilder, PopupType } from '../../types/state/ui/LocationState';
import { SecurityUsagePopup, SecurityUsagePopupTab, TSecurityUsagePopupPayload } from '../security-usage-popup/SecurityUsagePopup';
import { NewBwicButton } from '../common/new-bwic-button/NewBwicButton';

interface InventoryNewBwicButtonProps {
    count: number;
    selectedSecurities: InventoryPosition[];
}

export function InventoryNewBwicButton({ count, selectedSecurities }: InventoryNewBwicButtonProps) {
    const history = useHistory();

    const handleCreateNewBwic = () => {
        history.push({ pathname: routes.newBWIC, state: { securities: selectedSecurities } })
    }

    const handleNewBwicClick = () => {
        const tickers = selectedSecurities.filter(s => s.scheduledOrBiddingBwicsCount).map(s => s.ticker);

        if (tickers.length) {
            history.push({
                ...history.location,
                state: new LocationStateBuilder<unknown, TSecurityUsagePopupPayload>()
                    .popup(PopupType.SecurityUsage, { tickers })
                    .result()
            });
        } else {
            handleCreateNewBwic();
        }
    }

    return (
        <SecurityUsagePopup
            disabled={!count}
            acceptedTabs={[SecurityUsagePopupTab.Bwics]}
            onConfirm={() => handleCreateNewBwic()}
        >
            {
                requesting => requesting
                    ? <button disabled className="btn btn-main">Loading...</button>
                    : <NewBwicButton handleClick={handleNewBwicClick} selectedSecurityCount={count} />
            }
        </SecurityUsagePopup>
    );
}
