import React, { useCallback, useEffect, useState } from 'react';
import { entries, isEqual } from 'lodash';
import { bwicDateFilterOptions } from '../../../constants';
import { DateRange } from '../../../types/filters/DateRange';
import { DateFilterOption } from '../../../types/filters/DateFilterOption';
import { FilterButton, FilterClear, FilterPanel } from '../../filters';
import {
    ManageEmailDetailsEventStatus,
    ManageEmailDetailsEventStatusLabels, ManageEmailTypeOption
} from '../../../types/management/ManageEmailDetails';
import { FilterSelect } from '../../common/filters/FilterSelect';
import { defaultManageEmailFilterFilter } from '../../../reducers/manage-email.reducer';
import { ManageEmailFilterState } from '../../../types/state/ManageEmailState';
import { DateRangeFilter } from '../../common/filters/FilterDateRange/DateRangeFilter';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequesting, isRequestNone } from '../../../utils';
import { RequestState } from '../../../constants/request-state';

interface Props {
    filter: ManageEmailFilterState;
    onFilterChange: (filter: ManageEmailFilterState) => void;
    typeOptions: ManageEmailTypeOption[];
    emailTypesRequestState: RequestState;
}

export const ManageEmailFilter: React.FC<Props> = ({ onFilterChange, filter, typeOptions, emailTypesRequestState }) => {
    const initialFilter = useSelector((s: AppState) => s.manageEmail.initialFilter);
    const [state, setState] = useState({ ...filter });


    useEffect(() => {
        if (isEqual(filter, initialFilter)) {
            setState({ ...defaultManageEmailFilterFilter });
            setLastAppliedFilter({ ...defaultManageEmailFilterFilter });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const getEmailStatusSelectItems = useCallback(() =>
        entries(ManageEmailDetailsEventStatusLabels)
            .sort(([, a], [, b]) => a.localeCompare(b))
            .map(([key, text]) => ({
                text,
                value: key,
                selected: state.statuses.some(t => t === key as unknown as ManageEmailDetailsEventStatus),
                visible: true,
                disabled: false
            })
            ), [state.statuses]);

    const getEmailTypesSelectItems = useCallback(() =>
        typeOptions
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((el) => ({
                text: el.description,
                value: el.value,
                selected: state.types.some(t => t === el.value),
                visible: true,
                disabled: false
            })
            ), [state.types, typeOptions]);


    const [lastAppliedFilter, setLastAppliedFilter] = useState({ ...filter });

    const isFilterApplied = !isEqual(state, lastAppliedFilter);

    const isFilterChanged = !isEqual(state, defaultManageEmailFilterFilter)

    const handleCustomDateChange = (range: DateRange) => {
        setState(state => ({ ...state, customDateRange: range, selectedDateOption: bwicDateFilterOptions.custom }));
    }

    const handleSelectedDateChange = (option: DateFilterOption) => {
        setState(state => ({ ...state, selectedDateOption: option }));
    }

    const handleStatusChange = (status: ManageEmailDetailsEventStatus) => {
        setState({
            ...state,
            statuses: state.statuses?.indexOf(status) === -1
                ? state.statuses.concat(status)
                : state.statuses?.filter(s => s !== status)
        })
    }

    const handleManageEmailTypeChange = (type: number) => {
        setState({
            ...state,
            types: state.types.indexOf(type) === -1
                ? state.types.concat(type)
                : state.types.filter(t => type !== t)
        })
    }

    const handleManageEmailTypeClearAll = () => {
        setState({
            ...state,
            types: []
        });
    }

    const handleManageEmailTypeSelectAll = () => {
        setState({
            ...state,
            types: typeOptions.map(el => el.value)
        });
    }

    const handleManageEmailStatusClearAll = () => {
        setState({
            ...state,
            statuses: []
        });
    }

    const handleManageEmailStatusSelectAll = () => {
        setState({
            ...state,
            statuses: Object.keys(ManageEmailDetailsEventStatusLabels) as unknown as ManageEmailDetailsEventStatus[]
        });
    }

    const handleApply = () => () => {
        setLastAppliedFilter({ ...state });
        onFilterChange({ ...state });
    }

    const handleReset = () => () => {
        onFilterChange({ ...defaultManageEmailFilterFilter });
        setLastAppliedFilter({ ...defaultManageEmailFilterFilter });
        setState({ ...defaultManageEmailFilterFilter });
    }

    return (
        <FilterPanel>
            <DateRangeFilter
                title="Date"
                customClassName="wide-range-date"
                disabled={false}
                acceptedOptions={bwicDateFilterOptions.toDefaultArray()}
                selectedDateOption={state.selectedDateOption}
                customDateRange={state.customDateRange}
                onReset={() => {
                    handleCustomDateChange({ from: null, to: null })
                    handleSelectedDateChange(bwicDateFilterOptions.unspecified)
                }}
                onCustomDateChange={handleCustomDateChange}
                onSelectedDateChange={handleSelectedDateChange}
                filter={state}
                initialFilter={initialFilter}
                lastAppliedFilter={lastAppliedFilter}
            />
            <FilterSelect
                withSearch={true}
                className="email-type"
                title="Email Type"
                multiply={true}
                disabled={isRequesting(emailTypesRequestState) || isRequestNone(emailTypesRequestState)}
                isApplied={isEqual(lastAppliedFilter.types, state.types)}
                options={getEmailTypesSelectItems()}
                onChangeItemSelection={item => {
                    handleManageEmailTypeChange(item.value)
                }}
                onClearAll={() => {
                    handleManageEmailTypeClearAll();
                }}
                onSelectAll={() => {
                    handleManageEmailTypeSelectAll();
                }}
            />
            <FilterSelect
                title="Email Status"
                multiply={true}
                isApplied={isEqual(lastAppliedFilter.statuses, state.statuses)}
                options={getEmailStatusSelectItems()}
                onChangeItemSelection={item => {
                    handleStatusChange(item.value as unknown as ManageEmailDetailsEventStatus)
                }}
                onClearAll={() => {
                    handleManageEmailStatusClearAll();
                }}
                onSelectAll={() => {
                    handleManageEmailStatusSelectAll();
                }}
            />
            <FilterButton disabled={!isFilterApplied} caption="Apply" onClick={handleApply()} />
            <FilterClear isShown={isFilterApplied || isFilterChanged} onClick={handleReset()} />
        </FilterPanel>
    )
}
