import { Redirect, Route, Switch, useHistory, useParams } from "react-router";
import { EmptyPlaceholder, Preloader } from "../../common";
import { NewsSelectionPanel } from "./NewsSelectionPanel";
import { routes } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../types/state/AppState";
import { EditNewsState } from "../../../types/state/EditNewsState";
import { useEffect, useState } from "react";
import { editNewsActions } from "../../../actions/edit-news.actions";
import { EditNewsTabType } from "../../../types/news/enums/EditNewsTabType";
import { ViewForm } from "./ViewForm";
import { EditForm } from "./EditForm";
import { defaultNews } from "./constants";
import IconSVG from "../../../styles/svg-icons";

interface EditNewsRouteParams {
    referenceName: string;
}

export const EditNews = () => {
    const { referenceName } = useParams<EditNewsRouteParams>();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isMenuPanelCollapsed, setIsMenuPanelCollapsed] = useState(false);

    const isCreate = history.location.pathname === routes.manageCreateNews;

    const {
        newsList,
        isLoading,
        isDetailsLoading,
        isMoreLoading,
        isSearching,
        newsDetails,
        initialNews,
        managers,
        arrangers,
        totalCount,
        searchTerm,
    } = useSelector<AppState, EditNewsState>(
        (state: AppState) => state.editNews
    );

    const noNews = !totalCount && !searchTerm && !isSearching;
    const createNews = () => history.replace(routes.manageCreateNews);

    useEffect(() => {
        dispatch(editNewsActions.init(referenceName));

        return () => {
            dispatch(editNewsActions.reset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (referenceName) {
            dispatch(editNewsActions.getNewsDetails(referenceName));
        }
    }, [referenceName, dispatch]);


    if (isLoading) {
        return <Preloader inProgress />;
    }

    if (!isCreate && noNews) {
        return (
            <div className="container container-manage-news">
                <EmptyPlaceholder text="You have not added news yet. To add news, click the button below.">
                    <button className="btn btn-main" onClick={createNews}>
                        <IconSVG name="plus" width={16} height={16} />
                        News
                    </button>
                </EmptyPlaceholder>
            </div>
        );
    }

    return (
        <div className="container container-manage-news">
            <div className="container-flex container-sidebar">
                <NewsSelectionPanel
                    initialNews={initialNews}
                    newsList={newsList}
                    selectedReferenceName={referenceName}
                    searchTerm={searchTerm}
                    isSearching={isSearching}
                    isLoading={isMoreLoading}
                    hasMore={newsList.length < totalCount}
                    collapsed={isMenuPanelCollapsed}
                    setCollapsed={setIsMenuPanelCollapsed}
                />
                <div className="container-flex content-part-sidebar">
                    <Preloader inProgress={isDetailsLoading}>
                        <Switch>
                            <Route
                                exact
                                path={routes.manageCreateNews}
                                render={() => (
                                    <EditForm data={defaultNews} managers={managers} arrangers={arrangers}/>
                                )}
                            />
                            <Route
                                exact
                                path={routes.manageNewsUrl(':newsReferenceName', EditNewsTabType.edit)}
                                render={() => newsDetails &&
                                    <EditForm
                                        initialReferenceName={initialNews?.referenceName}
                                        data={newsDetails}
                                        managers={managers}
                                        arrangers={arrangers}
                                    />
                                }
                            />
                            <Route
                                exact
                                path={routes.manageNewsUrl(':newsReferenceName', EditNewsTabType.view)}
                                render={() => newsDetails &&
                                    <ViewForm
                                        data={newsDetails}
                                        managers={managers}
                                        arrangers={arrangers}
                                    />
                                }
                            />
                            {initialNews?.referenceName && <Redirect to={routes.manageNewsUrl(initialNews.referenceName, EditNewsTabType.view)} />}
                        </Switch>
                    </Preloader>
                </div>
            </div>
        </div>
    );
};
