import React from 'react';
import { MarketTrendsWebinars } from './market-trends-webinar/MarketTrendsWebinars';
import { Switch, Route, Redirect } from 'react-router';
import { routes } from '../../constants';

export const RecourcesView: React.FC = () => {
    return (
        <div className="container resources resources-top-gradient resources-bottom-gradient">
            <Switch>
                <Route path={routes.resourcesWebinar} component={MarketTrendsWebinars}/>
                <Redirect to={routes.notFound}/>
            </Switch>
        </div>
    )
};
