import * as React from 'react';
import { SettlementAgentCommissionByRating } from '../../../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { CommissionByRatingTable } from '../clearing-bank/CommissionByRatingTable';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { OnHoverTooltip } from "../../../common/OnHoverTooltip";
import { Preloader } from '../../../common';

interface Props {
    isRequesting?: boolean;
    companyName: string;
    commission: SettlementAgentCommissionByRating;
    onClose: () => void;
}

export function ViewSettlementAgentCommissionPopup({ companyName, commission, isRequesting = false, onClose }: Props) {
    const handleClose = (e: MouseEvent | React.MouseEvent) => {
        e.stopPropagation();
        onClose();
    }

    return (
        <Popup
            renderInBody={true}
            modalClass="modal-commission"
            title={
                <>
                    <span className="title">Commission by Rating</span>
                    <OnHoverTooltip overlay={companyName}>
                        <span className="name-tag text-ellipsis">{companyName}</span>
                    </OnHoverTooltip>
                </>
            }
            onClose={handleClose}
        >
            <PopupBody>
                <Preloader inProgress={isRequesting}>
                    <CommissionByRatingTable
                        readonly={true}
                        commission={commission}
                    />
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={handleClose}>Close</button>
            </PopupFooter>
        </Popup>
    );
}
