import { InventoryPositionStatus } from "../../types/inventory/InventoryPositionStatus"

interface InventoryStatusLabelProps {
    status: InventoryPositionStatus
}

export const InventoryStatusLabel = ({ status }: InventoryStatusLabelProps) => {
    if (status === InventoryPositionStatus.added) return <span className="state-new">NEW</span>
    if (status === InventoryPositionStatus.updated) return <span className="state-updated">UPD</span>
    return null;
}