import { useContext, useState } from 'react';
import { roles, routes } from '../../constants';
import { ClickOutside } from '../common/ClickOutside';
import { NavBarSubItem } from './NavBarSubItem';
import { useHistory } from 'react-router-dom';
import { ShowFor } from '../access';
import { DirectBiddingDisclaimerActionBlocker } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { TradeCounterBadge } from './TradeCounterBadge';
import { ClientsMenuItem } from './ClientsMenuItem';
import { KTXTradingNotificationBadge } from './KTXTradingNotificationBadge';
import { AllToAllArgeementRequiredActionBlocker } from '../agreements/AllToAllArgeementRequiredActionBlocker';
import { user } from '../../user';
import { NavBarSubDropDownItem } from './NavBarSubDropDownItem';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

export const KTXTradingMenuItem = () => {
    const history = useHistory();
    const isBlocked = useContext(BlockedFeatureContext);
    const [expanded, setExpanded] = useState(false);
    const currentUser = user.current()!;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const isActive = () => {
        const pathname = history.location.pathname;
        const isActive =
            pathname === routes.newBWIC ||
            pathname === routes.blotter ||
            pathname === routes.portfolio ||
            pathname === routes.clients ||
            pathname === routes.dealers ||
            pathname === routes.profileManagePlatformUsers
        return (
            isActive ? 'active' : ''
        );
    }

    return (
        <li className={`navbar-item dropdown dropdown-with-badge ${expanded ? 'show' : ''}`}>
            <button
                type="button"
                className={`btn-link navbar-link dropdown-toggle ${isActive()}`}
                onClick={isBlocked ? undefined : toggleExpanded}
            >
                {
                    !currentUser.isClearingBank &&
                    currentUser.newLabelActive &&
                    <span className="new-label">new</span>
                }
                KTX Trading
                <KTXTradingNotificationBadge />
            </button>

            <div className={`dropdown-menu navbar-dropdown-menu navbar-dropdown-manage ${expanded ? 'show' : ''}`}>
                {expanded && (
                    <ClickOutside onClick={() => setExpanded(false)}>
                        <>
                            {!currentUser.isClearingBank && <ShowFor roles={[...roles.seller(), roles.BrokerDealerTrader]}>
                                <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                                    {
                                        blocked =>
                                            <DirectBiddingDisclaimerActionBlocker onConfirm={() => history.push(routes.newBWIC)}>
                                                <AllToAllArgeementRequiredActionBlocker disabled={blocked}>
                                                    {
                                                        argeementBlocked =>
                                                            <NavBarSubItem
                                                                onClick={toggleExpanded}
                                                                path={routes.newBWIC}
                                                                title='New BWIC'
                                                                disabled={argeementBlocked}
                                                            />
                                                    }
                                                </AllToAllArgeementRequiredActionBlocker>
                                            </DirectBiddingDisclaimerActionBlocker>
                                    }
                                </RoleActionBlocker>
                            </ShowFor>}
                            <ShowFor roles={[...roles.bd(), ...roles.seller()]}>
                                <NavBarSubDropDownItem
                                    onClick={toggleExpanded}
                                    items={user?.current()?.isClearingBank || user.hasSingleRole(roles.BrokerDealerViewer) ?
                                        [{ title: 'Buy', path: `${routes.myBWICs}?dateFrom=unspecified&dateTo=unspecified`, onClick: toggleExpanded }] :
                                        [{ title: 'Buy', path: `${user.hasRoles(...roles.bd()) ? routes.myBWICs : routes.sellerBuy}?dateFrom=unspecified&dateTo=unspecified`, onClick: toggleExpanded },
                                        { title: 'Sell', path: `${user.hasRoles(...roles.bd()) ? routes.allToAll : routes.sellerSell}?dateFrom=unspecified&dateTo=unspecified`, onClick: toggleExpanded }]
                                    }
                                    title='My BWICs'
                                />
                            </ShowFor>
                            <ShowFor roles={[...roles.bd(), ...roles.seller()]}>
                                <NavBarSubItem
                                    onClick={toggleExpanded}
                                    path={routes.blotter}
                                    title={(
                                        <>
                                            Blotter
                                            <TradeCounterBadge />
                                        </>
                                    )}
                                />
                            </ShowFor>
                            <ClientsMenuItem onClick={toggleExpanded} />
                            <ShowFor roles={[...roles.seller()]}>
                                <NavBarSubItem
                                    title="dealers"
                                    path={routes.dealers}
                                    onClick={toggleExpanded}
                                />
                            </ShowFor>
                            <NavBarSubItem
                                onClick={toggleExpanded}
                                path={routes.portfolio}
                                title="Portfolio"
                            />
                            <ShowFor roles={[...roles.seller(), roles.CollateralManager]}>
                                <ShowFor role={roles.SellerAdministrator}>
                                    <NavBarSubItem
                                        onClick={toggleExpanded}
                                        title="manage"
                                        className="navbar-link-address-book"
                                        path={routes.profileManagePlatformUsers}
                                    />
                                </ShowFor>
                            </ShowFor>
                        </>
                    </ClickOutside>
                )}
            </div>
        </li>
    )
};
