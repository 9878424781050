import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { user } from '../../user/user';
import { roles } from '../../constants';

export function KTXTradingNotificationBadge() {
    const pendingAgreementCount = useSelector((state: AppState) => state.clientsCompanies.pendingAgreementCount);
    const trades = useSelector((s: AppState) => s.entities.awaitingTrades.items);
    const currentUser = user.current();
    const isBlotterHasNotificitaion = trades.some(trade =>
        trade.status === TradeStatus.pending ||
        (trade.sellerSettlementStatus === TradeSettlementStatus.PendingProceeds && currentUser?.isClearingBank) ||
        (trade.buyerSettlementStatus === TradeSettlementStatus.PendingProceeds && currentUser?.isClearingBank) ||
        trade.sellerSettlementStatus === TradeSettlementStatus.PendingAllocation ||
        trade.buyerSettlementStatus === TradeSettlementStatus.PendingAllocation
    )

    if (user.hasRoles(...roles.bd()) &&
        (
            isBlotterHasNotificitaion ||
            !!pendingAgreementCount
        )
    ) {
        return <span className="alert-badge"></span>
    }
    else if (isBlotterHasNotificitaion) {
        return <span className="alert-badge"></span>
    }
    return null
}
