import React from 'react';
import classNames from 'classnames';
import { companyStatuses } from '../../../constants';
import { Popover } from '../../controls';

export function CompanyStatus({ company }) {
    const hasBlockedReason = company.status === companyStatuses.blocked.statusCode && company.statusUpdateReason;

    const statusClasses = classNames({
        'first-letter status': true,
        [`${companyStatuses.getByStatusCode(company.status).className}`]: true,
        'has-blocked-reason': company.statusUpdateReason
    });

    if (hasBlockedReason) {
        return (
            <Popover
                title="Block Reason"
                className="popover-md"
                actionButton={(
                    <div className={statusClasses}>
                        <span>{companyStatuses.getByStatusCode(company.status).title}</span>
                    </div>
                )}
            >
                <div className="status-message alert">
                    <i className="icon icon-warning" />
                    <span className="status-message-cnt">{company.statusUpdateReason}</span>
                </div>
            </Popover>
        )
    }
    return (
        <div className={statusClasses}>
            <span>{companyStatuses.getByStatusCode(company.status).title}</span>
        </div>
    )
}
