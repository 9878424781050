import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Preloader, Relative } from '../../common';
import { FormError } from '../../controls';
import { errorMessages } from '../../../constants';
import { validateEmail } from '../../../utils';
import { bdInventoryActions } from '../../../actions';
import IconSVG from '../../../styles/svg-icons';

interface AddBloombergEmailProps {
    toggleVisibleForm: () => void;
    isRequestSaveEmail: boolean;
}

export const AddBloombergEmail: React.FC<AddBloombergEmailProps> = ({ toggleVisibleForm, isRequestSaveEmail = false }) => {
    const dispatch = useDispatch();
    const emailDomain = '@bloomberg.net';
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const disabledSaveButton = !email;

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const [mail, domain] = value.trim().split('@');
        const newEmailName = `@${domain}` === emailDomain ? mail : value;
        if (error) {
            setError('')
        }
        setEmail(newEmailName);
    };

    const handleSave = () => {
        const newEmail = `${email}${emailDomain}` || '';
        if (!validateEmail(newEmail)) {
            return setError(errorMessages.invalidEmail);
        }
        dispatch(bdInventoryActions.addBloombergEmailRequest(newEmail))
    };

    return (
        <div className="form-row add-contact-form">
            <label className="form-label" htmlFor="participantEmail">Email</label>
            <div className="flex-row">
                <div className="form-control-wrapper flex-item">
                    <input
                        className={classNames('form-control', { 'is-invalid': !!error })}
                        value={email || ''}
                        maxLength={50}
                        onChange={handleChangeEmail}
                    />
                    <span className="mask-domain">{emailDomain}</span>
                    <FormError message={error} />
                </div>
                <div className="buttons">
                    <Relative>
                        <Preloader inProgress={isRequestSaveEmail}>
                            <button className="btn btn-link btn-success" onClick={handleSave} disabled={disabledSaveButton}>
                                <IconSVG name="check" width={16} height={16} />
                            </button>
                            <button className="btn btn-link btn-danger" onClick={toggleVisibleForm}>
                                <IconSVG name="close" width={16} height={16} />
                            </button>
                        </Preloader>
                    </Relative>
                </div>
            </div>
        </div>
    )
};
