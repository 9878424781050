import React, { useEffect } from 'react';
import { userRequestAdminActions } from '../../actions/user-request-admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { Badge } from '../common/Badge';
import { isRequestNone } from '../../utils';
import { OnHoverTooltip } from '../common';

export const PendingUserRequestCountBadge = () => {
    const dispatch = useDispatch();

    const pendingUserRequestCount = useSelector((state: AppState) => state.companies.pendingUserRequestCount);
    const requestStateGetPendingUserRequestCount = useSelector((state: AppState) => state.companies.requestStateGetPendingUserRequestCount);

    useEffect(() => {
        if(isRequestNone(requestStateGetPendingUserRequestCount)) {
            dispatch(userRequestAdminActions.getPendingUserRequestCount());
        }
    }, [requestStateGetPendingUserRequestCount, dispatch]);

    if (!pendingUserRequestCount) return null;

    return (
        <OnHoverTooltip overlay="Unapproved users">
            <Badge value={pendingUserRequestCount} />
        </OnHoverTooltip>
    )
}
