import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../actions';
import { RequestState } from '../../../constants/request-state';
import { helpResourcesService } from '../../../services/help-resources.service';
import { HelpResourceSection } from '../../../types/help-resources/HelpResourceCategory';

export const useGetCategorySections = (categoryId?: number) => {
    const dispatch = useDispatch();

    const [sections, setSections] = useState<HelpResourceSection[]>([]);
    const [requestStateGetCategorySections, setRequestStateGetCategorySections] = useState(RequestState.none);

    const getData = useCallback(async (categoryId: number) => {
        try {
            setRequestStateGetCategorySections(RequestState.request);
            const data = await helpResourcesService.fetchCategorySections(categoryId);
            setSections(data);
            setRequestStateGetCategorySections(RequestState.success);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestStateGetCategorySections(RequestState.failure);
        }
    }, [dispatch])

    useEffect(() => {
        if(categoryId) {
            getData(categoryId)
        }
    }, [getData, categoryId])

    return { requestStateGetCategorySections, sections }
}
