import { SelectionPanelSearch } from "../../amrPipeline/common/SelectionPanelSearch";
import { News } from "../../../types/news/News";
import { NewsNavItem } from "./NewsNavItem";
import { routes } from "../../../constants";
import { useHistory } from "react-router";
import { EditNewsTabType } from "../../../types/news/enums/EditNewsTabType";
import InfiniteScroll from "react-infinite-scroller";
import {
    EmptyPlaceholder,
    InfiniteScrollLoader,
    Preloader,
} from "../../common";
import { useDispatch } from "react-redux";
import { editNewsActions } from "../../../actions/edit-news.actions";
import { constants } from "./constants";
import { useDebounce } from "../../../effects/useDebounce";
import { useEffect } from "react";
import IconSVG from "../../../styles/svg-icons";
import classnames from "classnames";

interface Props {
    initialNews?: News;
    newsList: News[];
    selectedReferenceName?: string;
    searchTerm: string;
    isLoading: boolean;
    hasMore: boolean;
    isSearching: boolean;
    collapsed: boolean;
    setCollapsed: (isCollapsed: boolean) => void;
}

export const NewsSelectionPanel = ({
    initialNews,
    newsList,
    selectedReferenceName,
    searchTerm,
    isLoading,
    hasMore,
    isSearching,
    collapsed,
    setCollapsed,
}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const debouncedSearch = useDebounce(searchTerm, 500);
    const noNewsYet = !newsList.length && !searchTerm && !isSearching;

    const onSearchNews = (value: string) =>
        dispatch(editNewsActions.searchNewsList(value));

    const createNews = () => history.push(routes.manageCreateNews);

    useEffect(() => {
        if (debouncedSearch.length > constants.maxSearchLengthToIgnore) {
            onSearchNews(debouncedSearch);
        }
        // eslint-disable-next-line
    }, [debouncedSearch]);

    const handleSearch = (val: string) => {
        if (
            val.length <= constants.maxSearchLengthToIgnore &&
            searchTerm.length > constants.maxSearchLengthToIgnore
        ) {
            onSearchNews("");
        }
        dispatch(editNewsActions.setSearchTerm(val));
    };

    const handleSelectNews = (referenceName: string) => {
        if (referenceName === selectedReferenceName) {
            return;
        }
        dispatch(editNewsActions.resetNewsDetails());
        history.replace(
            routes.manageNewsUrl(referenceName, EditNewsTabType.view)
        );
    };

    const onNextPageRequest = () =>
        isLoading
            ? null
            : dispatch(editNewsActions.loadMoreNewsList(searchTerm));

    const renderNavItem = (newsItem: News) => (
        <NewsNavItem
            key={newsItem.referenceName}
            data={newsItem}
            searchTerm={searchTerm}
            selected={selectedReferenceName === newsItem.referenceName}
            onClick={handleSelectNews}
        />
    );

    const renderNavItemsList = (newsItem: News) =>
        newsItem.referenceName === initialNews?.referenceName
            ? undefined
            : renderNavItem(newsItem);

    const renderNewsList = () => {
        if (noNewsYet) {
            return null;
        }

        if (isSearching) {
            return <Preloader inProgress />;
        }

        if (!newsList.length) {
            return <EmptyPlaceholder textView />;
        }

        return (
            <InfiniteScroll
                className="infinite-scroll-container"
                pageStart={0}
                initialLoad={false}
                loadMore={onNextPageRequest}
                hasMore={hasMore}
                loader={
                    <InfiniteScrollLoader
                        key={0}
                        isNextPageRequesting={isLoading}
                    />
                }
                useWindow={false}
            >
                {initialNews && renderNavItem(initialNews)}
                {newsList.map(renderNavItemsList)}
            </InfiniteScroll>
        );
    };

    return (
        <aside className={classnames("sidebar sidebar-nav sidebar-nav-manage-news", { collapsed })}>
            {!noNewsYet &&
                <SelectionPanelSearch
                    searchTerm={searchTerm}
                    placeholder="Search by title"
                    onSearch={handleSearch}
                    collapsed={collapsed}
                    onCollapseClick={setCollapsed}
                />
            }
            <nav className="sidebar-nav-list">
                {renderNewsList()}
                <div className="add-new-box">
                    <button className="btn btn-ghost" onClick={createNews}>
                        <IconSVG name="plus" width={16} height={16} />
                        News
                    </button>
                </div>
            </nav>
        </aside>
    );
};
