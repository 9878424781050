import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import { editCompanyActions } from '../../../actions/edit.company.actions';
import { companyRoles, constants, errorMessages, routes } from '../../../constants';
import { numericUtils, stringUtils } from '../../../utils';
import { AutoCompleteInput, FormError } from '../../controls';
import { InputField } from '../../forms';
import { RouteLeavingGuard } from '../../routing';
import { yup } from '../../../validation/yup';
import { useHistory, useParams } from 'react-router';
import { SalesRepresentative } from '../../../types/sales-representative/SalesRepresentative';
import { AppState } from '../../../types/state/AppState';
import { MultiSelect, MultiSelectDataItem } from '../../controls/MultiSelect';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { Preloader } from '../../common/Preloader';
import { useAppDispatch } from '../../../effects/useAppDispatch';

const RenderShortCodeInput: React.FC<{ labelText: string }> = ({ labelText }) => {
    return (
        <div className="form-item">
            <InputField
                className="form-control"
                name="code"
                label={labelText}
                placeholder="Assign Shortcode"
                markRequired={true}
                pattern={constants.companyShortcode.source}
                minLength={1}
                maxLength={constants.companyShortCodeMaxLength}
            />
        </div>
    );
};

const SalesRepresentatives: React.FC<{
    salesRepresentatives: SalesRepresentative[];
    selectedSalesRepresentatives: number[];
    companyRole?: string;
    errorMessage: string;
    onSelected: (item: MultiSelectDataItem, selected: boolean) => void;
}> = ({ salesRepresentatives, selectedSalesRepresentatives, errorMessage, companyRole, onSelected }) => {
    const selectedList = salesRepresentatives.filter(s => selectedSalesRepresentatives.some(v => v === s.id));
    return (
        <>
            <h2>Sales Representatives</h2>
            <div className="form-row two-col">
                <div className="form-item">
                    <label className="form-label" />
                    <div className="form-control-wrapper">
                        <MultiSelect
                            className={cn({ 'is-invalid': !!errorMessage })}
                            showAddButton={false}
                            placeholder="Assign Sales Representatives"
                            disabled={companyRole === companyRoles.Media}
                            sourceItems={salesRepresentatives.map(s => ({
                                selected: selectedSalesRepresentatives.some(i => i === s.id),
                                value: s.id,
                                text: `${s.firstName} ${s.lastName} ${!s.active ? '(Blocked)' : ''}`,
                                disabled:
                                    !selectedSalesRepresentatives.some(i => i === s.id) &&
                                    (selectedSalesRepresentatives.length >= constants.salesRepresentativesLimit || !s.active)
                            }))}
                            onItemSelect={onSelected}
                            generateSelectedText={(items) => (
                                <div className="multiselect-row">
                                    <span className="text-ellipsis">{items[0].text}</span>
                                    <span>{items.length > 1 && (' and ' + (items.length - 1) + ' more')}</span>
                                </div>
                            )}
                        />
                        <FormError message={errorMessage} />
                    </div>
                </div>
                <div className="form-item">
                    <label className="form-label" />
                    {!!selectedSalesRepresentatives.length && (
                        <div className="selected-employees-list">
                            <h5>Assigned Sales Representatives</h5>
                            <ul>
                                {selectedList.map(s =>
                                    <li key={s.id}>{`${s.firstName} ${s.lastName}`}</li>)}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
};

const RenderHeadOfTrading: React.FC<{ headOfTradingFirstName: string; headOfTradingLastName: string; }> = (
    { headOfTradingFirstName, headOfTradingLastName }) => {
    const isHeadOfTradingNameEmpty =
        stringUtils.isNullOrWhiteSpace(headOfTradingFirstName) &&
        stringUtils.isNullOrWhiteSpace(headOfTradingLastName);
    return (
        <>
            <h2>Head of Trading</h2>
            <div className="form-row two-col">
                <div className="form-item">
                    <InputField
                        className="form-control"
                        name="headOfTradingFirstName"
                        label="First Name"
                        placeholder="First Name"
                        markRequired={false}
                        maxLength={64}
                    />
                </div>
                <div className="form-item">
                    <InputField
                        className="form-control"
                        name="headOfTradingLastName"
                        label="Last Name"
                        placeholder="Last Name"
                        markRequired={false}
                        maxLength={64}
                    />
                </div>
            </div>
            <div className="form-item">
                <InputField
                    className="form-control"
                    name="headOfTradingEmail"
                    label="Email"
                    placeholder="Email"
                    markRequired={!isHeadOfTradingNameEmpty}
                    maxLength={64}
                />
            </div>
            <div className="form-row two-col">
                <div className="form-item">
                    <InputField
                        className="form-control"
                        name="headOfTradingOffice"
                        label="Office"
                        placeholder="Office Number"
                        markRequired={false}
                        maxLength={64}
                    />
                </div>
                <div className="form-item">
                    <InputField
                        className="form-control"
                        name="headOfTradingMobile"
                        label="Mobile"
                        placeholder="Mobile Number"
                        markRequired={false}
                        maxLength={64}
                    />
                </div>
            </div>
        </>
    )
};

export const EditCompany = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { referenceName } = useParams<{ referenceName: string }>();
    const isEdit = !!referenceName;
    const { loading, company, countries, states, cities, saving, saved, salesRepresentatives } = useSelector((state: AppState) => state.editCompany);

    useEffect(() => {
        dispatch(editCompanyActions.init(referenceName));
        return () => {
            dispatch(editCompanyActions.reset())
        }
    }, [dispatch, referenceName]);

    useEffect(() => {
        if (saved) {
            history.replace(routes.manageCompanies)
        }
    }, [saved, history])

    const getRoles = () => {
        const roles = [
            { value: companyRoles.BrokerDealer, text: companyRoles.getTitle(companyRoles.BrokerDealer), selected: false, disabled: false },
            { value: companyRoles.Media, text: companyRoles.getTitle(companyRoles.Media), selected: false, disabled: false },
            { value: companyRoles.Seller, text: companyRoles.getTitle(companyRoles.Seller), selected: false, disabled: false },
            { value: companyRoles.Viewer, text: companyRoles.getTitle(companyRoles.Viewer), selected: false, disabled: false }
        ];
        if (company?.role === companyRoles.Administrator) {
            roles.push({ value: companyRoles.Administrator, text: companyRoles.getTitle(companyRoles.Administrator), disabled: true, selected: false });
        }
        return roles
    };

    const initialValues = {
        id: company?.id || undefined,
        name: company?.name || '',
        phone: company?.phone || '',
        website: company?.website || '',
        countryCode: company?.countryCode || '',
        state: company?.state || '',
        stateId: company?.stateId || undefined,
        city: company?.city || '',
        cityId: company?.cityId || undefined,
        zipCode: company?.zipCode || '',
        address: company?.address || '',
        role: company?.role || '',
        distributionList: company?.distributionList || '',
        code: company?.code || '',
        mpid: company?.mpid || '',
        headOfTradingFirstName: company?.headOfTrading?.firstName || '',
        headOfTradingLastName: company?.headOfTrading?.lastName || '',
        headOfTradingEmail: company?.headOfTrading?.email || '',
        headOfTradingOffice : company?.headOfTrading?.office  || '',
        headOfTradingMobile : company?.headOfTrading?.mobile  || '',
        salesRepresentatives: company?.salesRepresentatives
            ? company.salesRepresentatives
                .filter(s => s.active)
                .map(s => s.id)
            : [],
    };

    const handleCountryChange = (setValues: (values: React.SetStateAction<typeof initialValues>) => void, values: typeof initialValues) => {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            const countryCode = e.target.value;
            setValues({ ...values, countryCode, stateId: undefined, state: '' });
            if (values.countryCode === constants.USCountryCode) {
                dispatch(editCompanyActions.loadCities());
            }
        };
    };

    const handleStateChange = (setFieldValue: (key: string, value: string) => void) => {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            const stateId = e.target.value;
            if (stateId) {
                dispatch(editCompanyActions.loadCities(stateId))
            }
            setFieldValue('stateId', stateId);
        }
    };

    const handleCityChange = (setValues: (values: React.SetStateAction<typeof initialValues>) => void, values: typeof initialValues) => {
        return (city: string) => {
            const selectedCity = cities.find(c => c.name.localeCompare(city, undefined, { sensitivity: 'accent' }) === 0);
            const cityId = selectedCity && selectedCity.id;
            setValues({ ...values, cityId, city });
        }
    };

    const handleChangeAddress = (setFieldValue: (key: string, value: string) => void) =>
        (e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('address', e.target.value);

    const handleChangeRole = (setValues: (values: React.SetStateAction<typeof initialValues>) => void, values: typeof initialValues) => {
        return (role: MultiSelectDataItem) => {
            const updatedValues = { ...values, role: String(role.value) };
            if (role.value === companyRoles.BrokerDealer) {
                updatedValues.distributionList = '';
                updatedValues.code = '';
            } else if(role.value === companyRoles.Media) {
                updatedValues.salesRepresentatives = [];
            }
            setValues(updatedValues);
        }
    };

    const handleChangeSalesRepresentatives = (setFieldValue: (key: string, value: number[]) => void, selectedSalesRepresentatives: number[]) => {
        return (item: MultiSelectDataItem, selected: boolean) => {
            let updatedList: number[] = [...selectedSalesRepresentatives];
            if (selected) {
                updatedList.push(Number(item.value))
            } else {
                updatedList = updatedList.filter(i => i !== item.value)
            }
            setFieldValue('salesRepresentatives', updatedList);
        }
    };

    const validationSchema = yup.object().shape({
        name: yup.string().trim().matches(constants.nonWhitespace).required().max(80),
        phone: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
        website: yup.string().trim().matches(constants.website),
        countryCode: yup.string().required(),
        state: yup.string().trim()
            .when(['stateId', 'countryCode'], {
                is: (stateId: string, countryCode: string) => !numericUtils.isNumber(stateId) && countryCode !== constants.USCountryCode,
                then: () => yup.string().trim().matches(constants.nonWhitespace).required(),
            }),
        stateId: yup.number()
            .when('countryCode', {
                is: (countryCode: string) => !countryCode || countryCode === constants.USCountryCode,
                then: () => yup.number().required(),
            }),
        city: yup.string().trim().required(),
        zipCode: yup.string().trim().test('zipCode', errorMessages.invalidValue, function (zipCode) {
            if (!zipCode) {
                return true
            }
            if (this.parent.countryCode === constants.USCountryCode) {
                return constants.USZipCode.test(zipCode)
            }
            return constants.zipCode.test(zipCode)
        }),
        address: yup.string().trim().required(),
        role: yup.string().required(),
        distributionList: yup.string(),
        code: yup.string()
            .when('role', {
                is: (role: string) => role === companyRoles.BrokerDealer || role === companyRoles.Seller,
                then: () => yup.string().matches(constants.companyShortcode).required()
            }),
        mpid: yup.string().trim()
            .when('role', {
                is: (role: string) => role === companyRoles.BrokerDealer,
                then: () => yup.string().matches(constants.mpid).required()
            }),
        headOfTradingFirstName: yup.string()
            .when('role', {
                is: (role: string) => role === companyRoles.BrokerDealer,
                then: () => yup.string().matches(constants.name)
            }),
        headOfTradingLastName: yup.string()
            .when('role', {
                is: (role: string) => role === companyRoles.BrokerDealer,
                then: () => yup.string().matches(constants.name)
            }),
        headOfTradingEmail: yup.string().matches(constants.email)
            .when(['headOfTradingFirstName', 'headOfTradingLastName'], {
                is: (headOfTradingFirstName: string, headOfTradingLastName: string) => headOfTradingFirstName || headOfTradingLastName,
                then: () => yup.string().required()
            }),
        headOfTradingOffice: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
        headOfTradingMobile: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
        salesRepresentatives: yup.array().when('role', {
            is: (role: string) => role !== companyRoles.Media,
            then: () => yup.array().required().min(1, errorMessages.chooseSalesRepresentative)
        })
    });

    return (
        <Preloader inProgress={loading || saved}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    dispatch(editCompanyActions.save(values))
                }}
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, errors, submitCount }) => {
                    const isUSACountryCode = !values.countryCode || values.countryCode === constants.USCountryCode;
                    return (
                        <div className="manage-cnt container">
                            <Form className="container-flex" noValidate={true}>
                                <div className="sub-header">
                                    <div className="sub-header-row type01 flex-row">
                                        <Breadcrumbs>
                                            <BreadcrumbsItem route={routes.manageCompanies} text='Manage Companies & Users' />
                                        </Breadcrumbs>
                                        <h1>{isEdit ? 'Edit Company' : 'Create New Company'}</h1>
                                        <div className="flex-item-right controls">
                                            <button
                                                className="btn btn-ghost"
                                                type="button"
                                                onClick={() => history.replace(routes.manageCompanies)}
                                            >
                                                CANCEL
                                            </button>
                                            <button
                                                className="btn btn-main"
                                                type="submit"
                                                disabled={saving || isEqual(initialValues, values)}
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Preloader inProgress={saving}>
                                    <div className="manage-company">
                                        <div className="manage-company-cnt">
                                            <div className="flex-row">
                                                <div className="col">
                                                    <h2>Information</h2>
                                                    <div className="form-item">
                                                        <InputField
                                                            className="form-control"
                                                            label="Company Name"
                                                            placeholder="Enter Legal Name"
                                                            markRequired={true}
                                                            name="name"
                                                            maxLength={80}
                                                        />
                                                    </div>
                                                    <div className="form-item">
                                                        <InputField
                                                            className="form-control"
                                                            label="Phone"
                                                            placeholder="Enter Phone Number"
                                                            markRequired={false}
                                                            name="phone"
                                                            maxLength={constants.phoneMaxLength}
                                                        />
                                                    </div>
                                                    <div className="form-item">
                                                        <InputField
                                                            className="form-control"
                                                            label="Website"
                                                            placeholder="http://"
                                                            markRequired={false}
                                                            name="website"
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                    <h2>Address</h2>
                                                    <>
                                                        <div className="form-item">
                                                            <label className="form-label" htmlFor="country">
                                                                Country <span className="text-red">*</span>
                                                            </label>
                                                            <div className="form-control-wrapper">
                                                                <select
                                                                    className={cn('form-control form-select', { 'is-invalid': !!submitCount && errors.countryCode })}
                                                                    name="countryCode"
                                                                    required
                                                                    value={values.countryCode || ''}
                                                                    onChange={handleCountryChange(setValues, values)}
                                                                >
                                                                    {(!isEdit || !values.countryCode) &&
                                                                    <option value="" disabled>Select Country</option>
                                                                    }
                                                                    {countries.map(c =>
                                                                        <option key={c.code}
                                                                                value={c.code}>{c.name}</option>
                                                                    )}
                                                                </select>
                                                                <FormError
                                                                    message={!!submitCount && errors.countryCode} />
                                                            </div>
                                                        </div>
                                                        <div className="form-item">
                                                            {isUSACountryCode ? (
                                                                <div className="form-control-wrapper">
                                                                    <label className="form-label" htmlFor="state">
                                                                        State<span className="text-red">*</span>
                                                                    </label>
                                                                    <select
                                                                        className={cn(
                                                                            'form-control form-select',
                                                                            { 'is-invalid': !!submitCount && errors.stateId }
                                                                        )}
                                                                        name="stateId"
                                                                        value={values.stateId || ''}
                                                                        onChange={handleStateChange(setFieldValue)}
                                                                    >
                                                                        <option value="" disabled>Select State</option>
                                                                        {!!values.countryCode && states.map(s =>
                                                                            <option key={s.id} value={s.id}>
                                                                                {s.name}
                                                                            </option>)
                                                                        }
                                                                    </select>
                                                                    <FormError
                                                                        message={!!submitCount && errors.stateId} />
                                                                </div>
                                                            ) : (
                                                                <InputField
                                                                    className="form-control"
                                                                    label="State/Province"
                                                                    placeholder="Enter State"
                                                                    markRequired={true}
                                                                    name="state"
                                                                    pattern={constants.nonWhitespace.source}
                                                                    maxLength={256}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="form-item">
                                                            <div className="form-control-wrapper">
                                                                <label className="form-label">
                                                                    City <span className="text-red">*</span>
                                                                </label>
                                                                <AutoCompleteInput
                                                                    name="city"
                                                                    value={values.city}
                                                                    sourceItems={cities.map(c => c.name)}
                                                                    maxLength="256"
                                                                    pattern={constants.name.source}
                                                                    required
                                                                    placeholder="Select City"
                                                                    emptyMessage=""
                                                                    isInvalid={!!submitCount && errors.city}
                                                                    onChange={handleCityChange(setValues, values)}
                                                                />
                                                                <FormError message={!!submitCount && errors.city} />
                                                            </div>
                                                        </div>
                                                        <div className="form-item">
                                                            <InputField
                                                                className="form-control"
                                                                label="Zip/Postal Code"
                                                                placeholder={isUSACountryCode ? 'Enter ZIP' : 'Enter Zip/Postal Code'}
                                                                markRequired={false}
                                                                name="zipCode"
                                                                maxLength={isUSACountryCode ? constants.USZipCodeSize : constants.PostalCodeSize}
                                                            />
                                                        </div>
                                                        <div className="form-item">
                                                            <label className="form-label" htmlFor="address">
                                                                Address <span className="text-red">*</span>
                                                            </label>
                                                            <div className="form-control-wrapper">
                                                        <textarea
                                                            className={cn(
                                                                'form-control',
                                                                { 'is-invalid': !!submitCount && errors.address }
                                                            )}
                                                            name="address"
                                                            maxLength={256}
                                                            cols={60}
                                                            rows={4}
                                                            placeholder="Enter Legal Address"
                                                            value={values.address}
                                                            onChange={handleChangeAddress(setFieldValue)}
                                                        />
                                                                <FormError message={!!submitCount && errors.address} />
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                                <div className="col">
                                                    <SalesRepresentatives
                                                        errorMessage={(!!submitCount && errors.salesRepresentatives?.toString()) || ''}
                                                        salesRepresentatives={salesRepresentatives}
                                                        selectedSalesRepresentatives={values.salesRepresentatives}
                                                        onSelected={handleChangeSalesRepresentatives(setFieldValue, values.salesRepresentatives)}
                                                        companyRole={values.role}
                                                    />
                                                    <h2>Role Options</h2>
                                                    <div className="form-item">
                                                        <label className="form-label" htmlFor="role">
                                                            Role<span className="text-red">&nbsp;*</span>
                                                        </label>
                                                        <div className="form-control-wrapper">
                                                            <MultiSelect
                                                                className={cn({ 'is-invalid': !!submitCount && errors.role })}
                                                                name="role"
                                                                required={true}
                                                                sourceItems={getRoles().map(r => r.value === values.role
                                                                    ? { ...r, selected: true }
                                                                    : r
                                                                )}
                                                                placeholder="Choose Role"
                                                                disabled={isEdit}
                                                                onItemSelect={handleChangeRole(setValues, values)}
                                                                showAddButton={false}
                                                            />
                                                            <FormError message={!!submitCount && errors.role} />
                                                        </div>
                                                    </div>
                                                    {values.role === companyRoles.BrokerDealer && (
                                                        <>
                                                            <div className="form-row two-col">
                                                                <div className="form-item">
                                                                    <InputField
                                                                        className="form-control"
                                                                        label="BWIC Distribution List"
                                                                        placeholder="Assign Distribution List"
                                                                        markRequired={false}
                                                                        name="distributionList"
                                                                        maxLength={256}
                                                                    />
                                                                </div>
                                                                <RenderShortCodeInput
                                                                    labelText="Broker-Dealer Shortcode" />
                                                            </div>
                                                            <div className="form-item">
                                                                <InputField
                                                                    className="form-control"
                                                                    name="mpid"
                                                                    label="MPID"
                                                                    placeholder="MPID"
                                                                    markRequired={true}
                                                                    minLength={1}
                                                                    maxLength={4}
                                                                />
                                                            </div>
                                                            {isEdit && (
                                                                <div className="form-item">
                                                                    <p className="clearing-bank">
                                                                        Settlement Agent:
                                                                        <b>{company?.isClearingBank ? 'Yes' : 'No'}</b>
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {values.role === companyRoles.Seller && (
                                                        <div className="form-row">
                                                            <div className="form-item">
                                                                <RenderShortCodeInput labelText="Company Shortcode" />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {values.role === companyRoles.BrokerDealer && (
                                                        <RenderHeadOfTrading
                                                            headOfTradingFirstName={values.headOfTradingFirstName}
                                                            headOfTradingLastName={values.headOfTradingLastName}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Preloader>
                            </Form>
                            <RouteLeavingGuard
                                navigate={(pathname: string) => history.push(pathname)}
                                shouldBlockNavigation={() => !saved && !isEqual(initialValues, values)}
                            />
                        </div>
                    )
                }}
            </Formik>
        </Preloader>
    );
};
