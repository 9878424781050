import { useDispatch, useSelector } from 'react-redux';
import { FilterOption } from '../../../types/filters/FilterOption';
import { FilterButton, FilterClear, FilterPanel, FilterSection } from '../../filters';
import { FilterSelect } from '../../common/filters/FilterSelect';
import { CompanyStatus } from '../../../types/company/CompanyStatus';
import { bwicDateFilterOptions, companyRoles } from '../../../constants';
import { companyStatuses } from '../../../constants/company.statuses';
import { AppState } from '../../../types/state/AppState';
import { DateRangeSelector } from '../../common';
import { DateFilterOption } from "../../../types/filters/DateFilterOption";
import { DateRange } from '../../../types/filters/DateRange';
import { manageCompaniesFiltersActions } from '../../../actions/manage-companies-filter.actions';
import { isEqual, keys } from 'lodash';
import { companiesActions } from '../../../actions/companies.actions';
import { companyFilterInitialState } from '../../../reducers/companies.reducer';
import { useCompanyRegByUsers } from './useCompanyRegByUsers';
import { systemUserName } from './MembersFilter';

interface FiltersProps {
    enabled: boolean;
}

export const CompaniesFilter = ({ enabled }: FiltersProps) => {
    const dispatch = useDispatch();
    const filter = useSelector((s: AppState) => s.companies.companyFilter);
    const lastAppliedCompanyFilter = useSelector((s: AppState) => s.companies.lastAppliedCompanyFilter);
    const filtersChanged = !isEqual({ ...filter, searchTerm: '' }, { ...lastAppliedCompanyFilter, searchTerm: '' });
    const isClearFilterShown = enabled
        && !isEqual({ ...companyFilterInitialState, searchTerm: '' }, { ...filter, searchTerm: '' });
    const resetFilters = () => dispatch(companiesActions.resetCompanyFilters());
    const salesRepresentatives = useSelector((s: AppState) => s.companies.salesRepresentatives);
    const regByUsers = useCompanyRegByUsers()

    return (
        <div className="filters-area flex-row align-items-flex-end">
            <FilterPanel>
                <FilterSection>
                    <FilterSelect
                        title="Status"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.active === lastAppliedCompanyFilter.active &&
                            filter.blocked === lastAppliedCompanyFilter.blocked &&
                            filter.offPlatform === lastAppliedCompanyFilter.offPlatform
                        }
                        options={[
                            CompanyStatus.active,
                            CompanyStatus.blocked,
                            CompanyStatus.offPlatform
                        ].map(x => ({
                            text: companyStatuses.getByStatusCode(x).title,
                            value: x,
                            selected: (
                                (filter.active && x === CompanyStatus.active) ||
                                (filter.blocked && x === CompanyStatus.blocked) ||
                                (filter.offPlatform && x === CompanyStatus.offPlatform)
                            ),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            (item.value === CompanyStatus.active && dispatch(manageCompaniesFiltersActions.toogleFilterStatusActive()));
                            (item.value === CompanyStatus.blocked && dispatch(manageCompaniesFiltersActions.toogleFilterStatusBlocked()));
                            (item.value === CompanyStatus.offPlatform && dispatch(manageCompaniesFiltersActions.toogleFilterStatusOffPlatform()));
                        }}
                        onClearAll={() => {
                            filter.active && dispatch(manageCompaniesFiltersActions.toogleFilterStatusActive());
                            filter.blocked && dispatch(manageCompaniesFiltersActions.toogleFilterStatusBlocked());
                            filter.offPlatform && dispatch(manageCompaniesFiltersActions.toogleFilterStatusOffPlatform());
                        }}
                        onSelectAll={() => {
                            !filter.active && dispatch(manageCompaniesFiltersActions.toogleFilterStatusActive());
                            !filter.blocked && dispatch(manageCompaniesFiltersActions.toogleFilterStatusBlocked());
                            !filter.offPlatform && dispatch(manageCompaniesFiltersActions.toogleFilterStatusOffPlatform());
                        }}
                    />
                    <FilterSelect
                        title="Role"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.administrator === lastAppliedCompanyFilter.administrator &&
                            filter.brokerDealer === lastAppliedCompanyFilter.brokerDealer &&
                            filter.seller === lastAppliedCompanyFilter.seller &&
                            filter.viewer === lastAppliedCompanyFilter.viewer &&
                            filter.media === lastAppliedCompanyFilter.media
                        }
                        options={[
                            companyRoles.Administrator,
                            companyRoles.BrokerDealer,
                            companyRoles.Media,
                            companyRoles.Seller,
                            companyRoles.Viewer
                        ].map(x => ({
                            text: companyRoles.getTitle(x),
                            value: x,
                            selected: (
                                (filter.administrator && x === companyRoles.Administrator) ||
                                (filter.brokerDealer && x === companyRoles.BrokerDealer) ||
                                (filter.seller && x === companyRoles.Seller) ||
                                (filter.viewer && x === companyRoles.Viewer) ||
                                (filter.media && x === companyRoles.Media)
                            ),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            (item.value === companyRoles.Administrator && dispatch(manageCompaniesFiltersActions.toogleFilterRoleAdministrator()));
                            (item.value === companyRoles.BrokerDealer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleBrokerDealer()));
                            (item.value === companyRoles.Seller && dispatch(manageCompaniesFiltersActions.toogleFilterRoleSeller()));
                            (item.value === companyRoles.Viewer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleViewer()));
                            (item.value === companyRoles.Media && dispatch(manageCompaniesFiltersActions.toogleFilterRoleMedia()));
                        }}
                        onClearAll={() => {
                            filter.administrator && dispatch(manageCompaniesFiltersActions.toogleFilterRoleAdministrator());
                            filter.brokerDealer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleBrokerDealer());
                            filter.seller && dispatch(manageCompaniesFiltersActions.toogleFilterRoleSeller());
                            filter.viewer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleViewer());
                            filter.media && dispatch(manageCompaniesFiltersActions.toogleFilterRoleMedia());
                        }}
                        onSelectAll={() => {
                            !filter.administrator && dispatch(manageCompaniesFiltersActions.toogleFilterRoleAdministrator());
                            !filter.brokerDealer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleBrokerDealer());
                            !filter.seller && dispatch(manageCompaniesFiltersActions.toogleFilterRoleSeller());
                            !filter.viewer && dispatch(manageCompaniesFiltersActions.toogleFilterRoleViewer());
                            !filter.media && dispatch(manageCompaniesFiltersActions.toogleFilterRoleMedia());
                        }}
                    />
                    <FilterSelect
                        title="Sales Reps"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            lastAppliedCompanyFilter.salesRepresentatives.length !== 0
                        }
                        options={salesRepresentatives.map(x => ({
                            text: `${x.firstName} ${x.lastName}`,
                            value: x.id,
                            selected: filter.salesRepresentatives.some(el => el === x.id),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            dispatch(manageCompaniesFiltersActions.toogleFilterSalesRepresentative([item.value]));
                        }}
                        onClearAll={() => {
                            dispatch(manageCompaniesFiltersActions.setFilterSalesRepresentative([]));
                        }}
                        onSelectAll={() => {
                            dispatch(manageCompaniesFiltersActions.setFilterSalesRepresentative(salesRepresentatives.map(el => el.id)));
                        }}
                    />
                    <DateRangeSelector
                        restyled={true}
                        title="Reg. Day"
                        isApplied={
                            lastAppliedCompanyFilter.selectedDateOption !== bwicDateFilterOptions.unspecified
                        }
                        onReset={() => dispatch(manageCompaniesFiltersActions.setDateFilterChange(bwicDateFilterOptions.unspecified))}
                        disabled={!enabled}
                        selectedDateOption={filter.selectedDateOption}
                        customDateRange={filter.customDateRange}
                        onSelectedDateChange={(dateOption: DateFilterOption) => dispatch(manageCompaniesFiltersActions.setDateFilterChange(dateOption))}
                        onCustomDateChange={(dateRange: DateRange) => dispatch(manageCompaniesFiltersActions.setDateFilterRangeChange(dateRange))}
                        acceptedOptions={[
                            bwicDateFilterOptions.unspecified,
                            bwicDateFilterOptions.thisWeek,
                            bwicDateFilterOptions.lastWeek,
                            bwicDateFilterOptions.lastMonth,
                            bwicDateFilterOptions.lastYear,
                            bwicDateFilterOptions.custom
                        ]}
                        disabledDays={{ after: new Date() }}
                    />
                    <FilterSelect
                        title="Reg. by"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            lastAppliedCompanyFilter.regBy.length !== 0
                        }
                        options={keys(regByUsers).map(x => ({
                            // Replace Reg. by value by default text for system admins
                            text: regByUsers[Number(x)] ?? systemUserName,
                            value: Number(x),
                            selected: filter.regBy.some(el => el === Number(x)),
                            visible: true,
                            disabled: false
                        })) as FilterOption<number>[]}
                        onChangeItemSelection={item => {
                            dispatch(manageCompaniesFiltersActions.toogleFilterRegBy([item.value]));
                        }}
                        onClearAll={() => {
                            dispatch(manageCompaniesFiltersActions.setFilterRegBy([]));
                        }}
                        onSelectAll={() => {
                            dispatch(manageCompaniesFiltersActions.setFilterRegBy(keys(regByUsers).map(k => Number(k))));
                        }}
                    />
                    <FilterButton
                        caption="Apply"
                        disabled={!filtersChanged}
                        onClick={() => dispatch(companiesActions.applyCompanyFilters())}
                    />
                    <FilterClear isShown={isClearFilterShown} onClick={resetFilters} />
                </FilterSection>
            </FilterPanel>
        </div>
    );
}
