import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterActions } from '../../../actions/filter.actions';
import { roles } from '../../../constants';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { AppState } from '../../../types/state/AppState';
import { user } from '../../../user';
import { isRequesting } from '../../../utils';
import { BwicFilters } from '../../filters/BwicFilters';
import { inventoryFilters } from './inventoryFilters';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../types/email-preferences/EmailPreferences';
import { textLabels } from '../../../constants/text-labels';

interface Props {
    handleApplyFilter: () => void;
}

const filterActions = createFilterActions(BwicFilterType.Inventory);

export const InventoryListFilter = ({ handleApplyFilter }: Props) => {
    const dispatch = useDispatch();
    const isFilterChanged = useSelector((s: AppState) => s.filters.inventory.filterChanged);
    const isFilterModified = useSelector((s: AppState) => s.filters.inventory.filterModified);
    const visibleFilters = useSelector((state: AppState) => state.filters.inventory.visibleFilters);
    const filter = useSelector((state: AppState) => state.filters.inventory.filter);
    const lastAppliedFilter = useSelector((state: AppState) => state.filters.inventory.lastAppliedFilter);
    const isInventoryExist = useSelector((state: AppState) => state.inventory.isInventoryExist);
    const requestStateSearchInventory = useSelector((state: AppState) => state.entities.allInventory.requestState);
    const selectedFilterReferenceName = useAppSelector(s => s.filters.inventory.selectedFilterReferenceName);
    const isDataFilterShouldDisplay = user.hasRoles(roles.Administrator, roles.DataEntry);
    const disabledFilters = isRequesting(requestStateSearchInventory) || !isInventoryExist;

    useEffect(() => {
        return () => {
            dispatch(filterActions.resetFilter());
            dispatch(filterActions.resetFilterState());
            dispatch(filterActions.resetFiltersVisibility());
        }
    }, [dispatch])

    const handleVisibilityChange = (filterName: string) => {
        dispatch(filterActions.filterVisibilityChange(filterName))
    }

    const handleClear = () => {
        dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        handleApplyFilter();
    };

    return (
        <BwicFilters
            withAlerts={true}
            isSearching={disabledFilters}
            onApply={handleApplyFilter}
            onReset={handleClear}
            visibleFilters={visibleFilters.filter(el => el !== 'date' || isDataFilterShouldDisplay)}
            hiddenFilters={inventoryFilters.additionalFilters}
            filterType={BwicFilterType.Inventory}
            lastAppliedFilter={lastAppliedFilter}
            filter={filter}
            filterChanged={isFilterChanged}
            filterModified={isFilterModified}
            onVisibilityChange={handleVisibilityChange}
            saveFilterVisible={true}
            pageConfigType={PageConfigType.DealerInventory}
            selectedFilterReferenceName={selectedFilterReferenceName}
            withBlockedSearchAndFilter={disabledFilters}
            saveFilterBlockerFeature={SubscriptionFeature.InventorySavedFilters}
            advancedFiltersBlockerFeature={SubscriptionFeature.InventoryAvancedFilters}
            alertOptions={[AlertOption.Never, AlertOption.Instant]}
            defaultAlertOption={AlertOption.Instant}
            filterAlertPopupDescription={textLabels.savedFiltersInventory}
            filterAlertPopupTooltips={{
                [AlertOption.Instant]: textLabels.savedFiltersInventoryInstantTooltip
            }}
        />
    )
}
