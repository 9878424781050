import * as React from 'react';
import { InfoTooltip } from '../common/InfoTooltip';
import { InventoryPosition } from '../../types/inventory/InventoryPosition';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { constants, routes } from '../../constants';
import { ColumnConfigKey } from '../../types/page-config/column-customizer/ColumnConfigKey';
import { ActionBlocker } from '../access/ActionBlocker';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { FeatureLink } from '../access/FeatureLink';

export const ViewBwicColumn: IColumnDefinition<InventoryPosition> = {
    columnKey: 'view-bwic',
    stickLeft: true,
    headerClassName: '',
    bodyClassName: '',
    configKey: ColumnConfigKey.Bwic,
    renderColumnHeaderContent: () => (
        <>BWIC<InfoTooltip text="Click on 'View' to see the BWIC where this security participates" /></>
    ),
    renderColumnContent: position =>
        position.scheduledOrBiddingBwicsCount
            ? (
                <ActionBlocker feature={SubscriptionFeature.InventoryBwicColumn}>
                    <FeatureLink withLockIcon path={routes.allBWICs + '?isinCusipsAndTickers=' + decodeURIComponent(position.ticker)}>
                        View ({position.scheduledOrBiddingBwicsCount})
                    </FeatureLink>
                </ActionBlocker>)
            : constants.emptyPlaceholder,
    className: "data-list-cell-sm"
};
