import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { editParsedBwicActions as actions } from '../../../../actions';
import { TimePickerControl } from '../../../controls';


class Time extends Component {
    render = () => {
        const { bidsDue, goodUntil, error } = this.props;

        const classes = cn('rc-time-picker-normal rc-time-picker-normal-150', { 'is-invalid': !!error });

        return (
            <div className="form-item bwic-time margin-r-0">
                <div className="form-row form-row-inline margin-b-0 position-relative">
                    <div className="form-item">
                        <label className="form-label">Start Time</label>
                        <TimePickerControl
                            id="bidDue"
                            time={bidsDue}
                            onTimeChange={this.handleBidsDueChange}
                            className={classes}
                        />
                    </div>
                    <div className="form-item">
                        <label className="form-label">End Time</label>
                        <TimePickerControl
                            id="goodUntil"
                            time={goodUntil}
                            onTimeChange={this.handleGoodUntilChange}
                            className={classes}
                        />
                    </div>
                    { !!error && <div className="form-error">{error}</div> }
                </div>
            </div>
        );
    }

    handleBidsDueChange = time => this.props.dispatch(actions.changeBidsDueTime(time));
    handleGoodUntilChange = time => this.props.dispatch(actions.changeGoodUntilTime(time));
}

const mapStateToProps = ({ editParsedBwic }) => ({
    bidsDue: editParsedBwic.bidsDue,
    goodUntil: editParsedBwic.goodUntil,
    error: editParsedBwic.errors.time
});


const connectedTime = connect(mapStateToProps)(Time);
export { connectedTime as Time };
