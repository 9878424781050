import {Popup, PopupBody, PopupFooter} from '../../controls';
import { UserLastLoginHistory } from '../../../types/management/UserLastLoginHistory';
import { Table } from '../../bidding/common/table';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { companiesActions } from '../../../actions';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { constants } from '../../../constants';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface User {
    fullName: () => string
}

interface LastLoginHistoryPopupProps {
    userLastLoginHistory: {
        history: UserLastLoginHistory[],
        user: User
    }
}

export function LastLoginHistoryPopup({ userLastLoginHistory }: LastLoginHistoryPopupProps) {
    const dispatch = useAppDispatch();

    if (!userLastLoginHistory.user) {
        return null;
    }

    const getTableColumns = () => {
        const columnDefinitions: IColumnDefinition<UserLastLoginHistory>[] = [
            {
                columnKey: 'date',
                renderColumnHeaderContent: () => 'Date&Time(Local Time)',
                renderColumnContent: entity => dateTimeUtils.utcToLocalString(entity.date, constants.dateTimeFormat),
                headerClassName: 'data-list-action-date',
                bodyClassName: 'data-list-action-date',
            }, {
                columnKey: 'action',
                renderColumnHeaderContent: () => 'Action',
                renderColumnContent: entity => entity.action,
                headerClassName: 'data-list-action-name',
                bodyClassName: 'data-list-action-name',
            }
        ];

        return columnDefinitions.map(c => new ColumnBuilder(c))
    };

    const dataItems = [...userLastLoginHistory.history];
    dataItems.sort((a, b) => (new Date(b.date).getTime() - new Date(a.date).getTime()));

    return (
        <Popup
            modalClass="modal-login-history"
            title={userLastLoginHistory.user.fullName() + ' Login History'}
            onClose={() => dispatch(companiesActions.resetUserLoginHistory())}
        >
            <PopupBody>
                <Table
                    dataItems={dataItems}
                    columns={getTableColumns()}
                />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={() => dispatch(companiesActions.resetUserLoginHistory())}>ok</button>
            </PopupFooter>
        </Popup>
    )
}
