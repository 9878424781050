import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useContext } from 'react';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';

interface Props {
    title: string;
    className?: string;
    active: boolean;
    path: string;
    children?: React.ReactNode;
    disabled?: boolean;
    activeClickable?: boolean;
    disabledTooltip?: boolean;
    disabledTooltipText?: string | JSX.Element;
    isNewLabelVisible?: boolean;
}

export function NavBarItem({
    title,
    className,
    active, path,
    children,
    disabled = false,
    activeClickable = false,
    isNewLabelVisible = false
}: Props) {
    const isBlocked = useContext(BlockedFeatureContext);
    const isDisabled = disabled || (active && !activeClickable) || isBlocked;

    const renderNew = () => isNewLabelVisible && <span className="new-label">new</span>

    const renderContent = () => <>{renderNew()}{title}{children}</>

    return (
        <li className="navbar-item">
            {isDisabled ? (
                <span className={cn("navbar-link", className, { active, disabled })}>
                    {renderContent()}
                </span>
            ) : (
                <Link
                    className={cn("navbar-link", { active: active && activeClickable })}
                    to={path}
                >
                    {renderContent()}
                </Link>
            )
            }
        </li>
    );
}
