import * as Yup from "yup";

const RequiredMessage = "Required field";

export const EditNewsValidationSchema = Yup.object().shape({
    referenceName: Yup.string().notRequired().nullable(),
    title: Yup.string().typeError(RequiredMessage).required(RequiredMessage),
    category: Yup.string().typeError(RequiredMessage).required(RequiredMessage),

    label: Yup.string().notRequired(),

    provider: Yup.string().notRequired(),

    description: Yup.string()
        .typeError(RequiredMessage)
        .required(RequiredMessage),
    newsDateTime: Yup.date()
        .typeError(RequiredMessage)
        .required(RequiredMessage),

    externalSourceLink: Yup.string().notRequired().nullable(),
    transactionLink: Yup.string().notRequired().nullable(),
    published: Yup.boolean().notRequired(),
    cloManagerReferenceName: Yup.string().notRequired().nullable(),
    arrangerReferenceName: Yup.string().notRequired().nullable(),
});
