import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { inventoryEditActions } from '../../../actions';
import { DownloadTemplateButton } from '../../common/download-template/DownloadTemplateButton';
import { DeleteRowsWithErrorButton } from '../../grid/DeleteRowsWithErrorButton';
import { SecuritiesGridFlaggedPopup } from '../../common/SecuritiesGridFlaggedPopup';
import { DeleteFlaggedItemsButton } from '../../grid/DeleteFlaggedItemsButton';
import { DeleteAllButton } from '../../grid/DeleteAllButton';

interface Props {
    disabled?: boolean;
}

export function InventoryEditGridActionButtons({ disabled = false }: Props) {
    const dispatch = useDispatch();
    const isFlaggedModalVisible = useSelector((s: AppState) => s.inventoryEdit.isFlaggedModalVisible);

    return (
        <>
            <DeleteFlaggedItemsButton disabled={disabled} />
            <DeleteRowsWithErrorButton disabled={disabled} />
            <DeleteAllButton disabled={disabled} />
            {isFlaggedModalVisible && <SecuritiesGridFlaggedPopup
                onClose={() => dispatch(inventoryEditActions.setFlaggedModal(false))} />}
            <DownloadTemplateButton templateType="SecuritiesInventoryXlsxTemplate" disabled={disabled} />
        </>
    );
}
