import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editParsedBwicActions as actions } from '../../../../actions';
import { classnames } from '../../../../styles/classnames';
import { DayPickerInput } from '../../../controls/DayPickerInput';

class BwicDate extends Component {
    render = () => {
        const { date, error } = this.props;
        const css = classnames({
            'form-control': true,
            'is-invalid': !!error
        });

        return (
            <div className="form-item">
                <label className="form-label">Date</label>
                <DayPickerInput
                    onDayChange={this.handleDayChange}
                    selectedDate={date}
                    allowWeekends={false}
                    className={css} />
                {!!error && <div className="form-error">{error}</div>}
            </div>
        );
    }

    handleDayChange = day => this.props.dispatch(actions.changeDate(day));
}

const mapStateToProps = ({ editParsedBwic }) => ({
    date: editParsedBwic.date,
    error: editParsedBwic.errors.date
});


const connectedBwicDate = connect(mapStateToProps)(BwicDate);
export { connectedBwicDate as BwicDate };
